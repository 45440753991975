import React from 'react'
import dynamic from 'next/dynamic'
import { white } from '@consts/colors'
import classNames from 'classnames'
import styles from './styles.module.scss'

const icons = {
  'beta-tag': dynamic(
    () =>
      import('@svgs/beta-tag.svg').then(
        importedComponent => importedComponent.ReactComponent
      ),
    {
      ssr: false
    }
  ),
  alarm: dynamic(
    () =>
      import('@svgs/alarm.svg').then(
        importedComponent => importedComponent.ReactComponent
      ),
    {
      ssr: false
    }
  ),
  organic: dynamic(
    () =>
      import('@svgs/organic.svg').then(
        importedComponent => importedComponent.ReactComponent
      ),
    {
      ssr: false
    }
  )
} as const

export type BadgeProps = {
  classes?: string
  title?: string
  badgeType?: 'filled' | 'outline' | 'minimal'
  iconName?: 'beta-tag' | 'alarm' | 'organic'
  themeColour?: string
  divergentBackground?: string
  small?: boolean
  medium?: boolean
  fullWidth?: boolean
}

function Badge({
  classes = '',
  title,
  badgeType = 'filled',
  themeColour = '',
  iconName,
  small = false,
  medium = false,
  divergentBackground = '',
  fullWidth = false
}: BadgeProps) {
  const filled = {
    backgroundColor: divergentBackground || themeColour,
    color: divergentBackground ? themeColour : white
  }

  const outline = {
    backgroundColor: white,
    border: `1px solid ${themeColour}`,
    color: themeColour
  }

  const minimal = {
    backgroundColor: 'transparent',
    color: themeColour,
    height: 'auto',
    padding: 0,
    width: 'auto'
  }

  const badgeStyleTypes = {
    filled,
    outline,
    minimal
  }

  const propsToStyle = badgeStyleTypes[badgeType]
  const badgeClasses = classNames(`${styles.badge} ${classes}`, {
    [styles.badge_fullWidth]: fullWidth,
    [styles.badge_small]: small,
    [styles.badge_medium]: medium,
    [styles.badge_beta]: /beta/.test(classes),
    [styles.badge_minimal]: badgeType === 'minimal'
  })

  const badgeIconClasses = classNames(styles.badge__icon, {
    [styles.badge__iconOnly]: !title,
    [styles.badge__iconSmall]: small
  })

  const Icon = iconName ? icons[iconName] : undefined

  if (!title && !Icon) {
    return null
  }

  return (
    <div className={badgeClasses} style={propsToStyle}>
      {Icon && (
        <div className={badgeIconClasses}>
          <Icon />
        </div>
      )}
      {title}
    </div>
  )
}

export default Badge
