import { Customer } from 'types/customer'

type Address = {
  firstName: string
  lastName: string
  line1: string
  line2: string
  city: string
  country: string
  phone: string
  province: string
  province_code: string
  postalCode: string
}
type ReturnData = {
  email: string
  address: Partial<Address>
}

export function prepareForPurpleDot({
  customer
}: {
  customer: Customer | undefined
}) {
  try {
    const returnData: Partial<ReturnData> = {}
    const address: Partial<Address> = {}

    if (customer?.email) {
      returnData.email = customer.email
    }

    if (customer?.orders && customer.orders.length) {
      address.firstName = customer.firstName
      address.lastName = customer.lastName
      address.line1 = customer.orders[0].shippingAddress.address1
      address.line2 = customer.orders[0].shippingAddress.address2
      address.city = customer.orders[0].shippingAddress.city
      address.country = customer.orders[0].shippingAddress.countryCodeV2
      address.phone = customer.orders[0].shippingAddress.phone
      address.province = customer.orders[0].shippingAddress.province
      address.province_code = customer.orders[0].shippingAddress.provinceCode
      address.postalCode = customer.orders[0].shippingAddress.zip
    } else if (customer?.addresses && customer.addresses.length) {
      address.firstName = customer.firstName
      address.lastName = customer.lastName
      address.line1 = customer.addresses[0].address1
      address.line2 = customer.addresses[0].address2
      address.city = customer.addresses[0].city
      address.country = customer.addresses[0].countryCodeV2
      address.phone = customer.addresses[0].phone
      address.province = customer.addresses[0].province
      address.province_code = customer.addresses[0].provinceCode
      address.postalCode = customer.addresses[0].zip
    }

    returnData.address = address

    return returnData
  } catch (e) {
    return {}
  }
}

const decodeString = (str: string) => {
  try {
    return typeof window === 'undefined'
      ? Buffer.from(str, 'base64').toString('utf-8')
      : atob(str)
  } catch (error) {
    return str
  }
}

export function decode(str: string) {
  const raw = decodeString(str)
    // eslint-disable-line no-undef
    .split('shopify/')[1]

  if (!raw) {
    return {
      type: '',
      id: '',
      params: {},
      raw: str
    }
  }

  const [type, id] = raw.split('/')

  const params = (id.split('?').slice(1)[0] || '')
    .split('&')
    .reduce((p: Record<string, unknown>, q) => {
      const [key, value] = q.split('=')
      // eslint-disable-next-line no-param-reassign
      p[key] = value
      return p
    }, {})

  return {
    type,
    id: id.split('?')[0],
    params,
    raw: str
  }
}

export function encode(
  type: string,
  id: number | string,
  params: Record<string, unknown> = {}
) {
  let full = `gid://shopify/${type}/${id}`

  const keys = Object.keys(params)

  if (keys.length > 0) {
    const queryArray = []
    for (let i = 0; i < keys.length; i++) {
      queryArray.push(`${keys[i]}=${params[keys[i]]}`)
    }

    const query = `?${queryArray.join('&')}`

    full += query
  }

  return typeof window === 'undefined'
    ? Buffer.from(full, 'utf-8').toString('base64')
    : btoa(full) // eslint-disable-line no-undef
}

type ParsedObject = { [key: string]: ParseData }
type ParseData = string | number | boolean | null | ParsedObject | ParseData[]

export function parse(data: ParseData): ParseData {
  if (Array.isArray(data) && !!data) {
    return data.map(item => parse(item))
  }
  if (typeof data === 'object' && !!data) {
    const expectedData = Object.keys(data).reduce((json, key) => {
      if (key === 'node' || key === 'items') {
        return {
          ...json,
          ...(parse(data[key]) as ParsedObject)
        }
      }
      if (key === 'edges') {
        return [...(parse(data[key]) as ParseData[])]
      }
      return { ...json, [key]: parse(data[key]) }
    }, {})
    return expectedData
  }
  return data
}

export function resizeShopifyImage(
  src: string,
  options: { width?: string; height?: string; crop?: string } = {}
) {
  try {
    const urlObj = new URL(src)
    const params = new URLSearchParams(urlObj.search)
    Object.keys(options).forEach((option: string) => {
      const optionValue = options[option as keyof typeof options]
      if (optionValue) {
        params.set(option, optionValue)
      }
    })
    const paramsString = params.toString()
    const pathnameWithoutTrailingSlash = urlObj.pathname.replace(/\/$/g, '')
    return `${urlObj.origin}${pathnameWithoutTrailingSlash}${
      paramsString ? '?' : ''
    }${paramsString}`
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
    }
    return ''
  }
}
