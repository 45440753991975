import styles from './styles.module.scss'

export default function MobileMenuGridContainer({
  isActive,
  children
}: {
  isActive: boolean
  children: React.ReactNode
}) {
  return (
    <div
      className={`${styles.MobileMenuGrid} ${
        isActive
          ? styles.MobileMenuGrid__active
          : styles.MobileMenuGrid__inactive
      }`}
    >
      <div className={styles.MobileMenuGrid__container}>{children}</div>
    </div>
  )
}
