import { useRef, useEffect, useState } from 'react'
import { BREAKPOINT_SIZES } from '@consts/index'
import { objectKeys } from '@helpers/utils'
import useWindowSize from './useWindowSize'

export { useWindowSize }
export { default as useExiting } from './useExiting'
export { default as useIdle } from './useIdle'
export { default as useMediaQuery } from './useMediaQuery'

export function useViewportDimensions() {
  const windowSize = useWindowSize()
  const breakpoint = objectKeys(BREAKPOINT_SIZES).find(
    key => (windowSize.width || 0) < BREAKPOINT_SIZES[key]
  )
  return { breakpoint }
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>(() => undefined)
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useYotpoRatingScripts = () => {
  const [isLibraryLoaded, setIsLibraryLoaded] = useState(false)

  function loadWidget() {
    return new Promise(resolve => {
      const scriptsID = 'yotpo-scripts'
      const existingScript = document.getElementById(scriptsID)
      if (existingScript) {
        resolve(undefined)
      } else {
        const newScriptTag = document.createElement('script')

        newScriptTag.type = 'text/javascript'
        newScriptTag.async = true
        newScriptTag.src = `//staticw2.yotpo.com/${process.env.YOTPO_API_KEY}/widget.js`
        newScriptTag.id = scriptsID
        newScriptTag.onload = () => {
          const checkForLibraryInterval = setInterval(() => {
            clearInterval(checkForLibraryInterval)
            resolve(undefined)
          }, 200)
        }
        newScriptTag.onerror = () => {
          resolve(undefined)
        }
        document.body.appendChild(newScriptTag)
      }
    })
  }

  useEffect(() => {
    loadWidget().then(() => setIsLibraryLoaded(true))
  }, [])
  return isLibraryLoaded
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
