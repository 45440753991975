import { useRef, useEffect } from 'react'

export default function useOutsideClick<T extends HTMLElement>(
  callback: () => void
) {
  const ref = useRef<null | T>(null)

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent) => {
      if (target && !ref.current?.contains(target as Node)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [ref, callback])

  return ref
}
