import { getLocale } from '@helpers/env'

const productCareCopyQuery = `query($productType: String!, $preview: Boolean) {
  productCareCopy: productCareCopyCollection(where: {productType_contains_some: [$productType]}, limit: 1, locale: "${getLocale()}", preview: $preview) {
    items {
      copy {
        json
      }
    }
  }
}`

export default productCareCopyQuery
