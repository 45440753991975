import {
  FlyoutMenuAction,
  ModalAction,
  CollectionFiltersAction
} from '../reducers/popUpsTrackerReducers'

export function modalTrackerAction(
  payload: ModalAction['payload']
): ModalAction {
  return {
    type: 'TRACK_MODAL',
    payload
  }
}
export function collectionFiltersTrackerAction(
  payload: CollectionFiltersAction['payload']
): CollectionFiltersAction {
  return {
    type: 'TRACK_COLLECTION_FILTERS',
    payload
  }
}
export function flyoutMenuTrackerAction(
  payload: FlyoutMenuAction['payload']
): FlyoutMenuAction {
  return {
    type: 'TRACK_FLYOUT_MENU',
    payload
  }
}
