import { getLink } from '@helpers/env'
import styles from './style.module.scss'
import Badge, { BadgeProps } from '@components/ui/Badge'

type MenuLinkProps = {
  title: string
  link: string
  badge?: BadgeProps | null
  size?: 'default' | 'large'
  target?: '_self' | '_blank'
  variant?: 'primary' | 'secondary'
  onClick: (title: string) => void
}

export default function MenuLink({
  title,
  link,
  badge,
  size = 'default',
  target = '_self',
  onClick,
  variant = 'primary'
}: MenuLinkProps) {
  return (
    <a
      href={getLink(link)}
      className={`${styles.link} ${styles[size]} ${styles[variant]}`}
      target={target}
      onClick={() => onClick(title)}
    >
      {title}

      {badge && <Badge classes={styles.badge} {...badge} badgeType="minimal" />}
    </a>
  )
}
