import { createContext } from 'react'
import { FlashBanner } from '@pages/_app'
import { HeaderProps, HeaderTheme } from 'types/header'

type AppContextProps = {
  flashBanner: FlashBanner
  header: HeaderProps
  headerTheme: HeaderTheme
  setHeaderTheme: (arg: HeaderTheme) => void
  setHeader: (arg: HeaderProps) => void
  setFlashBanner: (arg: FlashBanner) => void
}

export const AddSearchClient = {
  addFacetField: (arg: string) => arg,
  setSearchOperator: (arg: string) => arg,
  setPaging: () => undefined,
  setThrottleTime: () => undefined,
  setFuzzyMatch: () => undefined
}

const AppContext = createContext<AppContextProps>({
  flashBanner: {},
  header: {},
  headerTheme: 'default',
  setHeaderTheme: () => undefined,
  setHeader: () => undefined,
  setFlashBanner: () => undefined
})

export default AppContext
