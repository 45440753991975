import { ReactNode } from 'react'
import Translations from '@helpers/translations'
import { ButtonLink } from '@components/ui/Button'
import styles from './styles.module.scss'

type PageErrorProps = {
  title?: string
  children?: ReactNode
  errorCFId?: string
}

function PageError({
  title = Translations.localise('pages.error.page-not-found'),
  children = <p>{Translations.localise('pages.error.sorry-page-not-found')}</p>,
  errorCFId
}: PageErrorProps) {
  return (
    <section className={styles.PageError}>
      <h1>{title}</h1>
      <div className={styles.PageError__content}>
        <p className="empty-cart-message">{children}</p>
        {errorCFId && (
          <>
            <p className="empty-cart-message">
              {Translations.localise('pages.error.report-error')}:
            </p>
            <p>{errorCFId}</p>
          </>
        )}
      </div>
      <ButtonLink variant="primary" href="/" fullWidth="never">
        {Translations.localise('pages.error.back-home')}
      </ButtonLink>
    </section>
  )
}

export default PageError
