import { getLocale } from '@helpers/env'

const collectionHeroTagQuery = `query($pageHandle: String!, $preview: Boolean) {
  collectionHero: collectionHeroCollection(where: {collectionHandle: $pageHandle}, limit: 1, locale: "${getLocale()}", preview: $preview) {
    items {
      badge {
        title,
        badgeType,
        themeColour,
        small,
        iconName
      }
      collectionHandle
    }
  }
}`

export default collectionHeroTagQuery
