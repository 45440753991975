import cartFragment from '../fragments/cart'

const mutation = `mutation ($cartInput : CartInput!) {
  cartCreate(input: $cartInput){
    cart ${cartFragment}
    userErrors {
      field
      message
    }
  }
}
`
export default mutation
