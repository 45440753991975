/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import classNames from 'classnames'
import { ReactComponent as CloseIcon } from '@svgs/close.svg'
import styles from './styles.module.scss'

type ModalContainerProps = {
  onClose: () => void
  theme?: {
    modalPosition?: string
  }
  classes?: string
}

function ModalContainer({
  onClose,
  children,
  theme = { modalPosition: undefined },
  classes
}: React.PropsWithChildren<ModalContainerProps>) {
  const { modalPosition } = theme
  const stopPropagation = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => e.stopPropagation()

  const modalClassNames = classNames(
    styles.modal__container,
    {
      [styles[`modal__container_${modalPosition}`]]: modalPosition
    },
    classes
  )

  return (
    <div
      role="dialog"
      className={modalClassNames}
      onClick={stopPropagation}
      onKeyDown={stopPropagation}
      tabIndex={0}
    >
      <button
        data-testid="modal-close-button"
        type="button"
        className={styles.modal__closeButton}
        onClick={onClose}
      >
        <CloseIcon width="18" height="18" />
      </button>
      {children}
    </div>
  )
}

export default ModalContainer
