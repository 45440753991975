import { SIZES } from '@consts/index'
import { Fit } from 'types/fit'
import FitSelector from '@components/ui/formElements/FitSelector'
import { UIState } from './types'
import { CATEGORIES } from './consts'
import styles from './styles.module.scss'
import HelpButton from '@components/ui/HelpButton'
import { SizeOptions } from 'types/product'

type SavedFitOptionProps = {
  sizeOption: (typeof CATEGORIES)[number]['sizeOptions'][number]
  savedFit: Fit
  fitSelection: Fit
  uiState: UIState
  handleSetSelection: (selection: Partial<Fit>) => void
  openSizeHelper?: (sizeOption: SizeOptions) => void
}
function SavedFitCard({
  sizeOption,
  fitSelection,
  uiState,
  savedFit,
  handleSetSelection,
  openSizeHelper
}: SavedFitOptionProps) {
  const id = sizeOption.toLowerCase() as SizeOptions
  const currentlySelectedValue = fitSelection[id as keyof Fit]?.toString()
  const originalSelectedValue = savedFit[id]?.toString()

  const list = SIZES[id].map(size => {
    const formattedSize = size.replace('Build ', '')
    return {
      label: formattedSize,
      value: formattedSize
    }
  })

  return (
    <div
      key={sizeOption}
      data-testid={`fit-finder-result-${sizeOption.toLocaleLowerCase()}`}
      className={styles.SaveFitWrapper__cardSize}
    >
      <span className={styles.SaveFitWrapper__cardSizeLabel}>
        {sizeOption}{' '}
        {!!openSizeHelper && (
          <HelpButton
            classes={styles.SavedFitCard__helpButton}
            active={false}
            onClick={() => openSizeHelper(id)}
            aria-label={`${sizeOption} help button`}
          />
        )}
      </span>
      <div className={styles.SaveFitWrapper__dropdownWrapper}>
        <FitSelector<string>
          classes={styles.SaveFitWrapper__dropdown}
          defaultValue={currentlySelectedValue || '-'}
          placeholder="-"
          isEditable={uiState === 'edit'}
          list={list}
          originalSelectedValue={originalSelectedValue}
          selection={currentlySelectedValue}
          setSelection={handleSetSelection}
          id={id}
        />
      </div>
    </div>
  )
}

export default SavedFitCard
