import { ReactComponent as CloseIcon } from '@svgs/close.svg'
import styles from './styles.module.scss'

type SizeHelperProps = {
  title: string
  description: string
  onClose: () => void
}

function SizeHelper({ onClose, title, description }: SizeHelperProps) {
  return (
    <div className={styles.SizeHelper}>
      <button
        data-testid="size-helper-close-button"
        type="button"
        className={styles.SizeHelper__closeButton}
        onClick={onClose}
      >
        <CloseIcon width="15" height="15" />
      </button>
      <div className={styles.SizeHelper__contentWrapper}>
        <p className={styles.SizeHelper__contentTitle}>{title}</p>
        <p className={styles.SizeHelper__content}>{description}</p>
      </div>
    </div>
  )
}
export default SizeHelper
