/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import styles from './buttonBase.module.scss'

type ButtonSize = 'large' | 'small'
type ButtonTextSize = 'large' | 'small'
type ButtonFullWidth = 'always' | 'never' | 'inherit'
type ButtonType = 'button' | 'submit' | 'reset' | undefined
export type Classes = string | Array<string> | Record<string, boolean>

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonBase = {
  children: React.ReactNode
  classes?: Classes
  size?: ButtonSize
  textSize?: ButtonTextSize
  fullWidth?: ButtonFullWidth
  disabled?: boolean
}

export type ButtonBaseProps = ButtonBase & {
  type?: ButtonType
} & ButtonProps

const ButtonBase = ({
  type = 'button',
  classes,
  children,
  size = 'large',
  textSize,
  fullWidth,
  disabled = false,
  ...props
}: ButtonBaseProps) => {
  const buttonClasses = classNames(
    styles.button,
    styles[size],
    styles[`textSize_${textSize || size}`],
    classes,
    {
      [styles.alwaysFullWidth]: fullWidth === 'always',
      [styles.neverFullWidth]: fullWidth === 'never',
      [styles.inherit]: fullWidth === 'inherit'
    }
  )

  return (
    <button
      {...props}
      className={buttonClasses}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default ButtonBase
