import React from 'react'
import { useMediaQuery } from '@helpers/hooks'
import { BREAKPOINT_SIZES } from '@consts/index'
import Link from '@components/ui/Link'
import { ReactComponent as ChevronIcon } from '@svgs/chevron.svg'
import { getLink } from '@helpers/env'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type FlashBannerLinkProps = {
  linkUrl: string | null
  linkTextDesktop: string | null
  contentColor: string | null
  bannerDismissible: boolean
}

function FlashBannerLink({
  contentColor,
  linkUrl,
  linkTextDesktop,
  bannerDismissible
}: FlashBannerLinkProps) {
  const isPhabletUp = useMediaQuery({ minWidth: BREAKPOINT_SIZES.PHABLET })
  const flashLinkClasses = classNames(styles.flashLinkWrapper, {
    [styles.flashLinkWrapper__narrowLink]: bannerDismissible
  })

  if (!linkUrl || !linkTextDesktop) {
    return null
  }

  return (
    <div className={flashLinkClasses}>
      {isPhabletUp ? (
        <Link
          classes={`link--block link--small ${
            contentColor ? `link--${contentColor}` : ''
          } ${styles.flashLink}`}
          text={linkTextDesktop}
          href={getLink(linkUrl)}
        />
      ) : (
        <div className={styles.flashLink__icon}>
          <ChevronIcon fill={contentColor || '#ffffff'} height="17" />
        </div>
      )}
    </div>
  )
}

export default FlashBannerLink
