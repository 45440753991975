/* eslint-disable import/prefer-default-export */
import { SIZES, COOKIE_EXPIRE_3_MONTHS, BUILD } from '@consts/index'
import { setCookie, getCookieByName } from '@helpers/session'
import { isEmptyObject, objectKeys } from '@helpers/utils'
import trackError from '@helpers/trackError'
import { upperFirstLetter } from '@helpers/utils'

export function parseQuery(queryString: string): Record<string, string> {
  const query: Record<string, string> = {}
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = decodeURIComponent(
      pairs[i].replace(/%([^\d{2}][\u2013|\u2014])/g, '$1')
    ).split('=')
    query[pair[0]] = pair[1] || ''
  }
  return query
}

type FitKeys =
  | 'shortsleg'
  | 'build'
  | 'leg'
  | 'waist'
  | 'size'
  | 'cut'
  | 'length'
type FitFinderMap = Record<string, FitKeys>

const FIT_FINDER_MAP: FitFinderMap = {
  sl: 'shortsleg',
  b: 'build',
  l: 'leg',
  w: 'waist',
  s: 'size',
  c: 'cut',
  lh: 'length'
}

function getFitFromUrl(): Partial<Record<FitKeys, string>> {
  const urlParams = parseQuery(window.location.search)

  return objectKeys(urlParams).reduce<Partial<Record<FitKeys, string>>>(
    (fit, param) => {
      const fitKey = FIT_FINDER_MAP[param]
      if (!fitKey) return fit

      const paramValue = urlParams[param]
      const formattedValue = upperFirstLetter(paramValue)

      const isValidFit = SIZES[fitKey]?.some(
        fitType =>
          fitType.replace(BUILD, '').trim().toLowerCase() ===
          paramValue.toLowerCase()
      )

      if (isValidFit) {
        return {
          ...fit,
          [fitKey]: formattedValue
        }
      }

      return fit
    },
    {}
  )
}

function createFitObject(
  fitFromUrl: Partial<Record<string, string>>,
  parsedCookie: Partial<Record<string, string>> = {}
): Partial<Record<string, string>> {
  if (!isEmptyObject(fitFromUrl)) {
    return fitFromUrl
  }

  return parsedCookie
}

export function initFit(): Partial<Record<string, string>> {
  try {
    const parsedCookie = JSON.parse(
      getCookieByName('filters') || '{}'
    ) as Partial<Record<string, string>>
    const fitFromUrl = getFitFromUrl()
    const fit = createFitObject(fitFromUrl, parsedCookie)
    const newFilters = objectKeys(fit || {}).reduce((json, item) => {
      const type = item.replace('swimshortsleg', 'shortsleg')
      const size = SIZES[type.toLowerCase() as keyof typeof SIZES]
      const arrayToCrawl =
        type.toLowerCase() === 'shortsleg'
          ? [...new Set([...SIZES.shortsleg, ...size])]
          : size

      const isValidFitSizeName =
        !!size &&
        arrayToCrawl.findIndex((value: string) => {
          return (
            value.toLowerCase().replace('build ', '') ===
            ((fit || {})[type] || '').toString().toLowerCase()
          )
        }) > -1
      return !isValidFitSizeName
        ? json
        : { ...json, [type.toLowerCase()]: (fit || {})[type] }
    }, {})

    if (!isEmptyObject(fitFromUrl)) {
      setCookie(
        'filters',
        JSON.stringify(newFilters).replace('shortsleg', 'shortsLeg'),
        COOKIE_EXPIRE_3_MONTHS
      )
    }

    return newFilters
  } catch (error) {
    const filterCookie = getCookieByName('filters')
    setCookie('filters', '{}', COOKIE_EXPIRE_3_MONTHS)
    trackError(new Error('error on filter cookie/sessionStorage parsing'), {
      error: error as Error,
      filterCookie
    })
    return {}
  }
}
