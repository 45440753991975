import React, { useContext } from 'react'
import styles from './styles.module.scss'
import AccordionContext from './context'

type AccordionPanelProps = {
  classes?: string
}

export default function AccordionPanel({
  children,
  classes
}: React.PropsWithChildren<AccordionPanelProps>) {
  const { id } = useContext(AccordionContext)

  return (
    <div
      className={`${styles.accordion__container} ${classes || ''}`}
      id={`accordion-panel-${id}`}
      aria-labelledby={`accordion-button-${id}`}
      tabIndex={-1}
    >
      <div className={styles.accordion__content}>{children}</div>
    </div>
  )
}
