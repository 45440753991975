import { Top, Bottom, Fit } from 'types/fit'

export type CustomerFitState = {
  fit:
    | {
        top: Partial<Top>
        bottom: Partial<Bottom>
      }
    | {
        top: undefined
        bottom: undefined
      }
  updatedAt: string | undefined
}

export type CustomerSetFitAction = {
  type: 'CUSTOMER_SET_FIT'
  payload: {
    fit?: Fit | null
    updatedAt?: CustomerFitState['updatedAt']
  }
}

const initState = {
  fit: {
    top: undefined,
    bottom: undefined
  },
  updatedAt: undefined
}

export default function customerFitReducer(
  state: CustomerFitState = initState,
  action: CustomerSetFitAction
): CustomerFitState {
  switch (action.type) {
    case 'CUSTOMER_SET_FIT':
      return {
        ...state,
        fit: {
          top: {
            cut: action.payload.fit?.cut || state.fit.top?.cut,
            size: action.payload.fit?.size || state.fit.top?.size,
            length: action.payload.fit?.length || state.fit.top?.length
          },
          bottom: {
            build: action.payload.fit?.build || state.fit.bottom?.build,
            leg: action.payload.fit?.leg || state.fit.bottom?.leg,
            waist: action.payload.fit?.waist || state.fit.bottom?.waist
          }
        },
        updatedAt: action.payload?.updatedAt
      }

    default:
      return state
  }
}
