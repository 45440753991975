import classNames from 'classnames'
import styles from './buttonIcon.module.scss'

type ButtonIconProps = {
  classes?: string
}
const ButtonIcon = ({
  classes,
  children,
  ...props
}: React.PropsWithChildren<ButtonIconProps>) => {
  const buttonClasses = classNames(styles.buttonIcon, classes)
  return (
    <span className={buttonClasses} {...props}>
      {children}
    </span>
  )
}

export default ButtonIcon
