import React from 'react'
import { useMediaQuery } from '@helpers/hooks'
import { BREAKPOINT_SIZES } from '@consts/index'
import { ReactComponent as ChevronIcon } from '@svgs/chevron.svg'
import classNames from 'classnames'
import styles from './styles.module.scss'
import ButtonMinimal from '@components/ui/Button/ButtonMinimal'

export type FlashBannerButtonProps = {
  linkTextDesktop: string
  contentColor: string | null
  bannerDismissible: boolean
}

function FlashBannerButton({
  contentColor,
  linkTextDesktop,
  bannerDismissible
}: FlashBannerButtonProps) {
  const isPhabletUp = useMediaQuery({ minWidth: BREAKPOINT_SIZES.PHABLET })
  const flashLinkClasses = classNames(styles.flashLinkWrapper, {
    [styles.flashLinkWrapper__narrowLink]: bannerDismissible
  })

  return (
    <div className={flashLinkClasses}>
      {isPhabletUp ? (
        <ButtonMinimal variant="light" underlined>
          {linkTextDesktop}
        </ButtonMinimal>
      ) : (
        <div className={styles.flashLink__icon}>
          <ChevronIcon fill={contentColor || '#ffffff'} height="17" />
        </div>
      )}
    </div>
  )
}

export default FlashBannerButton
