import { useContext, useEffect, useState } from 'react'
import HeaderLogo from '../../shared/HeaderLogo'
import { ReactComponent as AccountIcon } from '@svgs/menu-account.svg'
import { ReactComponent as SearchIcon } from '@svgs/search.svg'
import { getLink } from '@helpers/env'
import CartMenuIcon from '@components/Header/shared/CartMenuIcon'
import { CartEvent } from '@components/Header'
import MobileMenuDrawerOverlay from '@components/Header/HeaderWrapper/mobile/MobileMenuDrawerOverlay'
import styles from './styles.module.scss'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'
import { getCookieByName } from '@helpers/session'
import { useDispatch } from 'react-redux'
import { flyoutMenuTrackerAction } from '@store/redux/actions/popUpsTrackerActions'
import { useRouter } from 'next/router'
import AppContext from '@components/AppContext'

export default function MobileHeader({
  onCartOpen,
  isFitFinderOpen
}: {
  onCartOpen: (e: CartEvent['event']) => void
  isFitFinderOpen: boolean
}) {
  const { headerTheme } = useContext(AppContext)
  const router = useRouter()
  const dispatch = useDispatch()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const handleBurgerClick = () => {
    setIsMobileMenuOpen(true)
    analytics('Click', {
      category: 'Menu Click'
    })
  }
  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false)
  }

  useEffect(() => {
    if (isFitFinderOpen && isMobileMenuOpen) {
      setIsMobileMenuOpen(false)
    }
  }, [isFitFinderOpen, isMobileMenuOpen])

  useEffect(() => {
    // Updates the Newsletter modal popup store status
    if (!getCookieByName('hideNewsletterPrompt')) {
      dispatch(flyoutMenuTrackerAction({ flyoutMenu: isMobileMenuOpen }))
    }
  }, [dispatch, isMobileMenuOpen])

  const isSearchDrawerOpen = router.asPath?.includes('/pages/search')

  return (
    <>
      <div className={`${styles.MobileHeader} ${headerTheme}`}>
        <HeaderLogo
          width="44"
          height="37"
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'Logo'
            })
          }}
        />

        <a
          data-testid="account-mobile-nav-button"
          className={styles.MobileHeader__accountButton}
          href={`${getLink('/account')}`}
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'My Account'
            })
          }}
        >
          <AccountIcon />
        </a>

        <a
          className={`${styles.MobileDrawer__searchButton} ${
            isSearchDrawerOpen ? styles.MobileDrawer__searchButton_active : ''
          }`}
          href={getLink('/pages/search')}
          aria-label="search"
          onClick={() => {
            analytics('Click', {
              category: 'Search',
              action: 'Open',
              label: 'Mobile'
            })
          }}
        >
          <SearchIcon />
        </a>

        <button
          aria-label="cart"
          onClick={onCartOpen}
          className={styles.MobileHeader__cartButton}
        >
          <CartMenuIcon />
        </button>

        <button
          className={styles.MobileHeader__toggleMenu}
          onClick={handleBurgerClick}
          aria-label="main navigation"
        >
          <div className={styles.MobileHeader__burger}>
            <span></span>
          </div>
        </button>
      </div>
      <MobileMenuDrawerOverlay
        isMobileMenuOpen={isMobileMenuOpen}
        handleCloseMobileMenu={handleCloseMobileMenu}
      />
    </>
  )
}
