const fragment = `{
  id 
  firstName
  lastName,
  email,
  orders(first:1, reverse:true) {
      edges {
      node {
          id
          name
          shippingAddress {
            firstName
            lastName
            address1
            address2
            city
            countryCodeV2
            country
            phone
            province
            provinceCode
            zip
        }
      }
    }
  }
  addresses(first:1, reverse:true) {
      edges {
      node {
        id
        firstName
        lastName
        address1
        address2
        city
        countryCodeV2
        country
        phone
        province
        provinceCode
        zip
      }
    }
  }
}`
export default fragment
