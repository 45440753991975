import React, { ReactNode, ErrorInfo } from 'react'
import trackError from '@helpers/trackError'

interface ErrorBoundaryProps {
  children?: ReactNode
  fallback?: ReactNode
  callback?: (error: Error, errorInfo: ErrorInfo) => void
  reportError?: boolean
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    children: null,
    fallback: null,
    callback: null,
    reportError: true
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { callback, reportError } = this.props
    if (reportError) {
      trackError(error, { error, errorInfo })
    }
    if (callback) {
      callback(error, errorInfo)
    }
  }

  render() {
    const { children, fallback } = this.props
    const { hasError } = this.state

    if (!hasError) {
      return children
    }

    if (fallback) {
      return fallback
    }

    return null
  }
}

export default ErrorBoundary
