import React from 'react'
import { ReactComponent as CartIcon } from '@svgs/menu-cart.svg'
import styles from './styles.module.scss'

import { useCart } from '@components/cart/hooks'

export const CartMenuIcon = () => {
  const { lines } = useCart()
  const lineItemsTotal = lines.reduce((total, item) => total + item.quantity, 0)

  return (
    <p className={styles.cartMenuIcon} data-total={lineItemsTotal}>
      <CartIcon
        width={21}
        height={25}
        className="CartMenuIcon"
        aria-label="cart icon"
      />
    </p>
  )
}

export default CartMenuIcon
