import trackError from '@helpers/trackError'
import fetchContentful, { Variables } from './fetchContentful'
import collectionHeroTagQuery from './queries/collectionHeroTagQuery'
import {
  productsPromoByPageHandleQuery,
  productsPromoByProductTypeQuery
} from './queries/productsPromoQuery'
import productDiscountQuery from './queries/productDiscountQuery'
import productCareCopyQuery from './queries/productCareCopyQuery'
import {
  CollectionHero,
  ProductCareCopy,
  ProductDiscount,
  ProductsPromo
} from 'types/contentful/graphQlApi'
import { EmptyObject } from 'types/helpers'

type CustomError = {
  message: string
  section?: string
} & Error

function handleError(
  errors: Error[] | Error,
  { message, section }: CustomError
) {
  if (Array.isArray(errors)) {
    errors.forEach(error => {
      trackError(new Error(error.message), {
        title: message,
        error,
        section
      })
    })
  } else {
    trackError(new Error(errors.message), {
      title: message,
      errors: errors,
      section
    })
  }
}

type FetchContentfulServiceProperties<T extends Variables> = {
  query: string
  errorMessage: string
  section?: string
  variables?: T | EmptyObject
}

export async function fetchContentfulService<T extends Variables, U>({
  query,
  errorMessage,
  section,
  variables = {}
}: FetchContentfulServiceProperties<T>) {
  try {
    const { data, errors } = await fetchContentful<U>(query, variables)
    if (errors) throw errors
    return data
  } catch (error) {
    handleError(error as Error, {
      message: errorMessage,
      name: Error.name,
      ...(section && { section })
    })
  }
}

type FetchContentfulProductsPromo = { preview?: boolean } & (
  | { pageHandle: string }
  | { productType: string | undefined }
)
export const fetchContentfulProductsPromo = (
  variables: FetchContentfulProductsPromo
) =>
  fetchContentfulService<FetchContentfulProductsPromo, ProductsPromo>({
    query:
      'productType' in variables && !!variables.productType
        ? productsPromoByProductTypeQuery
        : productsPromoByPageHandleQuery,
    errorMessage: 'Error fetching contentful products promo',
    variables
  })

type FetchProductDiscountDescription = {
  productType: string
  preview?: boolean
}
export const fetchProductDiscountDescription = (
  variables: FetchProductDiscountDescription
) =>
  fetchContentfulService<FetchProductDiscountDescription, ProductDiscount>({
    query: productDiscountQuery,
    errorMessage: 'Error fetching contentful product discount',
    variables
  })

type FetchProductCareCopy = {
  productType: string
  preview?: boolean
}
export const fetchProductCareCopy = (variables: FetchProductCareCopy) =>
  fetchContentfulService<FetchProductCareCopy, ProductCareCopy>({
    query: productCareCopyQuery,
    errorMessage: 'Error fetching contentful product care copy',
    variables
  })

type FetchCollectionHeroTag = {
  pageHandle: string
  preview?: boolean
}
export const fetchCollectionHeroTag = (variables: FetchCollectionHeroTag) =>
  fetchContentfulService<FetchCollectionHeroTag, CollectionHero>({
    query: collectionHeroTagQuery,
    errorMessage: 'Error fetching contentful collection hero tag',
    variables
  })
