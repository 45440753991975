/* eslint-disable no-case-declarations */
import { FilterOption } from 'types/filters'

export type FilterState = {
  filterOptions: FilterOption[]
}

export type DynamicFilterAction = {
  type: 'FILTER_DYNAMIC_SELECTED'
  payload: FilterOption[]
}

export type ResetFilterAction = {
  type: 'RESET_DYNAMIC_FILTERS'
  payload?: FilterOption[]
}

export type FilterAction = DynamicFilterAction | ResetFilterAction

const initState = {
  filterOptions: []
}

export default function filterReducer(
  state: FilterState = initState,
  action: FilterAction
): FilterState {
  switch (action.type) {
    case 'FILTER_DYNAMIC_SELECTED': {
      const newFilterOptions = [...state.filterOptions]

      action.payload.forEach(filter => {
        const index = newFilterOptions.findIndex(f => f.type === filter.type)
        if (index === -1) {
          newFilterOptions.push(filter)
        } else if (
          newFilterOptions[index].value?.toLowerCase() ===
          filter.value?.toLowerCase()
        ) {
          newFilterOptions.splice(index, 1)
        } else {
          newFilterOptions[index] = { ...filter }
        }
      })

      return {
        ...state,
        filterOptions: newFilterOptions
      }
    }

    case 'RESET_DYNAMIC_FILTERS': {
      const newFilterOptions = [...state.filterOptions]
      if (!action.payload || action.payload.length === 0) {
        return { ...state, filterOptions: [] }
      }
      return {
        ...state,
        filterOptions: newFilterOptions.filter(
          filter =>
            action.payload?.findIndex(f => filter.type === f.type) === -1
        )
      }
    }
    default:
      return state
  }
}
