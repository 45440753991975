import useHashRouter from '@helpers/hooks/useHashRouter'

const FIT_FINDER_HASH = 'fit_finder_open'

export function useFitFinderToggle() {
  const { hash, addHash, removeHash } = useHashRouter()
  const isFitFinderOpen = hash === FIT_FINDER_HASH

  const openFitFinder = () => {
    addHash(FIT_FINDER_HASH)
  }

  return {
    isFitFinderOpen,
    openFitFinder,
    closeFitFinder: removeHash
  }
}
