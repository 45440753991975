import React from 'react'
import styles from './styles.module.scss'

type ListItem = {
  label: string
  value: string | number
}

type SetSelectionArgs = Record<string, string | number>

export type ListProps = {
  list: ListItem[]
  selection?: string | number | null
  setSelection: (args: SetSelectionArgs) => void
  id: string
}

type FitSelectorListProps = ListProps & { isOpen: boolean }

function FitSelectorList({
  list,
  selection,
  setSelection,
  id,
  isOpen
}: FitSelectorListProps) {
  return (
    <div
      className={`${styles.DropdownList} ${
        isOpen ? styles.DropdownList__open : ''
      }`}
    >
      {list.map(item => (
        <button
          key={`${item.value}_${id}`}
          type="button"
          className={`list-option-${id} ${styles.DropdownList__Item} ${
            selection === item.value ? styles.selected : ''
          }`}
          onClick={() => setSelection({ [id]: item.value })}
        >
          {item.label}
        </button>
      ))}
    </div>
  )
}

export default FitSelectorList
