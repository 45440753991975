import { readCart as readCartService } from '@api/graphqlShopify'
import { setCartCookie } from '@helpers/cart'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Cart } from 'types/checkout'
import { purgeNullVariantsFromCart } from '../cartHelpers'
import { updateCart, toggleCartNotification, resetCart } from './cartActions'

type ReadCheckoutReturnType = ThunkAction<Promise<void>, void, unknown, Action>

function readCart(cartId: string): ReadCheckoutReturnType {
  return async dispatch => {
    try {
      let cart = (await readCartService(cartId)) as Cart

      const purgedCheckout = await purgeNullVariantsFromCart(cart?.lines)

      if (purgedCheckout) {
        cart = purgedCheckout
        dispatch(toggleCartNotification(true))
      }

      dispatch(updateCart(cart))
    } catch (error) {
      setCartCookie({ expiration: -1 })
      dispatch(resetCart())
    }
  }
}

export default readCart
