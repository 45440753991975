import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { cartAttributesUpdate } from '@api/graphqlShopify'
import { CartState } from '@store/redux/reducers/cartReducer'
import { Cart, CustomAttribute } from 'types/checkout'
import { purgeNullVariantsFromCart } from '../cartHelpers'
import { updateCart, toggleCartNotification } from './cartActions'

type UpdateCartAttributes = (
  attributes: CustomAttribute | CustomAttribute[],
  context: string
) => ThunkAction<Promise<Cart>, { cart: CartState }, unknown, Action>

const updateCartAttributes: UpdateCartAttributes = attributes => {
  return async dispatch => {
    let cart = await cartAttributesUpdate(attributes)

    const purgedCart = await purgeNullVariantsFromCart(cart?.lines)

    if (purgedCart) {
      dispatch(toggleCartNotification(true))
      cart = await cartAttributesUpdate(attributes)
    }

    dispatch(updateCart(cart))

    return cart
  }
}

export default updateCartAttributes
