import dynamic from 'next/dynamic'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { Asset } from 'types/contentful/graphQlApi'

const icons = {
  trouser: dynamic(
    () =>
      import('@svgs/trouser.svg').then(
        importedComponent => importedComponent.ReactComponent
      ),
    {
      ssr: false
    }
  ),
  parachute: dynamic(
    () =>
      import('@svgs/parachute.svg').then(
        importedComponent => importedComponent.ReactComponent
      ),
    {
      ssr: false
    }
  ),
  'fit-clock': dynamic(
    () =>
      import('@svgs/fit-clock.svg').then(
        importedComponent => importedComponent.ReactComponent
      ),
    {
      ssr: false
    }
  )
} as const

export type FlashBannerIconProps = {
  iconName: keyof typeof icons | null
  contentColor: string | null
  showMobile: boolean
  iconSvg: Asset | null
}

function FlashBannerIcon({
  contentColor,
  iconName,
  iconSvg,
  showMobile
}: FlashBannerIconProps) {
  const classes = classNames(styles.flashSvg, {
    [styles.flashSvg_visible]: showMobile
  })

  const Icon = iconName ? icons[iconName] : null

  return (
    <>
      {Icon && (
        <div className={classes}>
          <Icon className={`${styles[`Icon_${contentColor}Fill`]}`} />
        </div>
      )}
      {!!iconSvg && Icon == null && (
        <div className={classes}>
          <img src={`${iconSvg.url}?h=40`} alt={iconSvg.title} height="20" />
        </div>
      )}
    </>
  )
}

export default FlashBannerIcon
