import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import AccordionContext from './context'

type AccordionItemProps = {
  id?: string
  onClick?: () => void
  isOpen?: boolean
  isDisabled?: boolean
  classes?: string
}

export default function AccordionItem({
  id,
  children,
  isOpen = false,
  onClick = () => undefined,
  classes = '',
  isDisabled = false,
  ...props
}: React.PropsWithChildren<AccordionItemProps>) {
  const contextProps = useMemo(
    () => ({
      id,
      isOpen,
      isDisabled,
      onClick
    }),
    [id, isOpen, isDisabled]
  )

  return (
    <div
      {...props}
      className={`${styles.accordion__section} ${
        isDisabled ? styles.disabled : ''
      } ${classes || ''}`}
      data-open={isDisabled || isOpen}
    >
      <AccordionContext.Provider value={contextProps}>
        {children}
      </AccordionContext.Provider>
    </div>
  )
}
