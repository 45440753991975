/* eslint-disable no-console */
import * as Sentry from '@sentry/react'

type CustomError = Error & { title?: string }

type ExtraProperties = {
  title?: string
  section?: string
  error?: Error
} & Partial<Record<string, unknown | undefined>>

const IGNORED_SENTRY_ERROR_MESSAGES = ['Network Error', 'Request aborted']

function formatContextError(error: Error | undefined) {
  if (!error) return ''

  return JSON.stringify(error)
}

function findIgnoredErrors(error: Error | undefined) {
  if (!error) return false

  const errorMessage = (error?.message || error) as string

  return IGNORED_SENTRY_ERROR_MESSAGES.includes(errorMessage)
}

function trackError(error: CustomError, extra?: ExtraProperties) {
  // Can't use helper to check prod because it causes a circular dependency error
  if (process.env.API_ENV !== 'production' || !process.browser) {
    console.error(error, extra)
  }

  try {
    const sentryError = error || new Error('Track error')
    sentryError.title = extra?.title || error?.title || 'Error'
    const context = {
      ...extra,
      error: formatContextError(extra?.error)
    }

    const isInvalidError = extra && findIgnoredErrors(extra?.error)

    if (!isInvalidError) {
      Sentry.captureException(sentryError, scope => {
        scope.clear()
        if (extra) {
          scope.setContext('extra', context)
          scope.setTag('section', extra.section)
        }
        return scope
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export function trackMessage(msg: string, context?: Record<string, string>) {
  try {
    Sentry.captureMessage(msg, scope => {
      scope.clear()
      if (context) {
        scope.setContext('extra', context)
      }
      return scope
    })
  } catch (e) {
    console.error(e)
  }
}

export default trackError
