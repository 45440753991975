import { useState, useEffect } from 'react'
import { debounce } from '@helpers/utils'

type WindowSizeState = {
  width: number | undefined
  height: number | undefined
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSizeState>({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    handleResize()
    window.addEventListener('resize', debounce(handleResize, 100))
    /* The orientationchange event listener is needed due to a bug on Chrome iOS, where changing device orientation
      doesn't get the updated window values with the resize listener. Even though it shouldn't be used on its own,
      here its being used with resize to mitigate mobile devices bugs */
    window.addEventListener('orientationchange', debounce(handleResize, 100))

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
    }
  }, [])
  return windowSize
}
