/* eslint no-unused-vars: "error" */
import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { setCookie } from '@helpers/session'
import LazyLoad from 'react-lazyload'
import { analytics } from '@helpers/analytics'
import updateNewsletterSubscription from '@helpers/misc/updateNewsletterSubscription'
import Translations from '@helpers/translations'
import { COOKIE_EXPIRE_1_YEAR } from '@consts/index'
import { FieldsLegalText } from '@components/GDPR'
import { SubmitButtonState } from '@components/ui/Button/SubmitButton'
import EmailForm from '@components/ui/EmailForm'
import { SubmitButton } from '@components/ui/Button'
import FooterNewsletterModal from '@components/newsletter/FooterNewsletterModal'
import styles from './styles.module.scss'

const PaymentCardOptions = dynamic(
  () => import('@components/Footer/PaymentCardOptions'),
  { ssr: false }
)

const NewsLetter = () => {
  const [buttonState, setButtonState] = useState<SubmitButtonState>('ready')
  const [openedModal, setOpenedModal] = useState(false)
  const [formEmail, setFormEmail] = useState('')
  const [formErrors, setFormErrors] = useState<boolean>(false)

  const handleSubmit = async (email: string) => {
    setButtonState('loading')

    try {
      const subcriptionFailed = await updateNewsletterSubscription({
        email,
        location: 'Footer'
      })

      if (subcriptionFailed) {
        setButtonState('ready')
      } else {
        setCookie('hideNewsletterPrompt', 'true', COOKIE_EXPIRE_1_YEAR)
        setOpenedModal(true)
        setFormEmail(email)
        setButtonState('success')
        analytics('Newsletter Sign Up', {
          label: 'footer',
          source: 'footer'
        })
        setButtonState('ready')
      }
    } catch (_) {
      setFormErrors(true)
      setButtonState('ready')
    }
  }

  return (
    <>
      <div className="grid__item grid grid--column">
        <div className="grid__container">
          <h5 className={`${styles.footerTitle} grid__item`}>
            {Translations.localise('common.footer.the-newsletter')}
          </h5>
          <div className={styles.formFlex} data-testid="footer-newsletter">
            <div className={styles.form__fields}>
              <EmailForm onSubmit={handleSubmit}>
                {formEmail => (
                  <SubmitButton
                    type="submit"
                    fullWidth="never"
                    size="small"
                    variant="white"
                    disabled={!formEmail}
                    state={buttonState}
                  >
                    Sign Up
                  </SubmitButton>
                )}
              </EmailForm>
            </div>
            {formErrors && (
              <p className={styles.form__errors}>
                There was a problem subscribing, try again later.
              </p>
            )}
            <div className="grid">
              <div className="grid__container">
                <FieldsLegalText widthStyle="narrow" lightStyle="white" />
              </div>
            </div>
            <div className={styles.footer__paymentOptions}>
              <LazyLoad once>
                <PaymentCardOptions />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
      {openedModal && (
        <FooterNewsletterModal
          openedModal={openedModal}
          onClose={() => setOpenedModal(false)}
          email={formEmail}
        />
      )}
    </>
  )
}

export default NewsLetter
