import React from 'react'
import { useDispatch } from 'react-redux'
import { Fit } from 'types/fit'
import { customerSetFitAction } from '@store/redux/actions/customerFitActions'
import { analytics } from '@helpers/analytics'
import { checkIsCompleteFit } from '@helpers/customer/fit'
import { Button, SubmitButton } from '@components/ui/Button'
import Modal from '@components/ui/Modal'
import { SubmitButtonHandlers } from '@components/ui/Button/SubmitButton'
import { UIState } from '../types'
import SaveFitWrapper from '../SavedFitWrapper'
import styles from '../shared/savedFitModalContainer.module.scss'
import { updateCustomerFit } from '../helpers'

const FIT_UPDATE_EVENT = 'Fit Update Prompt'

type SavedFitSavedFitFirstTimeModalProperties = {
  title: string
  description: string
  method: string
  category: string
  savedFit: Fit
  onClose?: () => void
}

export default function SavedFitFirstTimeModal({
  savedFit,
  title,
  description,
  category,
  method,
  onClose = () => undefined
}: SavedFitSavedFitFirstTimeModalProperties) {
  const isSavedFitComplete = checkIsCompleteFit(savedFit)
  const dispatch = useDispatch()

  const handleKeepCurrentFit = async ({
    triggerButtonError,
    triggerButtonSuccess
  }: SubmitButtonHandlers) => {
    try {
      await updateCustomerFit(savedFit, method)

      analytics(FIT_UPDATE_EVENT, {
        action: 'Fit saved',
        category
      })

      await triggerButtonSuccess()

      dispatch(
        customerSetFitAction({
          updatedAt: new Date().toISOString(),
          fit: savedFit
        })
      )

      onClose()
    } catch (error) {
      triggerButtonError()
    }
  }

  const handleClose = () => {
    dispatch(
      customerSetFitAction({
        updatedAt: new Date().toISOString()
      })
    )
    analytics(FIT_UPDATE_EVENT, {
      action: 'Modal closed',
      category
    })
    onClose()
  }

  const handleAfterSubmit = () => {
    analytics(FIT_UPDATE_EVENT, {
      action: 'Fit edited',
      category
    })
    onClose()
  }

  const handleEditFit = (setUIState: (uiState: UIState) => void) => {
    setUIState('edit')
    analytics('Click', {
      label: 'Edit my results',
      category
    })
  }

  return (
    <Modal onClose={handleClose} openedModal={true}>
      <div className={styles.savedFitModalContainer}>
        <div className={styles.savedFitModalWrapper}>
          <SaveFitWrapper
            method={method}
            title={title}
            description={description}
            savedFit={savedFit}
            showImage={false}
            afterSubmit={handleAfterSubmit}
            showShopButtons={false}
          >
            {({ uiState, setUIState }) => {
              return (
                <>
                  {uiState === 'read' && (
                    <div
                      className={styles.savedFitModalContainer__buttonWrapper}
                    >
                      <Button
                        variant={`${
                          isSavedFitComplete ? 'secondary' : 'primary'
                        }`}
                        onClick={() => handleEditFit(setUIState)}
                      >
                        {isSavedFitComplete ? 'Edit' : 'Complete your Fit'}
                      </Button>
                      <SubmitButton
                        variant="primary"
                        onClick={handleKeepCurrentFit}
                        disabled={!isSavedFitComplete}
                      >
                        Save your Fit
                      </SubmitButton>
                    </div>
                  )}
                </>
              )
            }}
          </SaveFitWrapper>
        </div>
      </div>
    </Modal>
  )
}
