import axios, { AxiosError, AxiosResponse } from 'axios'

type ReturnType<T> = {
  data: T
  errors?: Error[]
}

export type Variables = {
  preview?: boolean
  [key: string]: string | boolean | null | undefined
}

export default function fetchContentful<T>(
  query: string,
  variables: Variables = {}
) {
  const token = variables.preview
    ? process.env.API_CONTENTFUL_PREVIEW_TOKEN
    : process.env.API_CONTENTFUL_TOKEN
  return axios({
    method: 'post',
    url: `https://graphql.contentful.com/content/v1/spaces/${process.env.API_CONTENTFUL_SPACE}/environments/${process.env.CONTENTFUL_ENV}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    data: { query, variables }
  })
    .then(({ data }: AxiosResponse<ReturnType<T>>) => data)
    .catch((e: AxiosError) => {
      throw e.toJSON() as AxiosError
    })
}
