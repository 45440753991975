/* eslint-disable import/prefer-default-export */
import { SIZES } from '@consts/index'
import { SizeOptions } from 'types/product'

export function getSizeOptionsFromType(type: string, productType?: string) {
  const isSocks = !!productType && /socks/.test(productType.toLowerCase())

  if (type === 'swimshortsleg' || type === 'shortsleg') {
    return SIZES.shortsleg
  }

  if (isSocks) {
    return SIZES.sizeSocks
  }

  return SIZES[type as SizeOptions]
}
