import React, { useState } from 'react'
import classNames from 'classnames'
import ButtonBase, { ButtonBaseProps } from './ButtonBase'
import styles from './submitButton.module.scss'

const BUTTON_STATUS_DELAY = 2000

type ButtonOnClick = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void

const Animations = () => (
  <>
    <div className={styles.button__loading}>
      <span className={styles.button__dot} />
      <span className={styles.button__dot} />
      <span className={styles.button__dot} />
      <span className={styles.button__dot} />
      <span className={styles.button__dot} />
    </div>
    <div className={`${styles.button__tick} ${styles.button__tick_success}`} />
    <div className={`${styles.button__tick} ${styles.button__tick_error}`} />
  </>
)

export type SubmitButtonHandlers = {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  triggerButtonSuccess: () => Promise<boolean>
  triggerButtonReady: () => Promise<boolean>
  triggerButtonError: () => Promise<boolean>
}

export type SubmitButtonState =
  | 'ready'
  | 'loading'
  | 'success'
  | 'error'
  | 'disabled'

export type SubmitButtonProps = Omit<ButtonBaseProps, 'onClick'> & {
  variant?: 'primary' | 'secondary' | 'white'
  state?: SubmitButtonState
  classes?: string
  onClick?: (arg: SubmitButtonHandlers) => void | undefined | Promise<void>
}

const SubmitButton = ({
  classes,
  onClick = () => undefined,
  children,
  state,
  variant = 'primary',
  ...props
}: SubmitButtonProps) => {
  const [savedButtonState, setSavedButtonState] = useState<SubmitButtonState>(
    state || 'ready'
  )
  const buttonState = state || savedButtonState
  const buttonClasses = classNames(
    styles.submitButton,
    styles[buttonState],
    styles[variant],
    classes
  )

  const handleOnDone = (handlerState: SubmitButtonState) => new Promise<boolean>((resolve) => {
    setSavedButtonState(handlerState)

    setTimeout(() => {
      setSavedButtonState('ready')
      resolve(true)
    }, BUTTON_STATUS_DELAY)
  })

  const handleClick: ButtonOnClick = event => {
    if (buttonState !== 'ready') return undefined

    setSavedButtonState('loading')

    if (typeof onClick === 'function') {
      onClick({
        event,
        triggerButtonSuccess: () => handleOnDone('success'),
        triggerButtonReady: () => handleOnDone('ready'),
        triggerButtonError: () => handleOnDone('error')
      })
    }
  }

  return (
    <ButtonBase classes={buttonClasses} onClick={handleClick} {...props}>
      <div className={styles.button__content}>{children}</div>
      <Animations />
    </ButtonBase>
  )
}

export default SubmitButton
