import React, { useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import Translations from '@helpers/translations'
import TextInput from '../formElements/TextInput'
import styles from './styles.module.scss'

type EmailFieldValidationProps = {
  withButtonRight?: boolean
  setInputValue: (arg: string) => void
  placeholder: string
  name?: string
}

function EmailFieldValidation({
  withButtonRight = false,
  setInputValue,
  placeholder,
  name = 'email',
  children
}: React.PropsWithChildren<EmailFieldValidationProps>) {
  const [showError, setShowError] = useState<string>('')

  const handleChange = ({ email }: { [email: string]: string }) => {
    if (isEmail(email)) {
      setShowError('')
      setInputValue(email)
    } else {
      setInputValue('')
    }
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEmail(e.target.value)) {
      setShowError(
        !e.target.value
          ? Translations.localise('common.email.validation')
          : Translations.localise('common.email.incorrect-format')
      )
    } else {
      setShowError('')
    }
  }

  return (
    <div
      className={`${styles.form__field} ${
        !!showError ? styles.form__field_increasedHeight : ''
      }`}
    >
      <TextInput
        id="email"
        name={name}
        changeValue={handleChange}
        handleBlur={handleBlur}
        withButtonRight={withButtonRight}
        inputErrorClassName={showError ? 'form__input--error' : ''}
        placeholder={placeholder}
      />
      {!!showError && <p className={styles.form__error}>{showError}</p>}
      {children}
    </div>
  )
}

export default EmailFieldValidation
