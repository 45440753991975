import { useEffect, useState } from 'react'

const EVENTS = [
  'mousemove',
  'mousedown',
  'keydown',
  'touchstart',
  'scroll'
] as const
const FOUR_SECONDS_IN_MILLISECONDS = 4000

type IdleHookResult = {
  isIdle: boolean
  cancelIsIdle: () => void
}

export default function useIdle(
  timeout: number = FOUR_SECONDS_IN_MILLISECONDS
): IdleHookResult {
  const [isIdle, setIsIdle] = useState(false)
  let timeoutHandler: NodeJS.Timeout

  const handleEvent = () => {
    if (isIdle) {
      setIsIdle(false)
    }
    clearTimeout(timeoutHandler)
    timeoutHandler = setTimeout(() => {
      setIsIdle(true)
    }, timeout)
  }

  const cancelIsIdle = () => {
    clearTimeout(timeoutHandler)
    EVENTS.forEach(event => {
      window.removeEventListener(event, handleEvent)
    })
  }

  useEffect(() => {
    EVENTS.forEach(event => {
      window.addEventListener(event, handleEvent)
    })

    return () => {
      cancelIsIdle()
    }
  })

  return { isIdle, cancelIsIdle }
}
