/* eslint-disable no-param-reassign */
import { getIsUserLoggedIn, getCustomerId } from '@helpers/session/user'
import { isBrowser, getAnalyticsCountryCode } from '@helpers/env'
import trackError from '@helpers/trackError'
import { STORE_ANALYTICS_CODES } from '@consts/index'

type AnalyticsCommonProperties = { url: string } & {
  store_location: keyof typeof STORE_ANALYTICS_CODES
  logged_in: boolean
}

type AnalyticsProperties =
  | AnalyticsCommonProperties
  | { [key: string]: unknown }

type AnalyticsOpts = {
  [key: string]: unknown
}

type PageProperties =
  | (AnalyticsCommonProperties & {
      path: string
      referrer?: string
    })
  | { [key: string]: unknown }

async function safelySendAnalytics(callback: () => void) {
  if (isBrowser() && !!window.analytics && !!window.postHogAsyncLoader) {
    try {
      await window.postHogAsyncLoader
      callback()
    } catch (error) {
      trackError(new Error('Failed to send analytics event'))
    }
  }
}

export async function analytics(
  name: string,
  props: AnalyticsProperties = {},
  opts: AnalyticsOpts = {}
) {
  const pageUrl = isBrowser() ? window.location.href : ''
  const eventProps = {
    ...props,
    store_location: getAnalyticsCountryCode(),
    logged_in: isBrowser() && getIsUserLoggedIn(),
    url: props.url || pageUrl
  }

  safelySendAnalytics(() => {
    window.analytics?.track(name, eventProps, opts)
  })
}

export async function page(props: PageProperties = {}) {
  const pageUrl = isBrowser() ? window.location.href : ''

  const pageProperties = {
    ...props,
    store_location: getAnalyticsCountryCode(),
    url: props.url || pageUrl,
    logged_in: getIsUserLoggedIn(),
    referrer: props.referrer !== props.url ? props.referrer : null
  }

  safelySendAnalytics(() => {
    window.analytics?.page(pageProperties)
  })
}

export async function identify(
  id: string | number | null | void,
  props: AnalyticsProperties = {},
  opts: AnalyticsOpts = {}
) {
  const eventProps = {
    ...props,
    store_location: getAnalyticsCountryCode(),
    logged_in: isBrowser() && getIsUserLoggedIn()
  }

  const userId = id || getCustomerId()

  if (userId) {
    safelySendAnalytics(() => {
      window.analytics?.identify(userId, eventProps, opts)
    })
  }
}

export function analyticsPostHogOmit(
  name: string,
  props: Record<string, unknown>,
  opts?: Record<string, unknown>
) {
  analytics(name, props, {
    integrations: {
      PostHog: false
    },
    ...opts
  })
}
