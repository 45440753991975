import DesktopMenuLinkMegamenu from './DesktopMenuLinkMegamenu'
import DesktopMenuLink from './DesktopMenuLink'
import styles from './styles.module.scss'
import { HeaderLinks } from 'types/header'

type PrimarLinksProps = {
  links: HeaderLinks
  currentNav: string
  handleShowMegamenu: (title: string) => void
  showFlyoutMenu: boolean
}

export function PrimaryNavLinks({
  links,
  currentNav,
  handleShowMegamenu,
  showFlyoutMenu
}: PrimarLinksProps) {
  const visibleLinks = links.filter(link => link.visibility)
  const handlShowCurrentNav = (currentNavLink: string) => {
    handleShowMegamenu(currentNavLink)
  }

  return (
    <nav
      aria-label="Primary Menu"
      className={styles.DesktopHeader__primaryLinks}
    >
      <ul role="menu">
        {visibleLinks.length &&
          links.map((link, index) => {
            const hasSubmenu = link?.grid && Object.keys(link?.grid).length > 0
            return (
              <li key={`${link.title}-${index}`}>
                <DesktopMenuLink
                  link={link}
                  active={
                    showFlyoutMenu &&
                    currentNav?.toLowerCase() === link.title.toLowerCase()
                  }
                  hasSubmenu={hasSubmenu}
                  handleToggleMegamenu={handlShowCurrentNav}
                />
                {hasSubmenu && (
                  <DesktopMenuLinkMegamenu
                    currentNav={currentNav}
                    link={link}
                    showFlyoutMenu={showFlyoutMenu}
                  />
                )}
              </li>
            )
          })}
      </ul>
    </nav>
  )
}
