import { CartNotificationAction } from '../reducers/cartNotificationReducer'
import { ResetCartAction, UpdateCartAction } from '../reducers/cartReducer'

export function updateCart(payload: UpdateCartAction['payload']) {
  return {
    type: 'UPDATE_CART',
    payload
  }
}

export function toggleCartNotification(
  payload: CartNotificationAction['payload']
) {
  return {
    type: 'TOGGLE_CART_NOTIFICATION',
    payload
  }
}

export function resetCart(): ResetCartAction {
  return {
    type: 'RESET_CART'
  }
}
