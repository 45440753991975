const moneyValue = `... on MoneyV2 {
  __typename
  amount
  currencyCode
}`

const fragment = `{
  id
  createdAt
  updatedAt
  checkoutUrl
  lines(first: 100)  {
    edges {
      node {
        id
        quantity
        merchandise {
          ... on ProductVariant {
            id
            sku
            price {${moneyValue}}
            compareAtPrice {${moneyValue}}
            quantityAvailable
            product {
              id
              productType
              title
              url : onlineStoreUrl
              options {
                name
                id
              }
            }
            image {
              url
            }
          }
        }
        attributes {
          key
          value
        }
        discountAllocations {
          discountedAmount {${moneyValue}}
          ... on CartAutomaticDiscountAllocation {
            title
          }
          ... on CartCodeDiscountAllocation {
            code
          }
          ... on CartCustomDiscountAllocation {
            title
          }
        }
      }
    }
  }
  attributes {
    key
    value
  }
  cost {
    totalAmount {${moneyValue}}
    subtotalAmount {${moneyValue}}
    totalTaxAmount {${moneyValue}}
    totalDutyAmount {${moneyValue}}
  }
  buyerIdentity {
    email
  }
  discountCodes {
    code
    applicable
  }
  discountAllocations {
    discountedAmount {${moneyValue}}
  }
}`
export default fragment
