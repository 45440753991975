import React from 'react'
import { getLink } from '@helpers/env'
import { useMediaQuery } from '@helpers/hooks'
import { BREAKPOINT_SIZES } from '@consts/index'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel
} from '@components/ui/Accordion'
import footerColumnsLinks from '../data'
import styles from './styles.module.scss'

const FooterLinksColumns = () => {
  const isDesktop = useMediaQuery({
    minWidth: BREAKPOINT_SIZES.DESKTOP,
    initialState: true
  })
  return (
    <Accordion
      id="FooterLinksColumns"
      classes={styles.FooterLinksColumns}
      isDisabled={isDesktop}
    >
      {footerColumnsLinks.map(link => {
        const { title, childLinks: children } = link
        return (
          <AccordionItem
            classes={styles.FooterLinksColumns__column}
            key={title}
          >
            <div className="grid__item" key={title}>
              <h5 className={styles.FooterLinksColumns__title}>
                <AccordionButton>{title}</AccordionButton>
              </h5>
              <AccordionPanel>
                <ul className={styles.FooterLinksColumns__list}>
                  {children.map(child => {
                    return (
                      <li
                        className={styles.FooterLinksColumns__listItem}
                        key={child.title}
                      >
                        <a
                          className={styles.FooterLinksColumns__itemLink}
                          href={child.rawUrl ? child.url : getLink(child.url)}
                          {...(child.openNewWindow && {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                          })}
                        >
                          {child.title}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </AccordionPanel>
            </div>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default FooterLinksColumns
