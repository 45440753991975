import { useCallback, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import useSessionStorage from '@helpers/hooks/useSessionStorage'
import { getHasCustomerSavedFit } from '@helpers/customer/fit'
import { Loader } from '@components/Loading/LoadingElements'
import Drawer from '@components/ui/Drawer'
import { EmptyObject } from 'types/helpers'
import { ShopLocale } from 'types/locales'
import { StepsContext } from './context'
import { Answers, History } from './types'
import { analytics } from '@helpers/analytics'

const FitFinderContainer = dynamic(() => import('./FitFinderContainer'), {
  ssr: false,
  loading: () => <Loader fullHeight={true} />
})

type NewFitFinderProps = {
  onCloseFitFinder: () => void
  isFitFinderOpen: boolean
}

type AnswersState = Partial<Record<ShopLocale, Answers | EmptyObject>>

const LOCALE = process.env.SHOP_LOCALE

const defaultAnswers = {
  [LOCALE]: {}
}

function NewFitFinder({
  onCloseFitFinder,
  isFitFinderOpen
}: NewFitFinderProps) {
  const hasSavedFit = getHasCustomerSavedFit()

  const [history, setHistory] = useSessionStorage<History>(
    'fitFinderHistory',
    []
  )

  const [answers, setAnswers] = useSessionStorage<AnswersState>(
    'fitFinderAnswers',
    defaultAnswers
  )

  const [isNewCustomer, setIsNewCustomer] = useSessionStorage(
    'fitFinderIsNewCustomer',
    !hasSavedFit
  )
  const [hasStartedFitFinder, setHasStartedFitFinder] = useState(
    !!history.length
  )

  const handleSetAnswers = useCallback(
    (answersToSet: Answers) => {
      setAnswers({
        ...answers,
        [LOCALE]: answersToSet
      })
    },
    [answers, setAnswers]
  )

  const contextValue = useMemo(
    () => ({
      answers: answers[LOCALE] || {},
      setAnswers: handleSetAnswers,
      hasStartedFitFinder,
      setHasStartedFitFinder,
      isNewCustomer,
      setIsNewCustomer,
      history,
      setHistory
    }),
    [
      answers,
      handleSetAnswers,
      hasStartedFitFinder,
      setHasStartedFitFinder,
      isNewCustomer,
      setIsNewCustomer,
      history,
      setHistory
    ]
  )

  const handleOnCloseFitFinder = () => {
    analytics('Fit Finder Closed')
    onCloseFitFinder()
  }

  return (
    <StepsContext.Provider value={contextValue}>
      <Drawer
        isDrawerOpen={isFitFinderOpen}
        onCloseDrawer={handleOnCloseFitFinder}
        size="medium"
      >
        <FitFinderContainer closeFitFinder={handleOnCloseFitFinder} />
      </Drawer>
    </StepsContext.Provider>
  )
}

export default NewFitFinder
