import cartFragment from '../fragments/cart'

const mutation = `mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(
    cartId: $cartId, lines: $lines
  ) {
    cart ${cartFragment}
    userErrors {
      field
      message
    }
  }
}
`
export default mutation
