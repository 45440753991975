import classNames from 'classnames'
import { ButtonHTMLAttributes } from 'react'
import buttonMinimalStyles from './buttonMinimal.module.scss'
import { Classes } from './ButtonBase'

type ButtonTextSize = 'regular' | 'large' | 'small'

type ButtonMinimalProps = {
  classes?: Classes
  children: React.ReactNode
  textSize?: ButtonTextSize
  underlined?: boolean
  variant?: 'primary' | 'secondary' | 'light'
} & ButtonHTMLAttributes<HTMLButtonElement>

const ButtonMinimal = ({
  variant = 'primary',
  classes,
  children,
  textSize = 'regular',
  underlined = false,
  ...props
}: ButtonMinimalProps) => {
  const buttonClasses = classNames(
    buttonMinimalStyles.buttonMinimal,
    buttonMinimalStyles[`textSize_${textSize}`],
    buttonMinimalStyles[variant],
    classes,
    {
      [buttonMinimalStyles.underlined]: underlined
    }
  )

  return (
    <button {...props} className={buttonClasses} type="button">
      {children}
    </button>
  )
}

export default ButtonMinimal
