const gbCollection = {
  collection: {
    'no-results': {
      'item-out-of-stock': 'is unfortunately out of stock.',
      'all-out-of-stock': 'A thousand apologies — we’re all out of these',
      please: 'Please',
      'clear-filters': 'clear filters',
      or: 'or',
      'click-here': 'click here',
      'see-all': 'to see the rest of the range.'
    },
    filters: {
      'filter-by-your-fit': 'Filter by your fit',
      'filter-my-size': 'Filter my size',
      'size-chart': 'Size chart',
      'whats-my-size': 'What’s my size',
      clear: 'Clear',
      save: 'Save',
      waist: 'Waist',
      build: 'Build',
      inches: 'Inches',
      length: 'Length',
      chest: 'Chest',
      cut: 'Cut',
      size: 'Size',
      'chest size': 'Chest Size',
      trousers: 'Trousers',
      shorts: 'Shorts',
      tops: 'Tops',
      shortsleg: 'Shorts Leg',
      swimshortsleg: 'Swims Leg',
      trunksleg: 'Trunks Leg',
      leg: 'Leg',
      'showing-results': 'Showing %t results'
    }
  }
}

module.exports = gbCollection
