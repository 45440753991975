import styles from './styles.module.scss'

type HelpButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  active: boolean
  classes?: string
} & React.ComponentPropsWithoutRef<'button'>

function HelpButton({
  active,
  onClick,
  classes = '',
  ...props
}: HelpButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      {...props}
      className={`${styles.helpButton} ${
        active ? styles.active : ''
      } ${classes}`}
    >
      {active ? 'x' : '?'}
    </button>
  )
}

export default HelpButton
