import cookies from 'js-cookie'
import { isBrowser } from '@helpers/env'

export const setCookie = (
  cookieName: string,
  cvalue: string,
  exdays = 365,
  subdomain = true
): void => {
  if (isBrowser()) {
    cookies.remove(cookieName)
    cookies.set(cookieName, cvalue, {
      ...(exdays !== 0 && { expires: exdays }),
      path: '/',
      domain:
        subdomain && process.env.API_ENV !== 'development'
          ? `.${process.env.SHOP_DOMAIN}`
          : ''
    })
  }
}

export const getCookieByName = (cookieName: string): string | undefined => {
  if (isBrowser()) {
    return cookies.get(cookieName) || ''
  }
}

export const removeCookie = (cookieName: string) => {
  if (isBrowser()) {
    cookies.remove(cookieName, {
      path: '/'
    })
    cookies.remove(cookieName, {
      path: '/',
      domain: `.${process.env.SHOP_DOMAIN}`
    })
  }
}
