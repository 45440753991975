import { Button } from '@components/ui/Button'
import { NewsletterModalWrapperStepProps } from '../types'

type ConfirmationContentProps = {
  onClose: () => void
  phoneNumber?: string
  email?: string
}

type NewsletterConfirmationProps = NewsletterModalWrapperStepProps & {
  onClose: () => void
  wrapperState: {
    phoneNumber?: string
    email?: string
  }
}

export const ConfirmationContent = ({
  onClose,
  email,
  phoneNumber
}: ConfirmationContentProps) => (
  <>
    <h3>You’re on the list</h3>
    <p>
      {phoneNumber ? (
        <>
          We’ve sent your discount code to
          <br />
          <strong>{phoneNumber}</strong>
        </>
      ) : (
        <>
          We’ve emailed your discount code to
          <br />
          <strong>{email}</strong>
        </>
      )}
    </p>
    <div className="confirmationStepButton">
      <Button variant="primary" onClick={onClose} fullWidth="always">
        Continue shopping
      </Button>
    </div>
  </>
)

export const NewsletterConfirmation = ({
  onClose,
  wrapperState
}: NewsletterConfirmationProps) => (
  <ConfirmationContent
    phoneNumber={wrapperState.phoneNumber}
    email={wrapperState.email}
    onClose={onClose}
  />
)
