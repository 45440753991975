import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { trackMessage } from '@helpers/trackError'
import { getCartCookie, setCartCookie } from '@helpers/cart'
import readCart from '@store/redux/actions/readCart'
import Drawer from '@components/ui/Drawer'
import { Loader } from '@components/Loading/LoadingElements'

const CartSideDrawer = dynamic(() => import('./CartSideDrawer'), {
  ssr: false,
  loading: () => <Loader fullHeight={true} />
})

type CartSideDrawerOverlayProps = {
  isCartOpen?: boolean
  closeCart: () => void
  openCart: () => void
}

function CartSideDrawerOverlay({
  isCartOpen = false,
  closeCart,
  openCart
}: CartSideDrawerOverlayProps) {
  const dispatch = useDispatch()
  const router = useRouter()

  const queryCartId = router.query.checkout_id as string | undefined

  useEffect(() => {
    const savedCarttId = getCartCookie()

    if (savedCarttId && queryCartId && queryCartId !== savedCarttId) {
      trackMessage('Saved checkout id is the NOT same as query, ignoring', {
        savedCarttId,
        queryCartId
      })
    }

    if (!savedCarttId && queryCartId) {
      setCartCookie({ value: queryCartId })
    }

    const checkoutId = savedCarttId || queryCartId

    if (checkoutId) {
      dispatch(readCart(checkoutId))
    }
  }, [dispatch, queryCartId])

  useEffect(() => {
    if (queryCartId != null && !isCartOpen && router.route !== '/cart') {
      openCart()
    }
  }, [queryCartId, router.route])

  return (
    <Drawer size="medium" isDrawerOpen={isCartOpen} onCloseDrawer={closeCart}>
      <CartSideDrawer closeCart={closeCart} />
    </Drawer>
  )
}

export default CartSideDrawerOverlay
