import { createClient } from 'contentful'
import { JsonItem } from 'types/contentful'
import { LOCALES } from '../consts/index'

const contentfulClient = createClient({
  space: process.env.API_CONTENTFUL_SPACE,
  environment: process.env.CONTENTFUL_ENV,
  accessToken: process.env.API_CONTENTFUL_TOKEN
})

const contentfulClientPreview = createClient({
  space: process.env.API_CONTENTFUL_SPACE,
  environment: process.env.CONTENTFUL_ENV,
  accessToken: process.env.API_CONTENTFUL_PREVIEW_TOKEN,
  host: 'preview.contentful.com'
})

const contentFul = {
  getEntries(json: JsonItem, isPreview: boolean) {
    const client = isPreview ? contentfulClientPreview : contentfulClient
    return client.getEntries<JsonItem>({
      ...json,
      include: 6,
      locale: LOCALES[process.env.SHOP_LOCALE]
    })
  },

  getEntry(id: string, depth: number, isPreview: boolean) {
    const client = isPreview ? contentfulClientPreview : contentfulClient
    return client.getEntry<JsonItem>(id, {
      locale: LOCALES[process.env.SHOP_LOCALE],
      include: depth
    })
  },

  getAsset(id: string, isPreview: boolean) {
    const client = isPreview ? contentfulClientPreview : contentfulClient

    return client.getAsset(id)
  }
}

export default contentFul
