import { BUILD, SIZES } from '@consts/index'
import { getUserSavedFitData } from '@helpers/session/user'
import { Fit } from 'types/fit'
import { ObjectValues } from 'types/helpers'

export function getCustomerSavedFitFromCookies() {
  try {
    const savedFitCookies = getUserSavedFitData()

    if (
      !savedFitCookies ||
      Object.keys(JSON.parse(savedFitCookies) as Fit).length === 0
    ) {
      return null
    }

    return JSON.parse(savedFitCookies) as Fit
  } catch {
    return null
  }
}

export function getHasCustomerSavedFit() {
  const savedFit = getCustomerSavedFitFromCookies()
  return !!savedFit && Object.keys(savedFit || {}).length > 0
}

export function checkIsCompleteFit(fit: Partial<Fit>): fit is Fit {
  return (
    'waist' in fit &&
    'build' in fit &&
    'leg' in fit &&
    'cut' in fit &&
    'length' in fit &&
    'size' in fit
  )
}

const fitMap = {
  b: 'build',
  l: 'leg',
  w: 'waist',
  s: 'size',
  c: 'cut',
  lh: 'length'
} as const

export function getSavedFitFromUrl(): Fit {
  const mappedSavedFit: Partial<Fit> = {}
  const urlParams = new URL(window?.location.href).searchParams

  const filteredUrlParams = Array.from(urlParams.entries()).filter(
    ([param]) => !!fitMap[param as keyof typeof fitMap]
  )

  filteredUrlParams.forEach(([key, value]) => {
    const fitProperty = fitMap[key as keyof typeof fitMap] as
      | ObjectValues<typeof fitMap>
      | undefined
    const staticValues = fitProperty ? SIZES[fitProperty] : []
    const valueFromStaticSizes = staticValues
      ?.map(value => value.replace(`${BUILD} `, ''))
      .find(
        staticValue =>
          staticValue.toLocaleLowerCase() === value.toLocaleLowerCase()
      )
    if (fitProperty && valueFromStaticSizes) {
      mappedSavedFit[fitProperty] = valueFromStaticSizes
    }
  })

  return mappedSavedFit as Fit
}
