import { ORDERED_OPTIONS } from '@consts/index'
import { objectKeys } from '@helpers/utils'
import { EmptyObject } from 'types/helpers'

export type FilterOption = {
  type: string
  value: string
  position: number
}

export function getFiltersOptionsState(
  filtersOptions: Partial<Record<string, string>> | EmptyObject = {}
) {
  return objectKeys(filtersOptions || {}).reduce(
    (arr: FilterOption[], filter) => [
      ...arr,
      {
        type: filter,
        value: String(filtersOptions[filter]),
        position: 1 + ORDERED_OPTIONS.findIndex(o => filter === o.toLowerCase())
      }
    ],
    []
  )
}
