import { useEffect, useState } from 'react'

function useHashRouter() {
  const [hash, setHash] = useState<null | string>(null)

  const handleHashChange = () => {
    const hash = window.location.hash.substring(1)
    setHash(hash)
  }

  const addHash = (hash: string) => {
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}${window.location.search}#${hash}`
    )
    window.dispatchEvent(new Event('popstate'))
  }

  const removeHash = () => {
    if (window.location.hash) {
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}${window.location.search}`
      )
      window.dispatchEvent(new Event('popstate'))
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handleHashChange)
    handleHashChange()
    return () => {
      window.removeEventListener('popstate', handleHashChange)
    }
  }, [])

  return { hash, addHash, removeHash }
}

export default useHashRouter
