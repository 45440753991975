import React, { ComponentProps } from 'react'
import { useMediaQuery } from '@helpers/hooks'
import { BREAKPOINT_SIZES } from '@consts/index'
import styles from './styles.module.scss'

export type FlashBannerItemWrapperProps = {
  link?: string | null
  backgroundColor?: string | null
  bannerDismissible: boolean
} & ComponentProps<'div'> &
  ComponentProps<'a'>

function FlashBannerItemWrapper({
  backgroundColor,
  link,
  children,
  bannerDismissible,
  ...props
}: React.PropsWithChildren<FlashBannerItemWrapperProps>) {
  const isPhabletDown = useMediaQuery({
    maxWidth: BREAKPOINT_SIZES.TABLET - 1
  })

  return link && isPhabletDown ? (
    <a
      href={link}
      className={`${styles.flashItem__link} ${
        bannerDismissible ? styles.flashItem__linkDismissable : null
      }`}
      style={{ backgroundColor: backgroundColor || undefined }}
      {...props}
    >
      {children}
    </a>
  ) : (
    <div
      className={styles.flashItem}
      style={{ backgroundColor: backgroundColor || undefined }}
      {...props}
    >
      {children}
    </div>
  )
}

export default FlashBannerItemWrapper
