import { CartLineItem } from 'types/checkout'

/* eslint-disable import/prefer-default-export */
const PROPERTY_NAMES = {
  shortsleg: 'Leg',
  taper: 'Lower Leg',
  message: ''
} as Record<string, string>

export function getCartItemProductOption(
  property: string,
  value: string
): string | null {
  if (!property || !value) return null

  const propertyShortName = PROPERTY_NAMES[property.toLowerCase()] || property
  return `${propertyShortName}: ${value}`
}

type CartUpdatePayloadArgs = {
  id: string
  merchandiseId?: string
  quantity: number
  lines: CartLineItem[]
}

export function getCartUpdatePayload({
  id,
  merchandiseId,
  quantity,
  lines
}: CartUpdatePayloadArgs) {
  return { payload: [{ id, quantity, merchandiseId }], newItems: lines }
}
