import { useState, useEffect, useRef } from 'react'
import { debounce } from '@helpers/utils'
import trackError from '@helpers/trackError'

const DEFAULTS = { threshold: 20, eventThrottle: 200 }

type ExitingProps = {
  threshold?: number
  eventThrottle?: number
}

type ExitingResult = {
  isExiting: boolean
  cancelIsExiting: () => void
}

export default function useExiting({
  threshold = DEFAULTS.threshold,
  eventThrottle = DEFAULTS.eventThrottle
}: ExitingProps = DEFAULTS): ExitingResult {
  const [isExiting, setIsExiting] = useState(false)
  const callbackRef = useRef<(event: MouseEvent) => void>()

  const windowBlur = () => setIsExiting(true)

  const cancelIsExiting = () => {
    document.removeEventListener(
      'mousemove',
      callbackRef.current as EventListener
    )
    window.removeEventListener('blur', windowBlur)
  }

  useEffect(() => {
    const mouseDidMove = (event: MouseEvent) => {
      setIsExiting(event.clientY <= threshold)
    }
    const callback = debounce(mouseDidMove, eventThrottle)
    callbackRef.current = callback
    try {
      window.addEventListener('blur', windowBlur, false)
      document.addEventListener('mousemove', callback, false)
      return () => {
        cancelIsExiting()
      }
    } catch (e) {
      const error = e as Error
      trackError(new Error('useExiting error'), { error })
    }
  }, [threshold, eventThrottle])

  return { isExiting, cancelIsExiting }
}
