import { useState } from 'react'

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

type WidthRange = {
  minWidth: number
  maxWidth: number
}

type MediaQueryArgs = Partial<WidthRange> & {
  initialState?: boolean
}

export default function useMediaQuery({
  minWidth,
  maxWidth,
  initialState = false
}: MediaQueryArgs) {
  const [matches, setMatches] = useState(initialState)

  useIsomorphicLayoutEffect(() => {
    const minWidthQuery = minWidth ? `(min-width: ${minWidth}px)` : ''
    const maxWidthQuery = maxWidth ? `(max-width: ${maxWidth}px)` : ''
    const query = `${minWidthQuery}${
      minWidthQuery && maxWidthQuery ? ' and ' : ''
    }${maxWidthQuery}`

    const media = window.matchMedia(query)
    setMatches(media.matches)

    const listener = (event: MediaQueryListEvent) => {
      setMatches(event.matches)
    }

    if (media.addEventListener) {
      media.addEventListener('change', listener)
    } else {
      media.addListener(listener) // For Safari
    }

    return () => {
      if (media.removeEventListener) {
        media.removeEventListener('change', listener)
      } else {
        media.removeListener(listener) // For Safari
      }
    }
  }, [minWidth, maxWidth])

  return matches
}
