import { createContext } from 'react'
import { Answers, History, HistorySetter } from './types'

type StepsContextValueProps = {
  answers: Partial<Answers>
  setAnswers: (arg: Partial<Answers>) => void
  hasStartedFitFinder: boolean
  setHasStartedFitFinder: (arg: boolean) => void
  isNewCustomer: boolean
  setIsNewCustomer: (arg: boolean) => void
  history: History
  setHistory: HistorySetter
}

export const StepsContext = createContext<StepsContextValueProps>({
  answers: {},
  setAnswers: () => undefined,
  hasStartedFitFinder: false,
  setHasStartedFitFinder: () => undefined,
  isNewCustomer: false,
  setIsNewCustomer: () => undefined,
  history: [],
  setHistory: () => undefined
})
