import { getPhoneNumber } from '@helpers/env'
import styles from './styles.module.scss'

const phoneNumber = getPhoneNumber()

function SecondaryNav() {
  return (
    <div className={styles.footerSecondaryNav}>
      <p className={styles.footerSmallprint}>
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      </p>
      <p className={styles.footerSmallprint}>
        <a href="mailto:hello@spoke-london.com">hello@spoke-london.com</a>
      </p>
      <p className={styles.footerSmallprint}>
        <a href="mailto:press@spoke-london.com">press@spoke-london.com</a>
      </p>
    </div>
  )
}

export default SecondaryNav
