import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { HIDE_FLASH_BANNER } from '@consts/index'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { showBanner as showBannerAction } from '@store/redux/actions/otherActions'
import Notification, { NotificationProps } from '@components/ui/Notification'
import styles from './styles.module.scss'
import { RootState } from '@store/redux/reducers'
import { NotificationBannerState } from '@store/redux/reducers/otherReducers'
import PromoBanner from './PromoBanner'
import AppContext from '@components/AppContext'

export const NOTIFICATION_BANNERS = {
  'Discount Code - SORTED':
    'Your 10% discount will be applied at the checkout.',
  'Discount Code - OTHER': 'Your discount will be applied at the checkout.',
  'OOSModal - success':
    'Thank you! We will be in touch when this product becomes available.',
  'Feedback Form - success': 'Thank you for your feedback',
  'Feedback Form - failed': 'We think you might have missed a question',
  'Unsubscribe From Catalog Form - success':
    'Thank you. You will stop receiving our catalogue shortly.',
  'Unsubscribe From Catalog Form - failed':
    'We think you might have missed a question',
  'Add to basket - failed':
    "There was a problem adding this product to basket. Please try again, if that doesn't work please contact Customer Service.",
  'Add to basket - variant problem':
    'Sorry, this product is now out of stock. Please try another size or submit a Back in Stock notification request.',
  'Add to basket - Out of stock':
    'Sorry, this colour is now out of stock. Please try another colour or submit a Back in Stock notification request.'
} as const

export type NotificationType = keyof typeof NOTIFICATION_BANNERS | undefined

export type PushNotificationBannerProps = {
  isVisible: boolean
  type: NotificationType
  theme: NotificationProps['type']
}

function PushNotificationBanner({
  isVisible,
  type,
  theme
}: PushNotificationBannerProps) {
  const dispatch = useDispatch()

  const text = type ? NOTIFICATION_BANNERS[type] : null
  const notificationBannerStyles = classNames(
    styles.notificationBannerWrapper,
    {
      [styles.showNotificationBanner]: isVisible
    }
  )

  const closeBanner = () =>
    dispatch(
      showBannerAction({
        isVisible: false,
        type,
        theme: 'default'
      })
    )

  const shouldShowCloseButton = type && theme !== 'success'

  return !!text ? (
    <div className={notificationBannerStyles}>
      <Notification
        classes={styles.notificationHeader}
        type={theme}
        theme="dark"
        variant="alert"
        content={text}
        dismissible={shouldShowCloseButton}
        onDismiss={closeBanner}
      />
    </div>
  ) : null
}

const FlashBanner = () => {
  const router = useRouter()
  const { flashBanner } = useContext(AppContext)
  const { showBanner, ...banner } = flashBanner || {}

  const notificationBanner = useSelector<RootState, NotificationBannerState>(
    state => state.notificationBanner
  )
  const isDynamicFlashBannerVisible =
    Object.keys(banner).length > 0 &&
    showBanner &&
    !HIDE_FLASH_BANNER.includes(router.query.pagesHandle?.toString?.() ?? '')

  return (
    <>
      <PushNotificationBanner {...notificationBanner} />
      {isDynamicFlashBannerVisible && (
        <section data-testid="DynamicFlashBanner" className={styles.flash}>
          <PromoBanner {...banner} />
        </section>
      )}
    </>
  )
}

export default FlashBanner
