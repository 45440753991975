import { combineReducers } from 'redux'
import filterReducer from './filterReducer'
import cartReducer from './cartReducer'
import cartNotificationReducer from './cartNotificationReducer'
import popUpsTracker from './popUpsTrackerReducers'
import otherReducers from './otherReducers'
import customerFitReducer from './customerFitReducer'

const reducers = {
  filters: filterReducer,
  cart: cartReducer,
  cartNotification: cartNotificationReducer,
  customerFit: customerFitReducer,
  popUpsTracker,
  ...otherReducers
}

const rootReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
