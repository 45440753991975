import { useState } from 'react'
import Translations from '@helpers/translations'
import EmailFieldValidation from '@components/ui/EmailFieldValidation'

type EmailFormProps = {
  onSubmit: (email: string) => void
  children: (name: string) => React.ReactNode
  classes?: string | undefined
  placeholder?: string
}

function EmailForm({
  onSubmit,
  classes,
  placeholder,
  children
}: EmailFormProps) {
  const [email, setEmail] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (email) {
      onSubmit(email)
    }
  }

  return (
    <form method="post" className={classes} onSubmit={handleSubmit} noValidate>
      <EmailFieldValidation
        setInputValue={setEmail}
        placeholder={
          placeholder ||
          Translations.localise(
            'common.newsletter-modal.form.email-placeholder'
          )
        }
      />
      {children(email)}
    </form>
  )
}

export default EmailForm
