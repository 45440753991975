import { updateMailingListSubscription, sendMarketingData } from '@api/apiCalls'
import trackError from '@helpers/trackError'
import { identify } from '@helpers/analytics'

export default async function updateNewsletterSubscription({
  method = 'subscribe',
  email = '',
  phoneNumber = undefined,
  location = '',
  includedChannels = ['email', 'sms']
}) {
  const isOptedIn = method === 'subscribe'
  const identifyProps = {
    email,
    ...(!isOptedIn && {
      $source: `Unsubscribed - ${location}`
    })
  }

  identify(null, { ...identifyProps })

  const response = await updateMailingListSubscription({
    method,
    email,
    phoneNumber,
    source: location,
    includedChannels
  })

  const hasFailed = response?.error
    ? response.error.includes('Error subscribing profile to list')
    : false

  try {
    await sendMarketingData({
      email,
      phoneNumber,
      isOptedIn,
      location,
      list: '1st',
      failed: hasFailed,
      action: false
    })
  } catch (error) {
    trackError(
      new Error('Failed to store customer marketing opt-in preferences'),
      { error }
    )
    throw error
  }

  return hasFailed
}
