/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { modalTrackerAction } from '@store/redux/actions/popUpsTrackerActions'
import ModalContainer from './ModalContainer/index'
import styles from './styles.module.scss'

type ModalProps = {
  content?: React.ReactNode
  onClose: () => void
  openedModal?: boolean
  exclude?: boolean
  theme?: {
    modalPosition?: string
  }
  classes?: string
  children?: React.ReactNode
}

const Modal = ({
  content,
  onClose,
  openedModal = false,
  exclude,
  theme,
  classes,
  children
}: ModalProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!exclude) {
      dispatch(modalTrackerAction({ modal: openedModal }))
    }
  }, [exclude, openedModal, dispatch])

  const handleClose = () => {
    onClose()
    if (!exclude) {
      dispatch(modalTrackerAction({ modal: !openedModal }))
    }
  }

  const blurClicked = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.type !== 'click') return
    handleClose()
  }

  return (
    <RemoveScroll enabled={openedModal} allowPinchZoom={true}>
      {openedModal && (
        <div
          className={styles.modal}
          onClick={blurClicked}
          onKeyDown={blurClicked}
        >
          <ModalContainer onClose={handleClose} theme={theme} classes={classes}>
            {children || content}
          </ModalContainer>
        </div>
      )}
    </RemoveScroll>
  )
}

export default Modal
