const flashBanner = `
 flashBanner {
    showBanner
    backgroundColor
    flashBannerItems: bannerItemsCollection {
      items {
        __typename
        ...on FlashBannerItemWithOffer {
          bannerName
          iconName
          iconSvg {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          messageDesktop
          messageMobile
          backgroundColor
          contentColor
          discount
          discountLabel
          linkTextDesktop
          linkUrl
        }
      }
    }
    flashBannerName
  }
`

const flashBannerNew = `
  flashBannerItem {
    ...on FlashBannerItemWithOffer {
      bannerName
      iconName
      iconSvg {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      messageDesktop
      messageMobile
      backgroundColor
      contentColor
      discount
      discountLabel
      linkTextDesktop
      linkUrl
    }
  }
`

const badgeFields = `
  {
      title
      badgeType
      themeColour
      small
      iconName
  }
`

const imageFields = `
  {
      title
      url
  }
`

export { badgeFields, flashBanner, flashBannerNew, imageFields }
