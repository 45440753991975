type CartNotificationState = {
  showCartNotification: boolean
}

export type CartNotificationAction = {
  payload: boolean
} & (
  | {
      type: 'TOGGLE_CART_NOTIFICATION'
    }
  | {
      type: 'CLOSE_CART'
    }
)

const initState = {
  showCartNotification: false
}

export default function cartNotificationReducer(
  state: CartNotificationState = initState,
  action: CartNotificationAction
): CartNotificationState {
  switch (action.type) {
    case 'TOGGLE_CART_NOTIFICATION':
      return { showCartNotification: !!action.payload }

    case 'CLOSE_CART':
      return { showCartNotification: false }

    default:
      return state
  }
}
