import { HeaderImage } from 'types/header'
import styles from './style.module.scss'
import Badge, { BadgeProps } from '@components/ui/Badge'

export type PromoImageProps = {
  title: string | null
  handle: string | null
  image: HeaderImage | null
  badge?: BadgeProps | null
  width: number
  height: number
  visible: boolean | null
  onClick: (title: string) => void
}

export default function PromoImage({
  title,
  handle,
  image,
  width,
  height,
  badge,
  onClick
}: PromoImageProps) {
  return (
    <a
      href={handle || ''}
      {...(title && { onClick: () => onClick(title) })}
      className={styles.promoImageWrapper}
      style={
        {
          '--width': `${width}px`,
          '--height': `${height}px`
        } as React.CSSProperties
      }
    >
      <div className={styles.promoImageWrapper__promoImage}>
        <img src={image?.url} alt={image?.title} />

        {badge && (
          <Badge
            classes={styles.promoImageWrapper__badge}
            title={badge.title}
            themeColour={badge.themeColour}
            badgeType={'filled'}
            small={true}
          />
        )}
      </div>
      <h3 className={styles.promoImageWrapper__title}>{title}</h3>
    </a>
  )
}
