import {
  NotificationType,
  PushNotificationBannerProps
} from '@components/FlashBanner'

export type CartOpenState = boolean
export type CartOpenAction = {
  type: 'OPEN_CART' | 'CLOSE_CART'
}

export type SearchDrawerState = boolean
export type SearchDrawerAction = {
  type: 'OPEN_SEARCH' | 'CLOSE_SEARCH'
}

export type NotificationBannerState = {
  isVisible: boolean
  type: NotificationType
  theme: PushNotificationBannerProps['theme']
}
export type NotificationBannerAction = {
  type: 'HIDE_SHOW_NOTIFICATION_BANNER'
  payload: {
    isVisible: boolean
    type?: NotificationType
    theme?: NotificationBannerState['theme']
  }
}

type FilterByCategoryState = string
export type FilterByCategoryAction = {
  type: 'CATEGORY_FILTER'
  payload: string
}

function isCartOpen(
  state: CartOpenState = false,
  { type }: CartOpenAction
): CartOpenState {
  switch (type) {
    case 'OPEN_CART':
      return true

    case 'CLOSE_CART':
      return false

    default:
      return state
  }
}

function isSearchDrawerOpen(
  state: SearchDrawerState = false,
  { type }: SearchDrawerAction
): SearchDrawerState {
  switch (type) {
    case 'OPEN_SEARCH':
      return true

    case 'CLOSE_SEARCH':
      return false

    default:
      return state
  }
}

function notificationBanner(
  state: NotificationBannerState = {
    isVisible: false,
    type: undefined,
    theme: 'default'
  },
  { type, payload }: NotificationBannerAction
): NotificationBannerState {
  if (type === 'HIDE_SHOW_NOTIFICATION_BANNER') {
    return { ...state, ...payload }
  }
  return state
}

function filterByCategory(
  state: FilterByCategoryState = 'All',
  { type, payload }: FilterByCategoryAction
): FilterByCategoryState {
  if (type === 'CATEGORY_FILTER') {
    return payload
  }
  return state
}

const reducers = {
  isCartOpen,
  isSearchDrawerOpen,
  notificationBanner,
  filterByCategory
}

export default reducers
