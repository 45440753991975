import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type FlashBannerMessageWrapperProps = {
  center: boolean
  bannerDismissible: boolean
}

function FlashBannerMessageWrapper({
  center,
  children,
  bannerDismissible
}: React.PropsWithChildren<FlashBannerMessageWrapperProps>) {
  const classes = classNames(styles.flashContent, {
    [styles.flashContent_center]: center,
    [styles.flashContent__dismissible]: bannerDismissible
  })
  return <div className={classes}>{children}</div>
}

export default FlashBannerMessageWrapper
