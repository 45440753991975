import { ThunkAction } from 'redux-thunk'
import { initFit } from '@helpers/initFit'
import trackError from '@helpers/trackError'
import { ORDERED_OPTIONS } from '@consts/index'
import {
  FilterAction,
  DynamicFilterAction,
  ResetFilterAction
} from '../reducers/filterReducer'

export function selectDynamicFilter(
  payload: DynamicFilterAction['payload']
): DynamicFilterAction {
  return {
    type: 'FILTER_DYNAMIC_SELECTED',
    payload
  }
}

export function resetDynamicFilters(
  payload?: ResetFilterAction['payload']
): ResetFilterAction {
  return {
    type: 'RESET_DYNAMIC_FILTERS',
    payload
  }
}

export function updateFilterOptions(): ThunkAction<
  void,
  unknown,
  unknown,
  FilterAction
> {
  return dispatch => {
    try {
      const parsedCookie = initFit()
      const filter = Object.keys(parsedCookie).map(type => ({
        type,
        value: parsedCookie[type]?.toString() || '',
        position:
          1 + ORDERED_OPTIONS.findIndex(option => type === option.toLowerCase())
      }))
      dispatch(selectDynamicFilter(filter))
    } catch (e) {
      const error = e as Error
      trackError(new Error(error.message))
    }
  }
}
