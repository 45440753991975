import { ProductOption } from 'types/product'

export function getSortedProductOptionTypes(
  productOptions: ProductOption[] = []
) {
  return [
    ...new Set(
      productOptions
        .sort((a, b) => (b.position < a.position ? 1 : -1))
        .map(option => option.type)
    )
  ]
}
