const dePages = {
  pages: {
    /*           */
    /*  Returns  */
    /*           */
    returns: {
      'arrange-a-return': 'Artikel zurückgeben',
      'order-number': 'Bestellnummer',
      'order-title': 'Deine bestellung',
      postcode: 'Postleitzahl',
      continue: 'Weiter',
      'need-help': 'Hilfe',
      'shop-the-store': 'Zum Shop',
      shop: 'Shop',
      exchange: 'Umtauschen',
      'exchange-lc': 'umtauschen',
      'exchanges-lc': 'umtauschen',
      refund: 'Erstattung',
      'refund-lc': 'Erstattung',
      'refunds-lc': 'Erstattung',
      'fit-promise-1': 'Sie haben bis',
      'fit-promise-2': 'Zeit, um kostenlos ein passendes Modell zu finden',
      'total-items': 'Artikel',
      'items-selected': 'elemente ausgewählt',
      'make-an-exchange': 'Artikel umtauschen',
      'return-reason': 'Grund für den Umtausch',
      'too-tight': 'Artikel ist zu eng',
      'too-loose': 'Artikel ist zu weit',
      'length-not-right': 'Die Länge passt nicht',
      'ordered-more-than-one': 'Ich habe mehrere Größen bestellt',
      'poor-quality': 'Schlecht Qualität / Fehler',
      'confirm-and-continue': 'Rückgabe bestätigen und fortfahren',
      'select-your-size': 'Wählen Sie Ihre neue Größe',
      other: 'Andere',
      taper: 'Taper',
      done: 'Fertig',
      edit: 'Bearbeiten',
      cancel: 'Stornieren',
      'returns-notifications': {
        'all-items-returned':
          'Alle Artikel dieser Bestellung wurden zurückgegeben',
        'carrier-selection':
          'Bitte wählen Sie einen Lieferservice aus, um die Rücksendung abzuschließen',
        'return-label': {
          'copy-1': 'Your label could not be generated. Please',
          'copy-2': 'get in touch',
          'copy-3': 'to resolve this problem.'
        },
        'return-carrier': {
          'copy-1':
            'There is an issue providing carriers for this return. Please',
          'copy-2': 'get in touch',
          'copy-3': 'to resolve this problem or',
          'copy-4': 'go back',
          'copy-5': 'to your returns.'
        },
        'return-default': {
          'copy-1': 'Your return could not be issued. Please',
          'copy-2': 'get in touch',
          'copy-3': 'to resolve this problem.'
        },
        'system-down':
          'Wir sehen einige Probleme mit Rücksendungen. Bitte versuchen Sie es später erneut.',
        'maintenance-mode': {
          'copy-1':
            'Geplante Wartung: Unser Retourenportal ist derzeit offline, wird aber in Kürze wieder zur Verfügung stehen!',
          'copy-2': 'Bitte versuche es in einer Stunde erneut.'
        }
      },
      'return-created': 'Rückgabe erstellt',
      'request-a-refund': 'Rückerstattung anfordern',
      'select-courier': 'Lieferservice auswählen',
      'return-selected': 'Ausgewählte Artikel zurückgeben',
      'generate-return': 'Retoure veranlassen',
      'all-done': 'FERTIG. Prüfen Sie bitte Ihren Posteingang',
      'finish-your-return': 'Beenden Sie Ihre Rückkehr',
      'qr-label-sent':
        'Ihr QR Code und Ihr Retourenlabel wurden Ihnen per E-Mail zugeschickt.',
      'label-instructions':
        'Drucken Sie das Retourenlabel aus und kleben Sie es auf ihr Paket oder, falls Ihre Post QR-Codes lesen kann, nehmen Sie diesen einfach mit zum Postamt. Lassen Sie sich eine Versandquittung geben und bewahren Sie diese auf, bis Sie von uns gehört haben.',
      'got-it': 'Erledigt',
      'label-generated': 'Retourenlabel Erstellt',
      'last-step': 'Letzter Schritt',
      'view-qr-code': 'QR-Code anzeigen',
      'new-size-requested': 'Gewünschte Größe bei Umtausch',
      'refund-requested': 'Rückerstattung beantragt',
      'refund-selected': 'Rückerstattung ausgewählt',
      'return-note-modal-title': 'Geht das alles in ein Paket?',
      'return-note-modal-message':
        'Wenn Sie Ihre Produkte in mehreren Paketen zurücksenden müssen, wählen Sie bitte alle Produkte aus, die in einem Paket zurückkommen, generieren Sie Ihr Rücksendeetikett und starten Sie den Vorgang für das zweite Paket erneut.',
      'qr-code-message': 'Dein QR-Code wurden dir per E-Mail zugesandt.',
      'label-message': 'Dein Rücksendeetikett wurde dir per E-Mail zugesandt.',
      'view-download':
        'Du kannst ihn bei Bedarf auch hier einsehen und herunterladen.',
      'view-returns-label': 'Retourenetikett anzeigen',
      'select-returns-courier':
        'Du musst deinen Retourenkurier auswählen, damit wir dir deinen Retourenaufkleber oder QR-Code senden können.'
    }
  }
}

module.exports = dePages
