const logBuildError =
  type =>
  ({ errorLevel, properties }) => {
    console.error(
      'Build Error',
      JSON.stringify({
        resource: {
          type,
          errorLevel,
          properties
        }
      })
    )
  }

export default logBuildError
