import { useState, useEffect } from 'react'
import SavedFitFirstTimeModal from '@components/FitFinder/SavedFit/SavedFitFirstTimeModal'
import { getSavedFitFromUrl } from '@helpers/customer/fit'
import { Fit } from 'types/fit'

export default function LayoutSavedFitModal() {
  const [savedFitFromUrl, setSavedFitFromUrl] = useState({})
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    setSavedFitFromUrl(getSavedFitFromUrl)
  }, [])

  if (Object.keys(savedFitFromUrl).length === 0 || !showModal) return null

  const handleOnClose = () => {
    setShowModal(false)
    window.sessionStorage.setItem('hide_saved_fit_modal', 'true')
  }

  return (
    <SavedFitFirstTimeModal
      method="fit_refresh"
      category="Save a Fit"
      title="Confirm & save your Fit"
      description="The below Fit is based on your last few orders with us. Save Your Fit now for a smoother experience next time you shop."
      savedFit={savedFitFromUrl as Fit}
      onClose={handleOnClose}
    />
  )
}
