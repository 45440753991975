import { CustomAttribute, Cart, CartLineItem, Price } from 'types/checkout'

type OptionalCurrencyPrice = {
  amount: string
  currencyCode?: string
}

export type CartState =
  | {
      id: string | undefined
      lines: CartLineItem[]
      cost: {
        subtotalAmount: OptionalCurrencyPrice
        totalAmount: OptionalCurrencyPrice
        totalDutyAmount: OptionalCurrencyPrice
        totalTaxAmount: OptionalCurrencyPrice
      }
      attributes: CustomAttribute[]
      checkoutUrl: string
    }
  | Cart

export type UpdateCartAction = {
  type: 'UPDATE_CART'
  payload: Cart
}
export type ResetCartAction = {
  type: 'RESET_CART'
}

type CartAction = UpdateCartAction | ResetCartAction

const initState = {
  id: undefined,
  lines: [],
  cost: {
    subtotalAmount: { amount: '0.0', currencyCode: undefined },
    totalAmount: { amount: '0.0', currencyCode: undefined },
    totalDutyAmount: { amount: '0.0', currencyCode: undefined },
    totalTaxAmount: { amount: '0.0', currencyCode: undefined }
  },
  attributes: [],
  checkoutUrl: ''
}

export default function cartReducer(
  state: CartState = initState,
  action: CartAction
): CartState {
  switch (action.type) {
    case 'UPDATE_CART':
      return { ...state, ...action.payload }

    case 'RESET_CART':
      return initState

    default:
      return state
  }
}
