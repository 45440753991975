import React, { useContext } from 'react'
import styles from './styles.module.scss'
import AccordionContext from './context'

type AccordionButtonProps = {
  children: React.ReactNode
  classes?: string
}

export default function AccordionButton({
  children,
  classes = ''
}: AccordionButtonProps) {
  const { id, isDisabled, onClick, isOpen } = useContext(AccordionContext)

  if (isDisabled) {
    return (
      <span className={`${styles.AccordionButton_textOnly} ${classes}`}>
        {children}
      </span>
    )
  }

  return (
    <button
      type="button"
      className={`${styles.AccordionButton} button ${classes}`}
      onClick={onClick}
      id={`accordion-button-${id}`}
      aria-controls={`accordion-panel-${id}`}
      aria-expanded={isOpen}
    >
      {children}
      <span className={styles.AccordionButton__plus}>+</span>
      <span className={styles.AccordionButton__minus}>-</span>
    </button>
  )
}
