import { ProductOption } from 'types/product'

type Collection = {
  items: {
    options: ProductOption[]
  }[]
}
export function getAvailableProductOptionTypes(collections: Collection[] = []) {
  const optionTypes = collections
    .flatMap(collection => collection.items || [])
    .flatMap(item => item.options || [])
    .sort((a, b) => (b.position < a.position ? 1 : -1))
    .map(option => option.type)
  const dedupedTypes = [...new Set(optionTypes)]
  return dedupedTypes
}
