import cartFragment from '../fragments/cart'

const mutation = `mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
  cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
    cart ${cartFragment}
    userErrors {
      field
      message
    }
  }
}`

export default mutation
