// Inspiration from: https://github.com/tristen/hoverintent

const hoverIntent = (
  el: HTMLElement | undefined,
  onOver: (args: unknown) => void,
  onOut: (args: unknown) => void,
  interval?: number
) => {
  let x: number
  let y: number
  let pX: number
  let pY: number
  let mouseOver = false
  let focused = false
  const hoverIntentModel: {
    remove?: object | undefined
    options?: object
  } = {}
  let state = 0
  let timer:
    | ReturnType<typeof clearTimeout>
    | ReturnType<typeof setTimeout>
    | number = 0

  let options = {
    sensitivity: 50,
    interval: interval || 100,
    timeout: 0,
    handleFocus: false
  }

  const delay = (el: HTMLElement, e: MouseEvent) => {
    if (timer && typeof timer === 'number') timer = clearTimeout(timer)
    state = 0
    return onOut.call(el, e)
  }

  const tracker = (e: MouseEvent) => {
    x = e.clientX
    y = e.clientY
  }

  const compare = (el: HTMLElement, e: MouseEvent) => {
    if (timer && typeof timer === 'number') {
      timer = clearTimeout(timer)
    }
    if (Math.abs(pX - x) + Math.abs(pY - y) < options.sensitivity) {
      state = 1
      return onOver.call(el, e)
    }
    pX = x
    pY = y
    timer = setTimeout(() => {
      compare(el, e)
    }, options.interval)
  }

  // Public methods
  hoverIntentModel.options = (opt: { handleFocus: boolean }) => {
    const focusOptionChanged = opt.handleFocus !== options.handleFocus
    options = { ...options, ...opt }
    if (focusOptionChanged) {
      if (options.handleFocus) {
        return addFocus()
      } else {
        return removeFocus()
      }
    }
    return hoverIntentModel
  }

  const dispatchOver = (e: MouseEvent) => {
    mouseOver = true
    if (timer && typeof timer === 'number') timer = clearTimeout(timer)
    if (!el) return
    el.removeEventListener('mousemove', tracker, false)

    if (state !== 1) {
      pX = e.clientX
      pY = e.clientY

      el.addEventListener('mousemove', tracker, false)

      timer = setTimeout(() => {
        compare(el, e)
      }, options.interval)
    }
  }

  const dispatchOut = (e: MouseEvent) => {
    mouseOver = false
    if (timer && typeof timer === 'number') timer = clearTimeout(timer)
    if (!el) return
    el.removeEventListener('mousemove', tracker, false)

    if (state === 1) {
      timer = setTimeout(() => {
        delay(el, e)
      }, options.timeout)
    }
  }

  const dispatchFocus = (e: FocusEvent) => {
    if (!mouseOver) {
      focused = true
      onOver.call(el, e)
    }
  }

  const dispatchBlur = (e: FocusEvent) => {
    if (!mouseOver && focused) {
      focused = false
      onOut.call(el, e)
    }
  }

  const addFocus = () => {
    if (!el) return
    el.addEventListener('focus', dispatchFocus, false)
    el.addEventListener('blur', dispatchBlur, false)
  }

  const removeFocus = () => {
    if (!el) return
    el.removeEventListener('focus', dispatchFocus, false)
    el.removeEventListener('blur', dispatchBlur, false)
  }

  hoverIntentModel.remove = () => {
    if (!el) return
    el.removeEventListener('mouseover', dispatchOver, false)
    el.removeEventListener('mouseout', dispatchOut, false)
    removeFocus()
  }

  if (el) {
    el.addEventListener('mouseover', dispatchOver, false)
    el.addEventListener('mouseout', dispatchOut, false)
  }

  return () => hoverIntentModel
}

export default hoverIntent
