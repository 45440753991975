import React from 'react'
import styles from './styles.module.scss'

type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  changeValue: (args: { [id: TextInputProps['id']]: string }) => void
  inputErrorClassName?: string
  withButtonRight?: boolean
  handleBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  required?: boolean
  regex?: string
}

const TextInput = ({
  changeValue,
  required = false,
  withButtonRight = false,
  inputErrorClassName = '',
  placeholder,
  value,
  type = 'email',
  handleBlur = () => undefined,
  id,
  ...props
}: TextInputProps) => {
  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeValue({ [id]: e.target.value })
  }
  return (
    <input
      className={`${styles.form__input} ${
        withButtonRight ? styles.form__input_withButtonRight : ''
      } ${inputErrorClassName} ${required ? styles.form__input_large : ''} ${
        id === 'postcode' ? styles.form__input_reducedWidth : ''
      }`}
      id={id}
      name={type}
      value={value}
      placeholder={placeholder}
      type={type}
      onChange={onValueChanged}
      onBlur={handleBlur}
      required={required}
      {...props}
    />
  )
}

export default TextInput
