import { useState, ReactNode, FormEvent, Dispatch, SetStateAction } from 'react'
import { Fit } from 'types/fit'
import { useDispatch } from 'react-redux'
import { customerSetFitAction } from '@store/redux/actions/customerFitActions'
import { analytics } from '@helpers/analytics'
import { getLink } from '@helpers/env'
import { checkIsCompleteFit } from '@helpers/customer/fit'
import { Button, ButtonLink, SubmitButton } from '@components/ui/Button'
import { SubmitButtonHandlers } from '@components/ui/Button/SubmitButton'
import SizeHelper from './SizeHelper'
import SavedFitOption from './SavedFitOption'
import { SizeOptions } from 'types/product'
import { UIState } from './types'
import { CATEGORIES } from './consts'
import styles from './styles.module.scss'
import { updateCustomerFit } from './helpers'

const SIZE_HELPERS_CATEGORIES = {
  bottoms: {
    waist: {
      title: 'Waist',
      description:
        'Our waist sizes run from 28 up to 46 — including those all-too-rare ‘odd’ numbers.'
    },
    build: {
      title: 'Build',
      description:
        'Choose from Builds A, B or C - narrow, regular and wide - for a flawless fit in the seat and thigh.'
    },
    leg: {
      title: 'Leg',
      description:
        'We hand-finish leg lengths to order - from 28" right through to 37", in single-inch increments.'
    }
  },
  tops: {
    size: {
      title: 'Size',
      description:
        'Ranging from S to XXL, with ‘half-sizes’ M+ and L+ - for those who are forever in-between.'
    },
    cut: {
      title: 'Cut',
      description:
        'Fine-tune your chest and sleeve fit with Slim or Regular cuts — sizes built to flatter every tricep and torso.'
    },
    length: {
      title: 'Length',
      description:
        'Choose from Short, Regular or Long to fine tune your sleeve and body length.'
    }
  }
}

type CategoryId = keyof typeof SIZE_HELPERS_CATEGORIES

type RenderFunction = ({
  uiState,
  setUIState,
  handleOnSubmit
}: {
  uiState: UIState
  setUIState: Dispatch<SetStateAction<UIState>>
  handleOnSubmit: ({ event, triggerButtonReady }: SubmitButtonHandlers) => void
}) => ReactNode

type SaveFitWrapperProps = {
  variant?: 'small' | 'responsive'
  noPadding?: boolean
  method: string
  title: string
  description: string
  savedFit: Fit
  showShopButtons: boolean
  showImage: boolean
  afterSubmit?: (fitSelection: Fit) => void
  children?: RenderFunction
}

type SizeHelpersBottom = keyof (typeof SIZE_HELPERS_CATEGORIES)['bottoms']
type SizeHelpersTop = keyof (typeof SIZE_HELPERS_CATEGORIES)['tops']

type SizeHelpers = {
  bottoms: SizeHelpersBottom | undefined
  tops: SizeHelpersTop | undefined
}

function getHelperCopy(
  property: SizeHelpersTop | SizeHelpersBottom | undefined
) {
  if (!property) return undefined

  if (property in SIZE_HELPERS_CATEGORIES.bottoms) {
    return SIZE_HELPERS_CATEGORIES.bottoms[property as SizeHelpersBottom]
  }

  if (property in SIZE_HELPERS_CATEGORIES.tops) {
    return SIZE_HELPERS_CATEGORIES.tops[property as SizeHelpersTop]
  }
}

function SaveFitWrapper({
  variant = 'responsive',
  noPadding,
  method,
  title,
  description,
  savedFit,
  showShopButtons,
  showImage,
  afterSubmit = () => undefined,
  children
}: SaveFitWrapperProps) {
  const dispatch = useDispatch()
  const [uiState, setUIState] = useState<UIState>('read')
  const [fitSelection, setFitSelection] = useState(savedFit)
  const [sizeHelpers, setSizeHelpers] = useState<SizeHelpers>({
    bottoms: undefined,
    tops: undefined
  })

  const isFitSelectionComplete = checkIsCompleteFit(fitSelection)

  const isFitSelectionChanged =
    JSON.stringify(fitSelection) !== JSON.stringify(savedFit)

  const handleSetSelection = (selection: Partial<Fit>) => {
    setFitSelection(currentSelection => ({ ...currentSelection, ...selection }))
  }

  const handleOnSubmit = async ({
    event,
    triggerButtonError,
    triggerButtonSuccess
  }: SubmitButtonHandlers) => {
    event.preventDefault()

    try {
      await updateCustomerFit(fitSelection, method)

      setUIState('read')

      analytics('Click', {
        label: 'Save',
        category: 'Fit Finder'
      })

      analytics('Fit Updated', {
        fit_source: method
      })

      await triggerButtonSuccess()

      dispatch(
        customerSetFitAction({
          updatedAt: new Date().toISOString(),
          fit: fitSelection
        })
      )

      afterSubmit(fitSelection)
    } catch (error) {
      triggerButtonError()
    }
  }

  const handleOnReset = (event: FormEvent) => {
    event.preventDefault()
    setFitSelection(savedFit)
    setUIState('read')
  }

  const handleOpenSizeHelper =
    (category: CategoryId) => (sizeHelper: SizeOptions) => {
      setSizeHelpers(currentSizeHelpers => ({
        ...currentSizeHelpers,
        [category]: sizeHelper
      }))
    }

  const handleCloseSizeHelper = (category: CategoryId) => {
    setSizeHelpers(currentSizeHelpers => ({
      ...currentSizeHelpers,
      [category]: undefined
    }))
  }

  return (
    <div
      className={`${styles.SaveFitWrapper} ${styles[variant]} ${
        noPadding ? styles.noPadding : ''
      }`}
    >
      {variant === 'responsive' && showImage && (
        <div className={styles.SaveFitWrapper__image}>
          <picture>
            <source
              media="(min-width: 700px)"
              srcSet="/static/images/SavedFitPageDesktop.jpg"
            />
            <img
              alt="Trousers size options example"
              src="/static/images/SavedFitPageMobile.jpg"
            />
          </picture>
        </div>
      )}
      <div className={styles.SaveFitWrapper__content}>
        <h2>{title}</h2>
        <p className={styles.SaveFitWrapper__description}>{description}</p>
        <form onReset={handleOnReset}>
          <div className={styles.SaveFitWrapper__cards}>
            {CATEGORIES.map(({ name, sizeOptions, button, id }) => {
              const sizeHelperCategory = SIZE_HELPERS_CATEGORIES[id]
              const activeSizeHelper = sizeHelpers[id]
              const helperCopy = getHelperCopy(activeSizeHelper)
              return (
                <div
                  key={name}
                  data-testid="saved-fit-category"
                  className={styles.SaveFitWrapper__card}
                >
                  {!!helperCopy && (
                    <SizeHelper
                      title={helperCopy?.title}
                      description={helperCopy?.description}
                      onClose={() => handleCloseSizeHelper(id)}
                    />
                  )}
                  <h2>{name}</h2>
                  {sizeOptions.map(sizeOption => (
                    <SavedFitOption
                      key={sizeOption}
                      sizeOption={sizeOption}
                      fitSelection={fitSelection}
                      uiState={uiState}
                      savedFit={savedFit}
                      handleSetSelection={handleSetSelection}
                      openSizeHelper={
                        sizeHelperCategory[
                          sizeOption.toLocaleLowerCase() as keyof typeof sizeHelperCategory
                        ]
                          ? handleOpenSizeHelper(id)
                          : undefined
                      }
                    />
                  ))}
                  {showShopButtons && (
                    <ButtonLink
                      variant="primary"
                      href={getLink(button.href)}
                      size="small"
                      fullWidth="always"
                      disabled={uiState === 'edit'}
                    >
                      {button.text}
                    </ButtonLink>
                  )}
                </div>
              )
            })}
          </div>
          {!!children && children({ uiState, setUIState, handleOnSubmit })}
          {uiState === 'edit' && (
            <div className={styles.SaveFitWrapper__buttons}>
              <SubmitButton
                type="button"
                variant="primary"
                fullWidth="always"
                onClick={handleOnSubmit}
                disabled={!isFitSelectionComplete || !isFitSelectionChanged}
              >
                Save
              </SubmitButton>
              <Button type="reset" variant="secondary" fullWidth="always">
                Cancel
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
export default SaveFitWrapper
