import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import useLocalStorage from '@helpers/hooks/useLocalStorage'
import { getCookieByName } from '@helpers/session'
import useScrollPosition from '@helpers/hooks/useScrollPosition'
import Translations from '@helpers/translations'
import { getCustomerSavedFitFromCookies } from '@helpers/customer/fit'
import { analytics } from '@helpers/analytics'
import { ReactComponent as CloseIcon } from '@svgs/close.svg'
import PromoBanner from '@components/FlashBanner/PromoBanner'
import styles from './styles.module.scss'

export type CartVisibilityState = {
  isCartOpen: boolean
}

function getFitBannerVisibilityState(pathname: string) {
  const savedFit = getCustomerSavedFitFromCookies()

  const isReturningCustomer = !!getCookieByName('th_LV')

  const isValidUrl = ['/products', '/collections'].includes(pathname)

  return !savedFit && !isReturningCustomer && isValidUrl
}

const FitFinderPromoBanner = ({
  onOpenFitFinder
}: {
  onOpenFitFinder: () => void
}) => {
  const router = useRouter()
  const {
    query: { productType },
    pathname
  } = router

  const isCartOpen = useSelector(
    (state: CartVisibilityState) => state.isCartOpen
  )
  const scrollPosition = useScrollPosition()

  const [hideFitFinderHint, setHideFitFinderHint] = useLocalStorage(
    'hideFitFinderHint',
    false
  )

  const showFitFinderPromoBanner =
    getFitBannerVisibilityState(pathname) && !hideFitFinderHint

  useEffect(() => {
    if (showFitFinderPromoBanner) {
      analytics(
        'Fit banner viewed',
        {
          label: 'Fit banner',
          product_type: productType,
          url: window.location.href
        },
        {
          integrations: {
            PostHog: false
          }
        }
      )
    }
  }, [showFitFinderPromoBanner])

  const closeBanner = () => {
    if (!!setHideFitFinderHint) {
      setHideFitFinderHint(true)
    }
  }

  const handleOnClick = () => {
    analytics('Click', {
      category: `Product ${
        window.location.href.includes('collections') ? 'Collection' : 'Product'
      } Page`,
      label: "Let's go",
      url: window.location.href
    })
    onOpenFitFinder()
  }

  if (!showFitFinderPromoBanner || isCartOpen) return null

  const isFitFinderHintVisible = scrollPosition > 150 && !hideFitFinderHint

  return (
    <div
      className={`${styles.FitFinderNotification} ${
        isFitFinderHintVisible ? styles.FitFinderNotificationVisible : ''
      }`}
    >
      <div className={styles.FitFinderNotification__wrapper}>
        <div className={styles.FitFinderNotification__container}>
          <PromoBanner
            contentColor="white"
            iconName="fit-clock"
            linkUrl={null}
            linkTextDesktop={Translations.localise(
              'pages.fit-finder.promo-banner.link'
            )}
            messageDesktop={Translations.localise(
              'pages.fit-finder.promo-banner.message'
            )}
            messageMobile={Translations.localise(
              'pages.fit-finder.promo-banner.message'
            )}
            dismissible
            showIconMobile
            onClick={handleOnClick}
            backgroundColor={null}
            discount={null}
            discountLabel={null}
            iconSvg={null}
          />
          <div className={styles.FitFinderNotification__closeButton}>
            <button type="button" onClick={closeBanner}>
              <CloseIcon
                aria-label="close icon"
                width="15"
                height="15"
                fill="#ffffff"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FitFinderPromoBanner
