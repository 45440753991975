import React, { useState } from 'react'
import isMobile from 'is-mobile'
import useOutsideClick from '@helpers/hooks/useOutsideClick'
import useEscapeKey from '@helpers/hooks/useEscapeKey'

import { ReactComponent as SelectIcon } from '@svgs/select-icon.svg'
import FitSelectorList, { ListProps } from './FitSelectorList'
import styles from './styles.module.scss'

type FitSelectorProps<T extends string> = {
  classes?: string
  defaultValue: T
  selectedClasses?: string
  isEditable?: boolean
  originalSelectedValue?: string
  placeholder?: string
} & ListProps

function FitSelector<T extends string>({
  classes = '',
  selectedClasses = '',
  originalSelectedValue,
  isEditable = true,
  defaultValue,
  placeholder,
  list,
  selection = null,
  setSelection,
  id
}: FitSelectorProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useOutsideClick<HTMLButtonElement>(() => setIsOpen(false))

  const handleClick = () => {
    if (isEditable) {
      setIsOpen(!isOpen)
    }
  }

  useEscapeKey(() => {
    if (isOpen) {
      handleClick()
    }
  })

  const shouldShowNative = isMobile({ tablet: true })

  const selectedLabel =
    (list.find(l => l.value.toString() === selection?.toString()) || {})
      .label || defaultValue

  if (shouldShowNative) {
    return (
      <section className={`${styles.FitSelector} ${classes}`}>
        <div
          className={`${styles.FitSelector__selectButton} ${
            isOpen ? styles.isOpen : ''
          } ${isEditable ? styles.isEditable : ''}`}
        >
          {selection !== originalSelectedValue && (
            <span className={styles.FitSelector__previouslySelectedValue}>
              {originalSelectedValue}
            </span>
          )}
          <select
            value={selection || defaultValue}
            onChange={e => {
              setSelection({ [id]: e.target.value })
            }}
          >
            {placeholder && !defaultValue && (
              <option value={placeholder} disabled>
                {placeholder}
              </option>
            )}
            {list.map(item => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              )
            })}
          </select>
          <SelectIcon width="8" height="10" className={styles.arrowDown} />
        </div>
      </section>
    )
  }

  return (
    <div className={`${styles.FitSelector} ${classes}`}>
      <button
        type="button"
        ref={ref}
        className={`${styles.FitSelector__selectButton} ${
          isOpen ? styles.isOpen : ''
        } ${isEditable ? styles.isEditable : ''}`}
        onClick={handleClick}
        tabIndex={0}
        data-hide={!isOpen}
      >
        {selection !== originalSelectedValue && (
          <span className={styles.FitSelector__previouslySelectedValue}>
            {originalSelectedValue}
          </span>
        )}
        <p
          className={`${styles.FitSelector__selected} ${selectedClasses || ''}`}
          data-value={id}
        >
          {selectedLabel}
        </p>
        <SelectIcon width="8" height="10" className={styles.arrowDown} />
      </button>
      <FitSelectorList
        list={list}
        selection={selection}
        setSelection={setSelection}
        isOpen={isOpen}
        id={id}
      />
    </div>
  )
}

export default FitSelector
