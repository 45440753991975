export type PopUpsTrackerState = {
  flyoutMenu: boolean
  modal: boolean
  collectionFilters: boolean
}

const initState = {
  flyoutMenu: false,
  modal: false,
  collectionFilters: false
}

export type FlyoutMenuAction = {
  type: 'TRACK_FLYOUT_MENU'
  payload: { flyoutMenu: boolean }
}

export type ModalAction = {
  type: 'TRACK_MODAL'
  payload: { modal: boolean }
}

export type CollectionFiltersAction = {
  type: 'TRACK_COLLECTION_FILTERS'
  payload: { collectionFilters: boolean }
}

type PopUpsTrackerAction =
  | FlyoutMenuAction
  | ModalAction
  | CollectionFiltersAction

export default function popUpsTrackerReducer(
  state: PopUpsTrackerState = initState,
  action: PopUpsTrackerAction
): PopUpsTrackerState {
  switch (action.type) {
    case 'TRACK_FLYOUT_MENU':
      return { ...state, flyoutMenu: action.payload.flyoutMenu }

    case 'TRACK_MODAL':
      return { ...state, modal: action.payload.modal }

    case 'TRACK_COLLECTION_FILTERS':
      return { ...state, collectionFilters: action.payload.collectionFilters }

    default:
      return state
  }
}
