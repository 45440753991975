import { ProductOption, ProductVariant } from 'types/product'
import { FilterOption } from 'types/filters'
import { objectKeys } from '@helpers/utils'
import { BUILD, FINISHED_TO_ORDER, SLIM_TAPER_REGEX } from '@consts/index'
import { getSortedProductOptionTypes } from './getSortedProductOptionTypes'

type GetFilteredVariantsArgs = {
  productOptions: ProductOption[]
  variant: ProductVariant
  filterOptions: FilterOption[]
}

export function getFilteredVariants({
  productOptions,
  variant,
  filterOptions
}: GetFilteredVariantsArgs) {
  const productOptionTypes = getSortedProductOptionTypes(productOptions)

  const options = objectKeys(variant).filter(variantKey => {
    return /^option/.test(variantKey) && variant[variantKey]
  })

  if (!options.length) return false

  return options.sort().every((optionKey, i) => {
    const optionValue = String(variant[optionKey])
    const shouldFilterOutFTOVariants =
      filterOptions.findIndex(filter => {
        return (
          filter.type === productOptionTypes[i] &&
          filter.value.replace(BUILD, '').trim() !==
            optionValue
              .replace(BUILD, '')
              .replace(SLIM_TAPER_REGEX, 'Slim')
              .trim() &&
          FINISHED_TO_ORDER !== optionValue
        )
      }) > -1

    return !shouldFilterOutFTOVariants
  })
}
