import { Fit } from 'types/fit'
import { updateFit, UpdateFitBody } from '@api/fit'
import { setCookie } from '@helpers/session'
import {
  getUserEmailData,
  getCustomerId,
  getUserPseudoTokenCookie
} from '@helpers/session/user'
import { identify } from '@helpers/analytics'
import { COOKIE_EXPIRE_1_YEAR, COOKIE_EXPIRE_3_MONTHS } from '@consts/index'
import trackError from '@helpers/trackError'
import { getAnalyticsCountryCode } from '@helpers/env'

export async function updateCustomerFit(fitSelection: Fit, method: string) {
  const customerId = getCustomerId()
  const userEmail = getUserEmailData()
  const userPseudoToken = getUserPseudoTokenCookie()

  if (userPseudoToken) {
    const payload: UpdateFitBody = {
      token: userPseudoToken,
      method,
      storeLocation: getAnalyticsCountryCode(),
      fit: {
        top: {
          size: fitSelection.size,
          cut: fitSelection.cut,
          length: fitSelection.length
        },
        bottom: {
          waist: `${fitSelection.waist}`,
          build: fitSelection.build,
          leg: `${fitSelection.leg}`
        }
      }
    }

    if (userEmail) {
      payload.email = userEmail
    }

    if (customerId) {
      payload.customerId = customerId
      identify(customerId, fitSelection)
    }

    try {
      await updateFit(payload)
      const fit = JSON.stringify(fitSelection)
      setCookie('saved_fit', fit, COOKIE_EXPIRE_1_YEAR, true)
      setCookie('filters', fit, COOKIE_EXPIRE_3_MONTHS)
    } catch (error) {
      trackError(new Error('Failed to update customer Fit'), {
        error: error as Error
      })
      throw error
    }
  }
}
