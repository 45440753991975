import { CSSTransition } from 'react-transition-group'
import { RemoveScroll } from 'react-remove-scroll'
import useEscapeKey from '@helpers/hooks/useEscapeKey'
import useOutsideClick from '@helpers/hooks/useOutsideClick'
import styles from './styles.module.scss'
import { useCallback } from 'react'

type DrawerProps = {
  isDrawerOpen: boolean
  onCloseDrawer: () => void
  size?: 'small' | 'medium' | 'auto'
  origin?: 'right' | 'bottom'
}

function Drawer({
  size = 'small',
  origin = 'right',
  isDrawerOpen = false,
  onCloseDrawer,
  children
}: React.PropsWithChildren<DrawerProps>) {
  const handleOnCloseDrawer = useCallback(() => {
    if (isDrawerOpen) {
      onCloseDrawer()
    }
  }, [isDrawerOpen, onCloseDrawer])

  const drawerRef = useOutsideClick<HTMLDivElement>(handleOnCloseDrawer)

  useEscapeKey(handleOnCloseDrawer)

  return (
    <CSSTransition
      in={isDrawerOpen}
      mountOnEnter
      unmountOnExit
      timeout={400}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone
      }}
    >
      <RemoveScroll enabled={isDrawerOpen}>
        <div className={styles.drawerOverlay}>
          <div
            className={`${styles.drawer} ${styles[`size_${size}`]} ${
              styles[`origin_${origin}`]
            }`}
            ref={drawerRef}
          >
            {children}
          </div>
        </div>
      </RemoveScroll>
    </CSSTransition>
  )
}

export default Drawer
