import { HeaderLink } from 'types/header'
import styles from './style.module.scss'
import Badge, { BadgeProps } from '@components/ui/Badge'

type MenuLinkProps = {
  title: string
  link: HeaderLink
  badge?: BadgeProps | null
  size?: 'default' | 'large'
  handleOnClick: (arg: HeaderLink) => void
}

export default function MenuButtonLink({
  title,
  link,
  badge,
  size = 'default',
  handleOnClick
}: MenuLinkProps) {
  const isLinkHighlighted = link.highlightLink
  const isLargeFontLink = size === 'large'
  return (
    <button
      className={`${styles.link} ${
        isLargeFontLink ? styles.link__largeFont : ''
      } ${isLinkHighlighted ? styles.link__highlight : ''}`}
      onClick={() => handleOnClick(link)}
    >
      {title}

      {badge && <Badge classes={styles.badge} badgeType="minimal" {...badge} />}
    </button>
  )
}
