import { getLocale } from '@helpers/env'

export const productsPromoByPageHandleQuery = `query($pageHandle:  String!, $preview: Boolean) {
  productsPromo: productsPromoCollection(where: {pageHandlesToDisplayPromo_contains_some: ["all", $pageHandle]}, locale: "${getLocale()}", preview: $preview) {
    items {
      title,
      byline,
      collection,
      pageHandlesToDisplayPromo,
      linkText,
      linkUrl
    }
  }
}`

export const productsPromoByProductTypeQuery = `query($productType:  String!, $preview: Boolean) {
  productsPromo: productsPromoCollection(where: {productTypesToDisplayPromo_contains_some: ["all", $productType]}, locale: "${getLocale()}", preview: $preview) {
    items {
      title,
      byline,
      collection,
      productTypesToDisplayPromo,
      linkText,
      linkUrl
    }
  }
}`
