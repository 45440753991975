import styles from './styles.module.scss'
import { HeaderLink } from 'types/header'
import DefaultGrid from './DefaultGrid'
import PromoGrid from './PromoGrid'

const componentMap = {
  promo: PromoGrid,
  default: DefaultGrid
}

const Megamenu = ({
  grid,
  currentNav
}: {
  grid: HeaderLink['grid']
  currentNav: string
}) => {
  const GridCompoent = componentMap[grid.variant]

  return (
    <div className={`${styles.Megamenu} megamenu-animation`}>
      <div className={styles.Megamenu__container}>
        <GridCompoent grid={grid} currentNav={currentNav} />
      </div>
    </div>
  )
}

export default Megamenu
