import React from 'react'
import { ReactComponent as CloseIcon } from '@svgs/close.svg'
import classNames from 'classnames'
import styles from './styles.module.scss'

type ContentProps = {
  content: React.ReactNode | undefined
}
function Content({ content }: ContentProps) {
  if (!content) {
    return null
  }

  if (typeof content === 'string') {
    return <p>{content}</p>
  }

  return <>{content}</>
}

export type NotificationProps = {
  type?: 'alert' | 'success' | 'info' | 'warning' | 'error' | 'default'
  theme?: 'light' | 'dark'
  variant?: 'alert' | 'success' | 'info' | 'warning' | 'error' | 'default'
  content?: React.ReactNode
  icon?: string
  classes?: string
  dismissible?: boolean
  onDismiss?: () => void
}

const Notification = ({
  type,
  theme = 'light',
  variant,
  content,
  classes,
  dismissible = false,
  onDismiss,
  children
}: React.PropsWithChildren<NotificationProps>) => {
  const notificationClassNames = classNames(
    styles.notification,
    {
      [styles.notification_dismissible]: dismissible,
      [styles[variant || '']]: !!variant,
      [styles[theme]]: !!theme,
      [styles[type || '']]: !!type
    },
    classes
  )

  return (
    <div className={notificationClassNames}>
      <Content content={content} />
      {children}
      {dismissible && (
        <button
          className={styles.notification__button}
          onClick={onDismiss}
          type="button"
        >
          <CloseIcon name="close" width="12" height="12" />
        </button>
      )}
    </div>
  )
}

export default Notification
