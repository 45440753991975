import { useState } from 'react'
import { isBrowser } from '@helpers/env'

type StorageType = 'localStorage' | 'sessionStorage'

export function getStorageValue<T>(
  storageType: StorageType,
  key: string,
  initialValue: T
): T {
  try {
    const item = isBrowser() ? window[storageType]?.getItem(key) : null
    return item ? (JSON.parse(item) as T) : initialValue
  } catch (error) {
    return initialValue
  }
}

export default function useStorage<T>(
  storageType: StorageType,
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState(() =>
    getStorageValue(storageType, key, initialValue as T)
  )
  const setValue = (value: T) => {
    try {
      setStoredValue(value)
      if (isBrowser()) {
        window[storageType]?.setItem(key, JSON.stringify(value))
      }
    } catch (error) {
      setStoredValue(value)
    }
  }
  return [storedValue, setValue]
}
