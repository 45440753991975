import { createContext } from 'react'

type AccordionContextProps = {
  id?: string
  onClick?: () => void
  isOpen?: boolean
  isDisabled?: boolean
}

const AccordionContext = createContext<AccordionContextProps>({
  id: undefined,
  isOpen: false,
  isDisabled: false,
  onClick: () => undefined
})

export default AccordionContext
