import { MobileGridProps } from 'types/header'
import styles from './styles.module.scss'
import PrimaryLinksContainer from '@components/Header/HeaderWrapper/desktop/Megamenu/shared/PrimaryLinksContainer'
import SecondaryLinksContainer from '@components/Header/HeaderWrapper/desktop/Megamenu/shared/SecondaryLinksContainer'
import FeaturedImage from '@components/Header/shared/FeaturedImage'
import { ReactComponent as ArrowPreviousIcon } from '@svgs/chevron-slim.svg'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

const componentMap = {
  primary: PrimaryLinksContainer,
  secondary: SecondaryLinksContainer
}

export default function DefaultMobileGrid({
  grid,
  title,
  currentNav,
  handleGoBack
}: MobileGridProps) {
  const { gridLinks } = grid || []

  const handleClickFeaturedImage = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      columnType: 'Primary Image'
    })
  }

  return (
    <>
      <button onClick={handleGoBack} className={styles.backButton}>
        <ArrowPreviousIcon
          width="8.26"
          height="15.13"
          aria-label="go back icon"
        />
      </button>
      <div className={styles.Megamenu__Grid}>
        <div className={styles.links}>
          <h3 className={styles.title}>{title}</h3>

          {gridLinks.items.map(gridLink => {
            const Component = componentMap[gridLink.variant]
            return (
              <Component
                key={`${gridLink.title}`}
                items={{ ...gridLink }}
                currentNav={currentNav}
              />
            )
          })}
        </div>
        {grid.featuredImageVisibility ? (
          <div className={styles.Megamenu__Grid__featuredImage}>
            <FeaturedImage
              title={grid.featuredImageTitle}
              handle={grid.featuredImageHandle}
              buttonText={grid.featuredImageButtonText}
              badge={grid.featuredImageBadge}
              image={grid.featuredImage}
              width={160}
              height={190}
              onClick={handleClickFeaturedImage}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}
