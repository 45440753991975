import axios from 'axios'
import {
  getShop,
  storeUrl,
  getAnalyticsCountryCode,
  getApiUrl,
  isProdEnv
} from '@helpers/env'
import { isEmptyObject, objectKeys } from '@helpers/utils'
import { setCookie } from '@helpers/session'
import { getIsUserLoggedIn, getCustomerId } from '@helpers/session/user'
import trackError from '@helpers/trackError'
import {
  KLAVIYO_EMAIL_LIST_IDS,
  KLAVIYO_SMS_LIST_IDS,
  HEADERS
} from '../consts'

const SHOP = getShop()
const API_URL = getApiUrl()
const isProd = isProdEnv()

function checkForProductTypesEmptyInventory(data: Record<string, object>) {
  const dataKeys = objectKeys(data)
  dataKeys.forEach(key => {
    const value = data[key]
    if (!value || isEmptyObject(value)) {
      trackError(new Error(`${key} inventory data is empty`), {
        section: 'Inventory'
      })
    }
  })
}

export function postFormRequest(requestBody: object, requestHandle: string) {
  const requestUrl = `${API_URL}${requestHandle}`
  return new Promise((resolve, reject) => {
    axios
      .post(requestUrl, requestBody, {
        headers: HEADERS
      })
      .then(data => resolve(data))
      .catch(e => reject(e))
  })
}

type SendMarketingData = {
  email: string
  phoneNumber?: string
  isOptedIn: boolean
  location: string
  list: string
  failed: boolean
  action: boolean
}
export function sendMarketingData({
  email,
  phoneNumber,
  isOptedIn,
  location,
  list,
  failed,
  action
}: SendMarketingData) {
  const optedIn = failed ? 'opt_in - failed' : 'opt_in'
  const optedOut = failed ? 'explicit_opt_out - failed' : 'explicit_opt_out'
  const explOptedOut = failed
    ? 'no_selection_no_action - failed'
    : 'no_selection_no_action'
  const opted = isOptedIn ? optedIn : action ? optedOut : explOptedOut

  const requestUrl = storeUrl(`events/${SHOP}/opt-in`)
  return axios.post(
    requestUrl,
    {
      email,
      location,
      phone_number: phoneNumber,
      logged_in: getIsUserLoggedIn(),
      list,
      event: opted
    },
    {
      headers: HEADERS
    }
  )
}

type UpdateMailingListSubscriptionArgs = {
  method: string
  email?: string
  phoneNumber?: string
  source: string
  includedChannels?: ('email' | 'sms')[]
}
export async function updateMailingListSubscription({
  method = 'subscribe',
  email,
  phoneNumber,
  source,
  includedChannels = ['email', 'sms']
}: UpdateMailingListSubscriptionArgs) {
  const requestUrl = storeUrl(`v2/mailing-list/${method}`)
  const listIds = []
  const klaviyoEmailListIds = isProd
    ? KLAVIYO_EMAIL_LIST_IDS.prod
    : KLAVIYO_EMAIL_LIST_IDS.dev

  let phoneNumberProperties = null

  if (!email) return new Error('Failed to provide an email for this request')

  if (email && includedChannels.includes('email')) {
    listIds.push({
      channel: 'email',
      list_id: klaviyoEmailListIds.first_tier_marketing
    })
  }

  if (phoneNumber && includedChannels.includes('sms')) {
    const shopLocale = process.env.SHOP_LOCALE
    const smsList = KLAVIYO_SMS_LIST_IDS[shopLocale]
    const klaviyoSmsListId = isProd ? smsList?.prod : smsList?.dev

    listIds.push({
      channel: 'sms',
      list_id: klaviyoSmsListId
    })
    phoneNumberProperties = {
      phone_number: phoneNumber,
      phone_country_code: shopLocale
    }
  }

  const body = {
    email,
    ...phoneNumberProperties,
    store: getAnalyticsCountryCode(),
    list_ids: listIds,
    ...(getCustomerId() && { user_id: getCustomerId() }),
    source
  }
  return axios
    .post(requestUrl, body, { headers: HEADERS })
    .then(r => r)
    .catch(error => {
      trackError(new Error('updateMailingListSubscription failed'), { error })
      return {
        error
      }
    })
}

export function getColors() {
  return axios
    .get(`${API_URL}/colours/${SHOP}`, {
      headers: HEADERS
    })
    .then(({ data }) => {
      if (
        !data ||
        isEmptyObject(data) ||
        !/colour_/.test(Object.keys(data)[0])
      ) {
        return Promise.reject(
          new Error(`Colours data not valid: ${JSON.stringify(data)}`)
        )
      }
      return data
    })
}

export function getProductsInventory(
  product_ids: number[],
  product_types: string[]
) {
  return axios
    .post(
      `${API_URL}/inventory/${SHOP}`,
      {
        product_types,
        product_ids
      },
      {
        headers: HEADERS
      }
    )
    .then(({ data }) => {
      if (isEmptyObject(data)) {
        trackError(new Error('Inventory data is empty'), {
          section: 'Inventory',
          data: {
            product_types
          }
        })
      } else {
        checkForProductTypesEmptyInventory(data)
      }

      return data
    })
}

export function getCollections(handle: string) {
  return axios
    .get(`${API_URL}/collections/${SHOP}/${handle}.json`, {
      headers: HEADERS
    })
    .then(r => r.data)
}

export function getReviewsByProductId(productId: number | string) {
  return axios
    .get(
      `https://api.yotpo.com/v1/widget/${process.env.YOTPO_API_KEY}/products/${productId}/reviews.json`
    )
    .then(res => {
      const {
        data: { status, response }
      } = res

      if (status?.code === 200 && response) {
        return response.bottomline
      }

      return null
    })
}

type SendFeedbackFitResponseArgs = {
  userId: string
  fitResponse: string
}
export function sendFeedbackFitResponse({
  userId,
  fitResponse
}: SendFeedbackFitResponseArgs) {
  return axios
    .post(
      `${API_URL}/feedback/${SHOP}/visit`,
      {
        userId,
        fitResponse,
        loggedIn: getIsUserLoggedIn(),
        storeLocation: getAnalyticsCountryCode()
      },
      {
        headers: HEADERS
      }
    )
    .then(response => {
      const { data } = response || {}
      if (data?.status === 'success') {
        setCookie('spoke_fit_response', 'true')
      }
      return data
    })
    .catch(error => {
      trackError(new Error('sendFeedbackFitResponse failed'), {
        error
      })
      return { error }
    })
}

type ValidatePhoneFormatArgs = {
  phoneNumber: string
  phoneCountryCode: string
}
export function validatePhoneFormat({
  phoneNumber,
  phoneCountryCode
}: ValidatePhoneFormatArgs) {
  return axios
    .post(
      `${API_URL}/validate/phone/${phoneCountryCode}`,
      {
        number: phoneNumber
      },
      {
        headers: HEADERS
      }
    )
    .then(({ data }) => data)
    .catch(error => {
      trackError(new Error('validatePhoneFormat failed'), {
        error
      })
      return {
        error:
          error?.response?.data?.error || 'Failed to validate the phone number'
      }
    })
}

export function submitOutOfStockForm(payload: {
  [x: string]: string | number
}) {
  return axios
    .post(`${API_URL}/products/${SHOP}/out-of-stock`, payload, {
      headers: HEADERS,
      validateStatus: function (status) {
        return status === 200
      }
    })
    .then(({ data }) => data)
}
