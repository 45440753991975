/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { CollectionProduct } from 'types/collection'
import { EmptyObject } from 'types/helpers'
import { Product, ProductVariant } from 'types/product'

export function updateVariants<T extends Product | CollectionProduct>(
  products: T[] = [],
  variants: Record<string, Record<number, ProductVariant>> | EmptyObject = {}
) {
  return products.map(product => {
    const { id, product_type } = product
    const inventoryVariants =
      variants[product_type as keyof typeof variants] || {}
    const productVariants =
      inventoryVariants[id as keyof typeof inventoryVariants] || []
    return { ...product, variants: productVariants }
  })
}
