import { HeaderLink } from 'types/header'
import Megamenu from '../Megamenu'
import megamenuStyles from './desktopMegamenu.module.scss'
import classNames from 'classnames'

type DesktopMenuLinkMegamenuProperties = {
  showFlyoutMenu: boolean
  currentNav: string
  link: HeaderLink
}
export default function DesktopMenuLinkMegamenu({
  showFlyoutMenu,
  currentNav,
  link
}: DesktopMenuLinkMegamenuProperties) {
  const classes = classNames(megamenuStyles.DesktopHeader__megamenu, {
    [megamenuStyles.DesktopHeader__megamenu__open]:
      showFlyoutMenu && currentNav?.toLowerCase() === link.title.toLowerCase(),
    [megamenuStyles.DesktopHeader__megamenu__openActive]:
      showFlyoutMenu && currentNav?.toLowerCase() === link.title.toLowerCase(),
    [megamenuStyles.DesktopHeader__megamenu__closeActive]:
      showFlyoutMenu && currentNav?.toLowerCase() !== link.title.toLowerCase()
  })

  return (
    <div className={classes}>
      <Megamenu grid={link.grid} currentNav={currentNav} />
    </div>
  )
}
