import {
  FINISHED_TO_ORDER,
  BUILD,
  ADDITIONAL_PROPERTIES,
  LOWER_LEG_SLIM_LONG,
  LOWER_LEG_SLIM_SHORT,
  LOWER_LEG_SLIM_SHORT_SKU,
  LOWER_LEG_SLIM_LONG_SKU,
  SLIM_TAPER_REGEX,
  TAPER,
  LEG
} from '@consts/index'
import { getFilteredVariants } from './getFilteredVariants'
import { getSortedProductOptionTypes } from './getSortedProductOptionTypes'
import { getValueFromObject } from '@helpers/utils'
import {
  ProductOption,
  ProductVariant,
  ProductWithVariants
} from 'types/product'
import { EmptyObject } from 'types/helpers'
import { FilterOption } from 'types/filters'

function recursiveCombinationArray(
  names: string[],
  arr: string[],
  incr: number,
  array: string[][] = []
): string[][] {
  let k = 0
  while (k < names.length) {
    if (incr < names.length - 1)
      array = [
        ...recursiveCombinationArray(names, [...arr, names[k]], incr + 1, array)
      ]
    else {
      const newArray = [...new Set([...arr, names[k]])].sort()
      if (array.findIndex(a => a.join('') === newArray.join('')) === -1) {
        array.push(newArray.sort())
      }
    }
    k += 1
  }
  return array
}

export function checkIfLowerLegRulesApply(filterOptions: FilterOption[] = []) {
  return filterOptions?.some(
    filter => filter.type === 'leg' && Number(filter.value) >= 33
  )
}

export function getAllAvailableProductOptionValues(
  product: ProductWithVariants | EmptyObject = {},
  filterOptions: FilterOption[] = [],
  nameOfFilter = ''
) {
  const { variants, options } = product || {}
  const productOptionTypes = getSortedProductOptionTypes(options)
  const productOptionsList = variants
    ? variants.reduce(
        (
          gatherVariant: Omit<ProductOption, 'name' | 'position'>[],
          variant
        ) => {
          const newGatherVariant = [...gatherVariant]

          let keepVariant = true
          Object.keys(variant)
            .filter(v => /^option/.test(v))
            .sort()
            .forEach((key, i) => {
              const shouldFilterOutFTOVariants =
                filterOptions?.findIndex(filter => {
                  return (
                    filter.type === productOptionTypes[i] &&
                    filter.value.toLowerCase() !==
                      String(variant[key as keyof typeof variant])
                        .replace(BUILD, '')
                        .replace(SLIM_TAPER_REGEX, 'Slim')
                        .trim()
                        .toLowerCase() &&
                    FINISHED_TO_ORDER !== variant[key as keyof typeof variant]
                  )
                }) > -1

              const selectedLeg = getValueFromOptions(filterOptions, LEG)

              if (nameOfFilter === LEG.toLowerCase() && selectedLeg) {
                if (
                  +selectedLeg < 32 &&
                  variant.sku?.endsWith(LOWER_LEG_SLIM_LONG_SKU)
                ) {
                  keepVariant = false
                }
                if (
                  +selectedLeg >= 32 &&
                  variant.sku?.endsWith(LOWER_LEG_SLIM_SHORT_SKU)
                ) {
                  keepVariant = false
                }
              }

              if (
                nameOfFilter !== productOptionTypes[i] &&
                shouldFilterOutFTOVariants
              ) {
                keepVariant = false
              }

              const index = gatherVariant.findIndex(
                v => v.type === productOptionTypes[i]
              )
              const optionValue = String(variant[key as keyof typeof variant])
                .replace(BUILD, '')
                .replace(SLIM_TAPER_REGEX, 'Slim')
                .trim()
              if (index === -1) {
                newGatherVariant.push({
                  type: productOptionTypes[i],
                  values: [optionValue]
                })
              } else {
                newGatherVariant[index] = {
                  ...newGatherVariant[index],
                  values: [
                    ...new Set([...newGatherVariant[index].values, optionValue])
                  ]
                }
              }
            })
          return keepVariant && variant.inventory_quantity > 0
            ? newGatherVariant
            : gatherVariant
        },
        []
      )
    : []

  return productOptionsList
}

export function filterOutSomeLowerLegVariants(
  variants: ProductVariant[] = [],
  selectedLegLength?: string
) {
  return variants.filter(variant => {
    if (variant?.option4 && selectedLegLength) {
      if (
        +selectedLegLength < 32 &&
        variant.sku.endsWith(LOWER_LEG_SLIM_LONG_SKU)
      ) {
        return false
      }

      if (
        +selectedLegLength >= 32 &&
        variant.sku.endsWith(LOWER_LEG_SLIM_SHORT_SKU)
      ) {
        return false
      }
    }

    return true
  })
}

function getValueFromOptions(filterOptions: FilterOption[], name: string) {
  return filterOptions.find(
    option => option.type.toLowerCase() === name.toLowerCase()
  )?.value
}

export function getVariantItem(
  product: ProductWithVariants | EmptyObject = {},
  filterOptions: FilterOption[] = []
) {
  const productOptions = getValueFromObject<ProductOption[]>(
    product,
    'options',
    []
  )
  const productVariants = getValueFromObject<ProductVariant[]>(
    product,
    'variants',
    []
  )
  const selectedLegLength = getValueFromOptions(filterOptions, LEG)

  const updatedProductVariants = productVariants.map(variant => {
    if (
      variant.option4 &&
      [LOWER_LEG_SLIM_SHORT, LOWER_LEG_SLIM_LONG].includes(variant.option4)
    ) {
      return { ...variant, option4: 'Slim' }
    }

    return variant
  })

  const filteredVariants =
    updatedProductVariants.filter(
      variant =>
        variant.inventory_quantity > 0 &&
        getFilteredVariants({ productOptions, variant, filterOptions })
    ) || []

  return filterOutSomeLowerLegVariants(filteredVariants, selectedLegLength)
}

export function createFilterCookies(filterOptions: FilterOption[]) {
  return JSON.stringify(
    filterOptions.reduce((filters, o) => {
      return (
        ADDITIONAL_PROPERTIES[o.type as keyof typeof ADDITIONAL_PROPERTIES] ||
        {}
      ).skipInCookies
        ? filters
        : {
            ...filters,
            [o.type === 'shortsleg' ? 'shortsLeg' : o.type]: /waist|leg/.test(
              o.type
            )
              ? parseInt(o.value, 10)
              : o.value.toString()
          }
    }, {})
  )
}

export { getFiltersOptionsState } from './getFiltersOptionsState'
export { updateVariants } from './updateVariants'
export { getSizeOptionsFromType } from './getSizeOptionsFromType'
export { getAvailableProductOptionTypes } from './getAvailableProductOptionTypes'
