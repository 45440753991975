import dynamic from 'next/dynamic'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getCustomerFitCondensed } from '@store/redux/selectors/customerFitSelectors'
import { checkIsDateMoreThanOneYearAgo } from '@helpers/utils'
import { checkIsCompleteFit } from '@helpers/customer/fit'
import { Fit } from 'types/fit'
import { isBrowser } from '@helpers/env'
import { useFitFinderToggle } from '@components/FitFinder/hooks/useFitFinderToggle'
import LayoutSavedFitModal from './LayoutSavedFitModal'
import { useCountryModal } from './CountryModal/hooks'

const LayoutSavedFitConfirmationModal = dynamic(
  () => import('./LayoutSavedFitConfirmationModal')
)
const LayoutNewsletterModal = dynamic(() => import('./LayoutNewsletterModal'))
const CountryModal = dynamic(() => import('./CountryModal'))

function checkHasKeyInStorage(storageKeyName: string) {
  return (isBrowser() && window.sessionStorage.getItem(storageKeyName)) != null
}

export default function LayoutModals() {
  const router = useRouter()
  const { query } = router

  const { shouldShowCountryModal } = useCountryModal(process.env.SHOP_LOCALE)

  const { isFitFinderOpen } = useFitFinderToggle()

  const customerFit = useSelector(getCustomerFitCondensed)

  const shouldShowOutdatedSavedFitModal =
    checkIsCompleteFit(customerFit.fit) &&
    checkIsDateMoreThanOneYearAgo(customerFit.updatedAt)

  const shouldShowSavedFitModal =
    'saved_fit_modal' in query && !checkHasKeyInStorage('hide_saved_fit_modal')

  if (!isBrowser()) {
    return null
  }

  if (shouldShowCountryModal) {
    return <CountryModal shopLocale={process.env.SHOP_LOCALE} />
  }

  if (isFitFinderOpen) {
    return null
  }

  if (shouldShowSavedFitModal) {
    return <LayoutSavedFitModal />
  }

  if (shouldShowOutdatedSavedFitModal) {
    return (
      <LayoutSavedFitConfirmationModal
        savedFit={{
          fit: customerFit.fit as Fit,
          updatedAt: customerFit.updatedAt
        }}
      />
    )
  }

  return <LayoutNewsletterModal />
}
