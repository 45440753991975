import cartFragment from '../fragments/cart'

const mutation = `mutation ($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(
    cartId: $cartId, lines: $lines
  ) {
    cart ${cartFragment}
    userErrors {
      field
      message
    }
  }
}`

export default mutation
