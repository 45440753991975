const usPages = {
  pages: {
    returns: {
      postcode: 'Zipcode'
    },
    /*              */
    /* Unsubscribe  */
    /*              */
    unsubscribe: {
      footer: {
        'paragraph-1': '<div />',
        'paragraph-2': '<div />',
        'paragraph-3': '<div />',
        'paragraph-4': '<div />',
        'paragraph-5': '<div />'
      }
    },
    cart: {
      'free-returns': 'Free Express Shipping & 200-day returns'
    }
  }
}

module.exports = usPages
