import { getLocale } from '@helpers/env'

const productDiscountQuery = `query($productType: String!, $preview: Boolean) {
  productDiscount: productDiscountCollection(where: {productType: $productType}, limit: 1, locale: "${getLocale()}", preview: $preview) {
    items {
      discountTitle
      discountDescription
    }
  }
}`

export default productDiscountQuery
