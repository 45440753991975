import { BUILD, WAIST, LEG, LENGTH, CUT, SIZE } from '@consts/index'

export const CATEGORIES = [
  {
    name: 'Bottom half',
    id: 'bottoms',
    sizeOptions: [WAIST, BUILD, LEG],
    button: {
      text: 'Shop Trousers',
      href: '/collections/trousers'
    }
  },
  {
    name: 'Top half',
    id: 'tops',
    sizeOptions: [SIZE, CUT, LENGTH],
    button: {
      text: 'Shop Tops',
      href: '/collections/tops'
    }
  }
] as const
