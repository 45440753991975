import { useContext, useState } from 'react'
import styles from './styles.module.scss'
import AppContext from '@components/AppContext'
import { jsonToArray } from '@helpers/contentful'
import MobileMenuLinks from './MobileMenuLinks'
import { HeaderLink } from 'types/header'
import MobileMenuGridContainer from './MobileMenuGridContainer'
import MobileSecondaryLinks from './SecondaryMenuLinks'
import DefaultMobileGrid from './mobileGrids/DefaultMobileGrid'
import PromoMobileGrid from './mobileGrids/PromoMobileGrid'

const componentMap = {
  default: DefaultMobileGrid,
  promo: PromoMobileGrid
}

export default function MobileMenuWrapper() {
  const { header } = useContext(AppContext)
  const headerItems = jsonToArray(header.items || [])
  const [currentStep, setCurrentStep] = useState(0)
  const [currentNav, setCurrentNav] = useState('')
  const { grid, title } =
    headerItems.find(link => link.title === currentNav) || {}

  const handlClickMenuItem = (link: HeaderLink) => {
    setCurrentNav(link.title)
    setCurrentStep(1)
  }

  const handleGoBack = () => setCurrentStep(0)

  const GridComponent = componentMap[grid?.variant as keyof typeof componentMap]

  return (
    <div className={styles.MobileMenuWrapper}>
      <MobileMenuGridContainer isActive={currentStep === 0}>
        <MobileMenuLinks
          links={headerItems}
          handlClickMenuItem={handlClickMenuItem}
          currentNav={currentNav}
        />
        <div className={styles.LineSeparator}></div>
        <MobileSecondaryLinks />
      </MobileMenuGridContainer>
      {grid && (
        <MobileMenuGridContainer isActive={currentStep === 1}>
          <GridComponent
            grid={grid}
            handleGoBack={handleGoBack}
            title={title}
            currentNav={currentNav}
          />
        </MobileMenuGridContainer>
      )}
    </div>
  )
}
