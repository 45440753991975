import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { cartUpdate } from '@api/graphqlShopify'
import { basketEvents } from '@helpers/cart'
import { Cart, CartLinesUpdateInput } from 'types/checkout'
import { CartState } from '../reducers/cartReducer'
import { purgeNullVariantsFromCart } from '../cartHelpers'
import {
  updateCart as updateCartAction,
  toggleCartNotification
} from './cartActions'

type UpdateCart = (
  itemsToUpdate: CartLinesUpdateInput[],
  context: string
) => ThunkAction<Promise<Cart>, { cart: CartState }, unknown, Action>

const updateCart: UpdateCart = (itemsToUpdate, context) => {
  return async (dispatch, getState) => {
    const { cart } = getState()

    let latestCart = await cartUpdate(itemsToUpdate)

    const purgedCheckout = await purgeNullVariantsFromCart(latestCart?.lines)

    if (purgedCheckout) {
      dispatch(toggleCartNotification(true))
      latestCart = await cartUpdate(itemsToUpdate)
    }

    dispatch(updateCartAction(latestCart))
    basketEvents({
      checkout: latestCart,
      previousItems: cart.lines || [],
      context,
      product: itemsToUpdate[0]
    })

    return latestCart
  }
}

export default updateCart
