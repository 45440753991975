import React from 'react'
import FlashBannerIcon from '@components/FlashBanner/shared/FlashBannerIcon'
import FlashBannerMessage from '@components/FlashBanner/shared/FlashBannerMessage'
import FlashBannerDiscount from '@components/FlashBanner/shared/FlashBannerDiscount'
import FlashBannerLink from '@components/FlashBanner/shared/FlashBannerLink'
import FlashBannerItemWrapper from '@components/FlashBanner/shared/FlashBannerItemWrapper'
import FlashBannerMessageWrapper from '@components/FlashBanner/shared/FlashBannerMessageWrapper'
import FlashBannerButton from '../shared/FlashBannerButton'
import { FlashBannerItem } from 'types/contentful/graphQlApi'
import { EmptyObject } from 'types/helpers'

type PromoBannerProps = Omit<
  FlashBannerItem,
  '__typename' | 'bannerName' | 'showBanner'
> & {
  onClick?: () => void
  dismissible?: boolean
  showIconMobile?: boolean
}

const PromoBanner = ({
  messageDesktop,
  messageMobile,
  contentColor = 'white',
  discount,
  discountLabel,
  linkTextDesktop,
  linkUrl,
  iconName,
  iconSvg,
  backgroundColor,
  dismissible = false,
  showIconMobile = false,
  onClick
}: PromoBannerProps | EmptyObject) => {
  return (
    <FlashBannerItemWrapper
      data-testid="PromoBanner"
      link={linkUrl}
      backgroundColor={backgroundColor}
      bannerDismissible={dismissible}
      onClick={onClick}
    >
      <FlashBannerIcon
        contentColor={contentColor}
        iconName={iconName}
        iconSvg={iconSvg}
        showMobile={showIconMobile}
      />
      <FlashBannerMessageWrapper
        center={!linkUrl}
        bannerDismissible={dismissible}
      >
        <FlashBannerMessage
          contentColor={contentColor}
          messageDesktop={messageDesktop}
          messageMobile={messageMobile}
        />
        {!!discount && !!discountLabel && (
          <FlashBannerDiscount
            contentColor={contentColor}
            discountLabel={discountLabel}
            discount={discount}
            center={!!linkUrl}
          />
        )}
      </FlashBannerMessageWrapper>
      {!!linkUrl && !!linkTextDesktop && (
        <FlashBannerLink
          contentColor={contentColor}
          linkUrl={linkUrl}
          linkTextDesktop={linkTextDesktop}
          bannerDismissible={dismissible}
        />
      )}
      {!linkUrl && typeof onClick === 'function' && !!linkTextDesktop && (
        <FlashBannerButton
          contentColor={contentColor}
          linkTextDesktop={linkTextDesktop}
          bannerDismissible={dismissible}
        />
      )}
    </FlashBannerItemWrapper>
  )
}

export default PromoBanner
