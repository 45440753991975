import { MobileGridProps } from 'types/header'
import { ReactComponent as ArrowPreviousIcon } from '@svgs/chevron-slim.svg'
import PrimaryLinksContainer from '@components/Header/HeaderWrapper/desktop/Megamenu/shared/PrimaryLinksContainer'
import SecondaryLinksContainer from '@components/Header/HeaderWrapper/desktop/Megamenu/shared/SecondaryLinksContainer'
import FeaturedImage from '@components/Header/shared/FeaturedImage'
import styles from './styles.module.scss'
import PromoImage from '@components/Header/shared/PromoImage'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

const componentMap = {
  primary: PrimaryLinksContainer,
  secondary: SecondaryLinksContainer
}

export default function PromoMobileGrid({
  grid,
  title,
  currentNav,
  handleGoBack
}: MobileGridProps) {
  const { gridLinks } = grid || []

  const handleClickFeaturedImage = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      columnType: 'Primary Image'
    })
  }

  const handleClickPromoImage = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      columnType: 'Secondary Image'
    })
  }

  return (
    <>
      <button onClick={handleGoBack} className={styles.backButton}>
        <ArrowPreviousIcon width="8.26" height="15.13" aria-label="go back icon" />
      </button>

      <h3 className={styles.title}>{title}</h3>

      <div className={styles.promoImageGrid}>
        {grid.featuredImageVisibility ? (
          <FeaturedImage
            title={grid.featuredImageTitle}
            handle={grid.featuredImageHandle}
            buttonText={grid.featuredImageButtonText}
            badge={grid.featuredImageBadge}
            image={grid.featuredImage}
            height={190}
            isTitleCentered={true}
            onClick={handleClickFeaturedImage}
          />
        ) : null}

        {grid.promoImagePrimaryVisibility ? (
          <PromoImage
            title={grid.promoImagePrimaryTitle}
            handle={grid.promoImagePrimaryHandle}
            image={grid.promoImagePrimary}
            badge={grid.promoImagePrimaryBadge}
            width={160}
            height={190}
            visible={true}
            onClick={handleClickPromoImage}
          />
        ) : null}
      </div>

      <div className={styles.promoLinks}>
        {gridLinks.items.map(gridLink => {
          const Component = componentMap[gridLink.variant]
          return (
            <Component
              key={`${gridLink.title}`}
              items={{ ...gridLink }}
              currentNav={currentNav}
            />
          )
        })}
      </div>
    </>
  )
}
