import { getCookieByName } from './index'
import { getStorageValue } from '@helpers/hooks/useStorage'

export const getUserLoggedInCookie = () => getCookieByName('th_LI')

export const getIsUserLoggedIn = () => !!getUserLoggedInCookie()

export const getUserSavedFitData = () => getCookieByName('saved_fit')

export const getUserEmailData = () => getCookieByName('th_LI_email')

export const getUserFirstNameData = () => getCookieByName('th_LI_first_name')

export const getIsFirstTimeUser = () =>
  !getCookieByName('th_LV') || !!getCookieByName('th_ftv')

export const getUserPseudoTokenCookie = (): string | undefined =>
  getCookieByName('token')

export const getCustomerId = () =>
  getUserLoggedInCookie() || getCookieByName('shopify_user_id') || undefined

export function getUserDataFromAnalytics(): {
  id: string | null
  traits:
    | (Partial<{ email: string; firstName: string }> & Record<string, string>)
    | Record<string, never>
} {
  const userId = getStorageValue('localStorage', 'ajs_user_id', null)
  const userAnonymousId = getStorageValue(
    'localStorage',
    'ajs_anonymous_id',
    null
  )
  const userTraits = getStorageValue('localStorage', 'ajs_user_traits', {})
  const email = getUserEmailData()
  const firstName = getUserFirstNameData()

  return {
    id: userId || userAnonymousId,
    traits: {
      ...(email && { email }),
      ...(firstName && { firstName }),
      ...userTraits
    }
  }
}
