/* eslint-disable camelcase */
import React, { useEffect, useCallback, useRef } from 'react'
import localFont from 'next/font/local'
import { DM_Sans } from 'next/font/google'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { getCookieByName, removeCookie, setCookie } from '@helpers/session'
import { analytics, page } from '@helpers/analytics'
import { parseQuery } from '@helpers/initFit'
import { showBanner } from '@store/redux/actions/otherActions'
import { COOKIE_EXPIRE_14_DAYS, COOKIE_EXPIRE_1_YEAR } from '@consts/index'
import Head from '@components/Head'
import Layout from './Layout'
import useCustomer from '@helpers/hooks/customer/useCustomer'

function useDiscountBanner() {
  const dispatch = useDispatch()

  const handleShowBanner = useCallback(
    (type: 'SORTED' | 'OTHER') => {
      dispatch(
        showBanner({
          isVisible: true,
          type: `Discount Code - ${type}`,
          theme: 'success'
        })
      )
      setTimeout(
        () =>
          dispatch(
            showBanner({
              isVisible: false,
              type: `Discount Code - ${type}`,
              theme: 'success'
            })
          ),
        5000
      )
    },
    [dispatch]
  )

  useEffect(() => {
    const { discount, utm_content, utm_term } = parseQuery(
      window.location.search
    )
    const discountCookie = getCookieByName('discount_code')
    if (
      utm_content === 'CARO_DISCOUNT_100918' &&
      utm_term === 'COLD_SPLIT_DISCOUNT_100918' &&
      discountCookie !== 'SORTED'
    ) {
      setCookie('hideNewsletterPrompt', 'true', COOKIE_EXPIRE_1_YEAR)
      setCookie('discount_code', 'SORTED', COOKIE_EXPIRE_14_DAYS, true)
      analytics('Coupon Entered', {
        label: 'Landing Page',
        coupon_id: 'SORTED'
      })
      handleShowBanner('SORTED')
    }
    if (!!discount && discount !== discountCookie) {
      analytics('Coupon Entered', {
        label: 'Landing Page',
        coupon_id: discount
      })
      handleShowBanner('OTHER')
      setCookie('discount_code', discount, COOKIE_EXPIRE_14_DAYS, true)
    }
  }, [handleShowBanner])
}

function getPageType(statusCode?: number) {
  switch (statusCode) {
    case 404:
      return '404'

    case 200:
    case undefined:
      return 'default'

    default:
      return 'error'
  }
}

const dmsans = DM_Sans({
  subsets: ['latin'],
  variable: '--font-dmsans'
})

const madigan = localFont({
  src: [
    {
      path: '../public/static/fonts/madigan-regular-webfont.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: '../public/static/fonts/madigan-medium-webfont.woff2',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../public/static/fonts/madigan-mediumitalic-webfont.woff2',
      weight: '500',
      style: 'italic'
    }
  ],
  variable: '--font-madigan'
})

type AppProps = {
  noLayout: boolean
  statusCode: number
  includeNoFollow: boolean
}

function App({
  children,
  noLayout,
  includeNoFollow,
  statusCode
}: React.PropsWithChildren<AppProps>) {
  const router = useRouter()
  const currentRoutePath = useRef(router.asPath)
  const { userId } = useCustomer()
  useDiscountBanner()

  useEffect(() => {
    if (userId) {
      removeCookie('th_ftv')
    }
  }, [userId])

  useEffect(() => {
    const checkoutStartedCookieName = `checkoutStarted_${process.env.SHOP_LOCALE}`
    if (!!getCookieByName(checkoutStartedCookieName)) {
      removeCookie(checkoutStartedCookieName)
    }
  }, [])

  useEffect(() => {
    const referrer = window.location.origin + currentRoutePath.current
    page({
      path: router.asPath,
      referrer,
      type: getPageType(statusCode)
    })
    currentRoutePath.current = router.asPath
  }, [router.asPath, statusCode])

  return (
    <main className={`${dmsans.variable} ${madigan.variable}`}>
      <Head includeNoFollow={includeNoFollow} />
      {noLayout ? children : <Layout>{children}</Layout>}
    </main>
  )
}

export default App
