import React from 'react'
import styles from './styles.module.scss'
import LinksColumns from './LinksColumns'
import SocialContent from './SocialContent'
import SecondaryNav from './SecondaryNav'
import NewsLetter from './NewsLetter'

function Footer() {
  return (
    <footer id="footer" className={styles.footer}>
      <div className={styles.footerLinks}>
        <NewsLetter />
        <LinksColumns />
      </div>
      <div className={styles.footerBase}>
        <div className={styles.footerBaseContent}>
          <div className={styles.footerSocialContent}>
            <SocialContent />
          </div>
          <SecondaryNav />
        </div>
      </div>
      <div id="trustbadge" className={styles.trustbadge} />
    </footer>
  )
}

export default Footer
