import ButtonMinimal from '@components/ui/Button/ButtonMinimal'
import styles from './styles.module.scss'
import Badge, { BadgeProps } from '@components/ui/Badge'
import { HeaderImage } from 'types/header'

type FeaturedImageProps = {
  title: string
  handle: string
  buttonText: string
  image: HeaderImage
  width?: number
  height?: number
  badge?: BadgeProps | null
  isTitleCentered?: boolean
  onClick: (title: string) => void
}

export default function FeaturedImage({
  title,
  handle,
  buttonText,
  image,
  width,
  height,
  badge,
  isTitleCentered,
  onClick
}: FeaturedImageProps) {
  return (
    <>
      <div
        className={styles.featuredImage}
        style={
          {
            '--width': `${width}px`,
            '--height': `${height}px`
          } as React.CSSProperties
        }
      >
        <a
          href={handle}
          aria-label={`Shop ${title}`}
          onClick={() => onClick(title)}
        >
          <div className={styles.featuredImage__image}>
            <img src={image.url} alt={image.title} />
          </div>

          <h3 className={styles.featuredImage__Title}>{title}</h3>

          {badge && (
            <Badge
              classes={styles.featuredImage__badge}
              title={badge.title}
              {...badge}
            />
          )}

          <ButtonMinimal
            classes={styles.featuredImage__imageButton}
            variant="light"
            underlined={true}
          >
            Shop now
          </ButtonMinimal>
          <h3
            className={`${styles.mobileTitle} ${
              isTitleCentered ? styles.mobileTitle__textAlignCenter : ''
            }`}
          >
            {buttonText}
          </h3>
        </a>
      </div>
    </>
  )
}
