const euCommon = {
  common: {
    sweetenersList: {
      'option-1': 'Free shipping on orders under',
      'option-2': 'Extended 200 day Returns',
      'option-3': 'No EU custom or duty fees'
    },
    cart: {
      multibuy: {
        't-shirts': {
          label: '3 for €150',
          discount: 'Save €15',
          eligiblePrice: '55.00'
        }
      }
    }
  }
}

module.exports = euCommon
