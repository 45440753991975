import React from 'react'
import styles from './styles.module.scss'

type LoaderProps = {
  fullHeight?: boolean
}

export const Loader = ({ fullHeight = false, ...props }: LoaderProps) => (
  <div
    className={`${styles.loader} ${fullHeight ? styles.fullHeight : ''}`}
    {...props}
  >
    <span className={styles.loader__loading}>
      <span className={styles.loader__dot} />
      <span className={styles.loader__dot} />
      <span className={styles.loader__dot} />
      <span className={styles.loader__dot} />
      <span className={styles.loader__dot} />
    </span>
    <span className={styles.loader__tick} />
  </div>
)
