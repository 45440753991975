import axios from 'axios'
import { ShopifyCheckoutError } from './types'

type ReturnType<T> = {
  data: T
  errors?: ShopifyCheckoutError[]
}

export default async function makeRequest<T>(
  query: string,
  variables: Record<string, unknown> = {}
): Promise<ReturnType<T>> {
  const { data } = await axios({
    method: 'post',
    url: `https://${process.env.SHOP_LOCALE.toLowerCase()}.${
      process.env.SHOP_DOMAIN
    }/api/2024-04/graphql.json`,
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.STOREFRONT_API_TOKEN,
      Accept: 'application/json'
    },
    data: {
      query,
      variables
    }
  })
  return data
}