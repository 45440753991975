import classNames from 'classnames'
import ButtonBase, { ButtonBaseProps } from './ButtonBase'
import styles from './button.module.scss'

type ButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'white'
    | 'white_outline'
    | 'delete'
    | 'default'
    | 'link'
  classes?: string
  selected?: boolean
} & ButtonBaseProps

function Button({
  variant = 'default',
  classes = '',
  selected = false,
  ...props
}: ButtonProps) {
  const buttonClasses = classNames(styles[variant] || '', classes, {
    [styles.selected]: selected
  })
  return <ButtonBase {...props} classes={buttonClasses} />
}

export default Button
