import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import {
  getUserPseudoToken,
  identifyUser as identifyUserService
} from '@api/fit'
import { getCustomerFit } from '@api/fit'
import { customerSetFitAction } from '@store/redux/actions/customerFitActions'
import {
  selectDynamicFilter,
  resetDynamicFilters
} from '@store/redux/actions/filterActions'
import trackError from '@helpers/trackError'
import { getUserPseudoTokenCookie } from '@helpers/session/user'
import { COOKIE_EXPIRE_1_YEAR, COOKIE_EXPIRE_3_MONTHS } from '@consts/index'
import { setCookie } from '@helpers/session'
import { identify, analytics } from '@helpers/analytics'
import { getCustomerSavedFitFromCookies } from '@helpers/customer/fit'
import { getCustomerId } from '@helpers/session/user'
import { Fit } from 'types/fit'
import { getFiltersOptionsState } from '@helpers/filters'

function getFitDataStatus(
  fitFromDatabase: Fit | undefined,
  savedFitFromCookies: Fit | null
) {
  if (!savedFitFromCookies) {
    return 'empty'
  }

  const fitSourcesMatchContents =
    Object.entries(savedFitFromCookies).sort().toString() ===
    Object.entries(fitFromDatabase || {})
      .sort()
      .toString()

  return fitSourcesMatchContents ? 'match' : 'mismatch'
}

async function identifyUser(userId: string, pseudoToken: string) {
  try {
    await identifyUserService({
      token: pseudoToken,
      customer_id: userId
    })
  } catch (error) {
    trackError(new Error('User could not be identified'), {
      error: error as Error,
      section: 'IdentifyUser'
    })
  }
}

const getCustomerToken = async () => {
  const userCookiePseudoToken = getUserPseudoTokenCookie()
  const pseudoToken = !userCookiePseudoToken
    ? await getUserPseudoToken()
    : undefined

  if (pseudoToken) {
    setCookie('token', pseudoToken, COOKIE_EXPIRE_1_YEAR)
  }

  return userCookiePseudoToken || pseudoToken
}

const fetchCustomerFit = async (
  userId: string,
  savedFitFromCookies: Fit | null
): Promise<{
  customerFit?: Fit
  email?: string
  updatedAt?: string
}> => {
  const {
    fit,
    email,
    updated_at: updatedAt
  } = await getCustomerFit({ customerId: userId })
  const customerFit =
    fit.top && fit.bottom ? { ...fit.top, ...fit.bottom } : undefined

  analytics('User Fit Data Fetched', {
    fit_cookies: getFitDataStatus(customerFit, savedFitFromCookies)
  })

  return { customerFit, email, updatedAt }
}

type RouterQuery = {
  userId?: string
}
export default function useCustomer(): { userId: string | undefined } {
  const router = useRouter()
  const dispatch = useDispatch()
  const { userId: userIdQuery } = (router.query as RouterQuery) || {}
  const userId = userIdQuery || getCustomerId()

  useEffect(() => {
    const initCustomer = async () => {
      const pseudoToken = await getCustomerToken()
      const savedFitFromCookies = getCustomerSavedFitFromCookies()

      if (userId && pseudoToken) {
        await identifyUser(userId, pseudoToken)
      }

      if (userIdQuery) {
        setCookie('shopify_user_id', `${userId}`, 0)
        const { customerFit, email, updatedAt } = await fetchCustomerFit(
          userIdQuery,
          savedFitFromCookies
        )

        if (!customerFit) {
          return false
        }

        dispatch(
          customerSetFitAction({
            fit: customerFit,
            updatedAt
          })
        )

        identify(userId, { ...customerFit, email })
        setCookie(
          'saved_fit',
          JSON.stringify(customerFit),
          COOKIE_EXPIRE_1_YEAR,
          true
        )

        const pushedFilterOptions = getFiltersOptionsState(customerFit)
        setCookie(
          'filters',
          JSON.stringify(customerFit),
          COOKIE_EXPIRE_3_MONTHS
        )
        dispatch(resetDynamicFilters())
        dispatch(selectDynamicFilter(pushedFilterOptions))
      } else if (savedFitFromCookies) {
        dispatch(
          customerSetFitAction({
            fit: savedFitFromCookies
          })
        )
      }
    }
    initCustomer()
  }, [userId, userIdQuery, dispatch])

  return { userId }
}
