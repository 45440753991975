import trackError from '@helpers/trackError'
import { getCookieByName, setCookie, removeCookie } from '@helpers/session'
import { parse } from '@helpers/shopify'
import { COOKIE_EXPIRE_14_DAYS } from '@consts/index'
import {
  cartCreateMutation,
  cartLinesUpdateMutation,
  cartLinesAddMutation,
  cartAttributesUpdateMutation
} from './mutations'
import { readCartQuery, readCustomerQuery } from './queries'
import makeRequest from './makeRequest'
import {
  ShopifyCheckoutError,
  ShopifyCustomerQuery,
  ShopifyCartCreate,
  ShopifyCartLinesAdd,
  ShopifyCartError,
  ShopifyCartQuery,
  ShopifyCartLinesUpdate,
  ShopifyCartAttributesUpdate
} from './types'
import {
  Cart,
  CartCreateInput,
  CartLinesInput,
  CartLinesUpdateInput,
  CustomAttribute
} from 'types/checkout'
import { Customer } from 'types/customer'

function addCartCookie(cartId: string) {
  setCookie(`cart_${process.env.SHOP_LOCALE}`, cartId, COOKIE_EXPIRE_14_DAYS)
  removeCookie('th_ftv')
}

function removeCartCookie() {
  removeCookie(`cart_${process.env.SHOP_LOCALE}`)
}

function getErrorMessage(error: ShopifyCheckoutError[] | Error) {
  return Array.isArray(error) ? error[0]?.message : error.message
}

export async function readCart(cartId: string) {
  try {
    const { data, errors } = await makeRequest<ShopifyCartQuery>(
      readCartQuery,
      {
        cartId
      }
    )

    if (errors?.length) {
      throw errors
    }

    const cart = data?.cart

    if (!cart || Object.keys(cart).length === 0) {
      removeCartCookie()
      throw new Error('This cart was previously completed')
    }

    const parsedCart = parse(cart) as Cart

    if (!parsedCart.lines) {
      throw new Error('lines missing from cart')
    }

    return parsedCart
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`readCart failed - ${errorMessage}`), {
      cartId,
      error
    })
    throw error
  }
}

export async function cartUpdate(lines: CartLinesUpdateInput[] = []) {
  const cartId = getCookieByName(`cart_${process.env.SHOP_LOCALE}`)

  try {
    if (!cartId) {
      throw Error('No cart id')
    }

    const { data } = await makeRequest<ShopifyCartLinesUpdate>(
      cartLinesUpdateMutation,
      {
        lines,
        cartId
      }
    )

    const { cart, userErrors } = parse(data.cartLinesUpdate) as {
      cart: Cart
      userErrors: ShopifyCartError[]
    }

    if (userErrors?.length) {
      throw userErrors
    }

    addCartCookie(cart.id)

    return cart
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`cartUpdate failed - ${errorMessage}`), {
      cartId,
      lines: JSON.stringify(lines),
      error
    })
    throw error
  }
}

export async function cartAttributesUpdate(
  attributes: CustomAttribute | CustomAttribute[]
) {
  const cartId = getCookieByName(`cart_${process.env.SHOP_LOCALE}`)

  try {
    if (!cartId) {
      throw Error('No cart id')
    }

    const { data, errors } = await makeRequest<ShopifyCartAttributesUpdate>(
      cartAttributesUpdateMutation,
      {
        cartId,
        attributes
      }
    )

    if (errors?.length) {
      throw errors
    }

    const { cart, userErrors } = parse(data.cartAttributesUpdate) as {
      cart: Cart
      userErrors: ShopifyCartError[]
    }

    if (userErrors?.length) {
      throw userErrors
    }

    addCartCookie(cart.id)

    return cart
  } catch (e) {
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`cartAttributesUpdate failed - ${errorMessage}`), {
      cartId,
      attributes: JSON.stringify(attributes),
      error
    })
    throw error
  }
}

export async function createCart(lines: CartCreateInput[]) {
  try {
    const { data } = await makeRequest<ShopifyCartCreate>(cartCreateMutation, {
      cartInput: {
        lines
      }
    })
    const { cart, userErrors } = parse(data.cartCreate) as {
      cart: Cart
      userErrors: ShopifyCartError[]
    }

    if (userErrors?.length) {
      throw userErrors
    }

    addCartCookie(cart.id)

    return cart
  } catch (e) {
    console.error('ERROR CREATE CART: ', e)
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`createCart failed - ${errorMessage}`), {
      lines: JSON.stringify(lines),
      error
    })
    throw error
  }
}

export async function readCustomer(customerAccessToken: string) {
  try {
    const { data, errors } = await makeRequest<ShopifyCustomerQuery>(
      readCustomerQuery,
      {
        customerAccessToken
      }
    )

    if (errors) throw errors

    return parse(data) as { customer: Customer }
  } catch (e) {
    const error = e as Error
    trackError(new Error('Error at reading the customer'), {
      error,
      customerAccessToken
    })
    return { customer: undefined }
  }
}

export async function cartLinesAdd(lines: CartLinesInput[]) {
  const cartId = getCookieByName(`cart_${process.env.SHOP_LOCALE}`)

  if (!cartId) {
    return createCart(lines)
  }

  try {
    const { data } = await makeRequest<ShopifyCartLinesAdd>(
      cartLinesAddMutation,
      {
        lines,
        cartId
      }
    )

    const { cart, userErrors } = parse(data.cartLinesAdd) as {
      cart: Cart
      userErrors: ShopifyCartError[]
    }

    if (userErrors?.length) {
      throw userErrors
    }

    addCartCookie(cart.id)

    return cart
  } catch (e) {
    console.error('ERROR ADDING CART LINE: ', e)
    const error = e as Error
    const errorMessage = getErrorMessage(error)
    trackError(new Error(`cartLinesAdd failed - ${errorMessage}`), {
      cartId,
      lines: JSON.stringify(lines),
      error
    })
    throw error
  }
}
