import React, { Children, useState } from 'react'
import AccordionItem from './AccordionItem'

function getDefaultOpenSections(defaultOpen: number[] = []) {
  const sections: { [index: number]: boolean } = {}

  defaultOpen.forEach(index => {
    sections[index] = true
  })

  return sections
}

type AccordionChild = ReturnType<typeof AccordionItem> | null

type AccordionProps = {
  id: string
  children: null | AccordionChild | (AccordionChild | null)[]
  classes?: string
  allowMultipleOpen?: boolean
  defaultOpen?: number[]
  isDisabled?: boolean
}

const Accordion = ({
  id,
  allowMultipleOpen,
  children,
  defaultOpen,
  isDisabled,
  classes
}: AccordionProps) => {
  const [openSections, setOpenSections] = useState(
    defaultOpen ? getDefaultOpenSections(defaultOpen) : {}
  )

  const handleOnClick = (index: number) => {
    setOpenSections({
      ...(allowMultipleOpen && openSections),
      [index]: !openSections[index]
    })
  }

  return (
    <section className={`${classes || ''}`}>
      {Children.toArray(children).map((child, index) => {
        if (React.isValidElement(child)) {
          return (
            <AccordionItem
              {...child.props}
              isOpen={openSections[index] || false}
              onClick={() => handleOnClick(index)}
              key={index.toString()}
              isDisabled={isDisabled}
              id={id}
            />
          )
        }
        return child
      })}
    </section>
  )
}

export default Accordion
