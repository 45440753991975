const gbProduct = {
  product: {
    accordion: {
      care: 'Care',
      'size-not-in-stock': 'Size not in stock?',
      'size-and-fit': 'Size & Fit',
      'product-details': 'Product details',
      'returns-and-exchanges': 'Returns & Exchanges',
      highlights: 'Highlights',
      'highlights-content': {
        'item-1': 'Our signature chino',
        'item-2': 'A perfect blend of smart and casual',
        'item-3': 'Cut in a peachy 7oz cotton with a hint of stretch'
      }
    },
    'gift-card': {
      'free-returns': 'Free shipping & returns',
      title: 'The gift that keeps on fitting',
      byline: 'Get it right this year',
      'paragraph-1':
        'Extend the promise of a perfect fit to family and friends - even if their size is a mystery - with a SPOKE gift card.',
      'paragraph-2':
        'You can choose from four different outer box options for the gift card pack  - as well as a range of values to match the price of our trousers.',
      'paragraph-3':
        'Each voucher is valid for 6 months from the date of issue - so he has time to get his act together - and the clock starts on our 100 day money back guarantee, only when his chosen pair ships.',
      'paragraph-4':
        "Once you've done the hard work, he can do the leg work, and choose from our range of colours and sizes for himself.",
      helper:
        'This will be sent you via email. You can then send it to your friend to use',
      'voucher-type': 'Voucher Type',
      amount: 'Amount',
      physical: 'Physical'
    },
    'delivery-estimate': {
      'free-delivery-by': 'Free Delivery by',
      'order-now': 'Order now and get it by'
    },
    'care-instructions': {
      belts: 'To clean please wipe down with a damp cloth.',
      denim: {
        'line-1':
          'The way we dye our denim means that these jeans will fade slowly with wearing and washing.',
        'line-2':
          'But like most good things in life, that carefree signature fadecomes at a (small) cost.',
        'line-3':
          "When they're fresh out of the box, darker colours can transfer with contact. So take a little extra care in the early days, especially when pairing with light coloured garments. Leave as long between washes as you can.",
        'line-4':
          'When you do, wash only with dark colours inside out, at no more than',
        'line-5':
          'degrees. And if you must tumble dry, keep to the lowest heat, not hot.'
      },
      smarts: {
        'line-1':
          'After a few wears you’ll need leave these Smarts in the tender loving care of your local dry cleaners. They should never see the inside of a domestic wash.',
        'line-2':
          'A trouser press will keep them fresh between cleans - but please use a warm setting at most. Nothing too hot.',
        'line-3':
          'And if you iron at home, avoid excessive heat and steam wherever possible.'
      },
      'winter-fridays': {
        'line-1':
          'After a few wears you’ll need to leave these Winter Fridays in the tender loving care of your local dry cleaners. They should never see the inside of a domestic wash.',
        'line-2':
          'A trouser press will keep them fresh between cleans - but please use a warm setting at most. Nothing too hot.And if you iron at home, avoid excessive heat and steam wherever possible.'
      },
      tshirts: {
        'line-1':
          "Please wash at 30 degrees and iron on a medium heat at most. Don't tumble dry, if you can avoid it - it never helps. Please don't bleach - even if it's white."
      },
      oxfords: {
        'line-1':
          'Machine wash at 30 degrees, wash with similar colours, no bleach, no tumble dry, warm iron.'
      },
      masks: {
        'line-1': 'Our mask can we be washed at',
        'line-2': 'degrees or less, and tumble dried.'
      },
      polo: {
        'line-1': 'Wash at',
        'line-2':
          'degrees, cool iron, no tumble dry - and no bleaching. Thanks!'
      },
      merino: {
        'line-1':
          'Wash on a gentle wool cycle. Do not tumble dry or bleach. Lay flat to dry.'
      },
      socks: {
        'line-1': 'Wash inside out.'
      },
      default: {
        'line-1': 'Please wash at',
        'line-2': 'degrees, mid iron, no tumble dry or bleach. Wash inside out.'
      }
    },
    filters: {
      'add-to-bag': 'Add to basket',
      'whats-my-fit': "What's my fit?",
      'find-my-fit': 'Find my Fit',
      'my-fit': 'My Fit',
      'size-chart': 'Size Chart',
      colour: 'Colour',
      'build-a-desc': 'Narrow thighs',
      'build-b-desc': 'Regular thighs',
      'build-c-desc': 'Wide thighs',
      'compare-lengths': 'Compare Lengths',
      'compare-cuts': 'Compare Cuts',
      'check-our': 'Check our',
      'click-here': 'click here',
      new: 'New',
      'short-7': 'For men of 5’7” or less. And 80s nostalgists.',
      'short-9': 'For men of 5’7” to 6’1”, with an option to roll the hem.',
      'short-11':
        'For men of 6’1” and above. And for those who like to roll the hem.',
      long: 'Long',
      regular: 'Regular',
      notes: {
        sweatshirts: {
          'sweatshirts-straight':
            'The bottom elasticated waistband is removed in our straight cut for a more relaxed fit. To see the differences',
          'slim-waistband':
            'Elasticated waistband for a slimmer fit around the body.',
          'straight-waistband': 'Side vents at the hip for a more relaxed fit.'
        },
        'fall-between-sizes': 'For those who often fall between a',
        'in-between-sizes':
          'Normally an in between waist size? Take a size down, the elasticated waist band will do the rest.',
        'lower-leg':
          'For those with longer legs, we recommend choosing standard lower leg as our trousers taper naturally for the best fit.'
      }
    },
    oos: {
      title: 'Please let us know if your preferred size is not available.',
      button: 'Submit your request',
      'notify-oos': 'Notify me when in stock'
    },
    'oos-modal': {
      heading: 'Your size not available?',
      byline: 'Please let us know if your preferred size is not available.',
      error: 'Oops! Something went wrong! Try again later',
      'gdpr-1':
        'We use your email to update you with orders, offers & promotions. Tick here to ',
      'gdpr-2': 'not receive',
      'gdpr-3': ' these emails.',
      form: {
        'email-placeholder': 'Enter email address',
        button: 'Send',
        'close-button': 'Close'
      },
      success:
        'Thank you! We will be in touch when this product becomes available'
    },
    personalisation: {
      beta: 'BETA',
      new: 'New',
      cost: '£10.00',
      delivery: 'Please allow 5 days for delivery.',
      edit: 'Edit',
      example: 'Example',
      'no-returns':
        'I acknowledge that personalised items cannot be exchanged or returned.',
      personalisation: 'Personalisation',
      remove: 'Remove',
      save: 'Save',
      cancel: 'Cancel',
      'total-cost': 'Total Cost'
    },
    'returns-and-exchanges-instructions': {
      lines:
        'Standard delivery in 3-5 working days | Free delivery on orders over £100 | Express delivery available | Best-in-class 200-day returns policy | Free returns and optional home pick-up | Responsive and human customer care'
    },
    ymal: 'You may also like',
    'not-for-you-1': 'If',
    'not-for-you-2': "aren't for you today",
    reviews: {
      title: 'Customer Reviews',
      byline: 'How we measure up'
    }
  }
}

module.exports = gbProduct
