import React, { AnchorHTMLAttributes } from 'react'
import classNames from 'classnames'
import { getLink } from '@helpers/env'
import styles from './styles.module.scss'

type LinkProps = {
  href: string
  classes?: string
  text?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  target?: string
  rawHref?: boolean
} & AnchorHTMLAttributes<HTMLAnchorElement>

const Link = ({
  href,
  classes,
  text,
  onClick,
  children,
  target,
  rawHref,
  ...props
}: React.PropsWithChildren<LinkProps>) => {
  return (
    <a
      {...props}
      {...(target && { target })}
      {...(onClick && { onClick })}
      href={rawHref ? href : getLink(href)}
      className={classNames(styles.link, classes)}
    >
      {children || text}
    </a>
  )
}

export default Link
