import { useState, useEffect } from 'react'
import trackError from '@helpers/trackError'

type ABTests = {
  tests: string[]
  testsVariants: Record<string, string>
  testsLoaded: boolean
}

function useABtests() {
  const [abTests, setABTests] = useState<ABTests>({
    tests: [],
    testsVariants: {},
    testsLoaded: false
  })

  useEffect(() => {
    const awaitPostHog = async () => {
      await window.postHogAsyncLoader
      window.posthog?.onFeatureFlags((flags, flagVariants) => {
        const tests = {
          tests: flags,
          testsVariants: flagVariants,
          testsLoaded: true
        }
        setABTests(tests)
        console.info('AB tests:', tests)
      })
    }
    try {
      awaitPostHog()
    } catch (error) {
      trackError(new Error('Failed to get PostHog feature flags'))
    }
  }, [])

  return abTests
}

export default useABtests
