import Drawer from '@components/ui/Drawer'
import { ReactComponent as CloseIcon } from '@svgs/close-slim.svg'
import styles from './styles.module.scss'
import MobileMenuWrapper from './MobileMenuWrapper/index'

type MobileMenuDrawerOverlayProps = {
  isMobileMenuOpen: boolean
  handleCloseMobileMenu: () => void
}

export default function MobileMenuDrawerOverlay({
  isMobileMenuOpen,
  handleCloseMobileMenu
}: MobileMenuDrawerOverlayProps) {
  return (
    <Drawer
      size="medium"
      isDrawerOpen={isMobileMenuOpen}
      onCloseDrawer={handleCloseMobileMenu}
    >
      <button
        onClick={handleCloseMobileMenu}
        className={styles.MobileDrawer__closeButton}
        type="button"
        aria-label="Close cart"
      >
        <CloseIcon fill="black" />
      </button>
      <MobileMenuWrapper />
    </Drawer>
  )
}
