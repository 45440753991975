import ErrorBoundary from '@components/ErrorBoundary'
import logBuildError from '@helpers/misc/logBuildError'
import DesktopHeader from './desktop'
import { jsonToArray } from '@helpers/contentful'
import MobileHeader from './mobile'
import { HeaderProps } from 'types/header'

function handleMenuError() {
  logBuildError('Header')({
    errorLevel: 'critical',
    properties: {
      message: 'Header menu failed'
    }
  })
  if (process.env.NODE_ENV === 'production') {
    process?.exit(1) // process.exit fails export if menu data isn't available
  }
}

type MainHeaderProps = {
  header: HeaderProps
  isCartOpen: boolean
  onCartOpen: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isFitFinderOpen: boolean
}

export const HeaderWrapper = ({
  header,
  isCartOpen,
  onCartOpen,
  isFitFinderOpen
}: MainHeaderProps) => {
  const headerItems = jsonToArray(header.items || [])

  if (!headerItems.length) {
    console.error('Header data is missing', { header })
    handleMenuError()
    return null
  }

  return (
    <>
      <ErrorBoundary>
        <DesktopHeader
          data={headerItems}
          onCartIsOpen={isCartOpen}
          onCartOpen={onCartOpen}
        />
        <MobileHeader
          onCartOpen={onCartOpen}
          isFitFinderOpen={isFitFinderOpen}
        />
      </ErrorBoundary>
    </>
  )
}
