/* eslint-disable import/prefer-default-export */
import { cartUpdate } from '@api/graphqlShopify'
import { arrayPartition } from '@helpers/utils'
import { getCartUpdatePayload } from '@components/cart/helpers'
import { CartLineItem } from 'types/checkout'

export async function purgeNullVariantsFromCart(lines: CartLineItem[]) {
  const invalidLineItems = arrayPartition(lines, lineItem => {
    return !!lineItem.merchandise
  })[1]

  if (invalidLineItems.length > 0) {
    const removeLineItems = invalidLineItems.map(lineItem => {
      const { payload } = getCartUpdatePayload({
        id: lineItem.id,
        quantity: 0,
        lines
      })
      return cartUpdate(payload)
    })

    const updateResponse = await Promise.all(removeLineItems)
    const lastResponse = updateResponse[updateResponse.length - 1]
    return lastResponse
  }

  return null
}
