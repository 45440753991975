import {
  NotificationBannerAction,
  FilterByCategoryAction,
  CartOpenAction,
  SearchDrawerAction
} from '../reducers/otherReducers'

export const openCart = (): CartOpenAction => ({
  type: 'OPEN_CART'
})

export const closeCart = (): CartOpenAction => ({
  type: 'CLOSE_CART'
})

export const openSearch = (): SearchDrawerAction => ({
  type: 'OPEN_SEARCH'
})

export const closeSearch = (): SearchDrawerAction => ({
  type: 'CLOSE_SEARCH'
})

export const showBanner = (
  payload: NotificationBannerAction['payload']
): NotificationBannerAction => ({
  type: 'HIDE_SHOW_NOTIFICATION_BANNER',
  payload
})

export const categoryFilter = (
  payload: FilterByCategoryAction['payload']
): FilterByCategoryAction => ({
  type: 'CATEGORY_FILTER',
  payload
})
