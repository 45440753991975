import styles from './styles.module.scss'

type NewsletterModalContentProps = {
  image: string
}

const NewsletterModalContent = ({
  image,
  children
}: React.PropsWithChildren<NewsletterModalContentProps>) => {
  return (
    <div className={styles.NewsletterModalContent__content}>
      <div
        className={`${styles.NewsletterModalContent__imageWrapper} ${
          image ? '' : styles.placeholder
        }`}
      >
        <img
          width={450}
          height={500}
          className={styles.NewsletterModalContent__image}
          src={image}
          alt="newsletter modal"
        />
      </div>
      <div className={styles.NewsletterModalContent__step}>{children}</div>
    </div>
  )
}

export default NewsletterModalContent
