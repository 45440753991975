import axios from 'axios'
import { getShop } from '@helpers/env'
import { FitFinderAnswers, FitFinderResults } from 'types/fit'
import trackError from '@helpers/trackError'
import { EmptyObject } from 'types/helpers'

const DEFAULT_HEADERS = {
  'x-api-key': process.env.FIT_API_KEY
}
const SHOP = getShop()
const FIT_API_URL = `${process.env.FIT_API_DOMAIN}/${process.env.FIT_API_VERSION}`

export type Fit = {
  top: {
    cut: string
    size: string
    length: string
  }
  bottom: {
    build: string
    leg: string
    waist: string
  }
}

type GetCustomerFitReturnSuccess = {
  updated_at: string
  email: string
  fit: Fit
  status: undefined
}

type GetCustomerFitReturnFail = {
  updated_at: undefined
  email: undefined
  fit: EmptyObject
  status: number
}
export function getCustomerFit({
  email,
  customerId
}: {
  customerId?: string
  email?: string
}): Promise<GetCustomerFitReturnSuccess | GetCustomerFitReturnFail> {
  return axios
    .get(`${FIT_API_URL}/fit/${customerId || email}`, {
      headers: DEFAULT_HEADERS
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      if ((error as Partial<{ status: number }>)?.status !== 404) {
        trackError(new Error('Failed to fetch customer fit'), {
          section: 'Saved Fit',
          error: error as Error
        })
      }
      return {
        fit: {},
        updated_at: undefined,
        email: undefined,
        status: error.status
      }
    })
}

type SaveFitArgs = {
  token: string
  customerId?: string | undefined
  email?: string | undefined
  fitFinderAnswers: FitFinderAnswers
  fit: Fit
  method: string
  storeLocation: string
}
type SaveFitResponse = {
  message: string
}
export function saveFit({
  token,
  customerId,
  email,
  fitFinderAnswers,
  fit,
  method,
  storeLocation
}: SaveFitArgs): Promise<SaveFitResponse> {
  return axios
    .post(
      `${FIT_API_URL}/fit`,
      {
        shop: SHOP,
        token,
        customer_id: customerId,
        email,
        fit_finder_answers: fitFinderAnswers,
        fit,
        method,
        store_location: storeLocation
      },
      {
        headers: DEFAULT_HEADERS
      }
    )
    .then(({ data }) => data)
}

export function fitFinder(body: FitFinderAnswers): Promise<FitFinderResults> {
  return axios
    .post(`${FIT_API_URL}/fit-finder`, body, {
      headers: DEFAULT_HEADERS
    })
    .then(({ data }) => data)
}

type SubmitFitFinderArgs = Omit<SaveFitArgs, 'fit'>
export async function submitFitFinder<T extends SubmitFitFinderArgs>({
  token,
  customerId,
  email,
  fitFinderAnswers,
  method,
  storeLocation
}: T): Promise<FitFinderResults> {
  const fitFinderResults = await fitFinder(fitFinderAnswers)
  const { top, bottom } = fitFinderResults
  await saveFit({
    fit: { top, bottom },
    token,
    customerId,
    email,
    fitFinderAnswers,
    method,
    storeLocation
  })

  return fitFinderResults
}

export function getUserPseudoToken(): Promise<string | undefined> {
  return axios
    .get(`${FIT_API_URL}/identify`, {
      headers: DEFAULT_HEADERS
    })
    .then(({ data }) => data?.token)
    .catch(error => {
      trackError(new Error('User token failed'), {
        error: error.message
      })
      return undefined
    })
}

export function identifyUser(body: { token: string; customer_id: string }) {
  return axios.post(`${FIT_API_URL}/identify`, body, {
    headers: DEFAULT_HEADERS
  })
}

export type UpdateFitBody = {
  customerId?: string | undefined
  email?: string | undefined
  fit: Fit
  method: string
  token: string
  storeLocation: string
}
type UpdateFitResponse = {
  message: string
}
export function updateFit({
  customerId,
  email,
  fit,
  method,
  token,
  storeLocation
}: UpdateFitBody): Promise<UpdateFitResponse> {
  return axios.put(
    `${FIT_API_URL}/fit`,
    {
      customer_id: customerId,
      email,
      fit,
      method,
      token,
      store_location: storeLocation,
      shop: SHOP
    },
    {
      headers: DEFAULT_HEADERS
    }
  )
}
