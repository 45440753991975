import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type FlashBannerDiscountProps = {
  discountLabel: string
  discount: string
  contentColor: string | null
  center: boolean
}

function FlashBannerDiscount({
  discountLabel,
  discount,
  contentColor,
  center
}: Partial<FlashBannerDiscountProps>) {
  const classes = classNames(styles.flashDiscount, {
    [styles.flashDiscount_center]: !center
  })
  return !!discountLabel || !!discount ? (
    <div className={classes} style={{ color: contentColor || undefined }}>
      <p className={styles.flashDiscount__label}>{discountLabel}</p>
      <p className={styles.flashDiscount__code}>{discount}</p>
    </div>
  ) : null
}

export default FlashBannerDiscount
