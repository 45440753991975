/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
import { AnchorHTMLAttributes } from 'react'
import classNames from 'classnames'
import { getLink } from '@helpers/env'
import styles from './buttonBase.module.scss'
import buttonStyles from './button.module.scss'
import { ButtonBase } from './ButtonBase'

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>

export type ButtonLinkProps = {
  href: string
  rawHref?: boolean
  variant?: 'primary' | 'secondary' | 'white' | 'white_outline' | 'default'
} & AnchorProps &
  ButtonBase

const ButtonLink = ({
  variant = 'default',
  classes,
  children,
  size = 'large',
  textSize,
  fullWidth,
  href,
  rawHref,
  ...props
}: ButtonLinkProps) => {
  const buttonClasses = classNames(
    styles.button,
    styles[size],
    styles[`textSize_${textSize || size}`],
    buttonStyles[variant],
    classes,
    {
      [styles.alwaysFullWidth]: fullWidth === 'always',
      [styles.neverFullWidth]: fullWidth === 'never',
      [styles.inherit]: fullWidth === 'inherit'
    }
  )

  return (
    <a
      {...props}
      className={buttonClasses}
      href={rawHref ? href : getLink(href)}
    >
      {children}
    </a>
  )
}

export default ButtonLink
