import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Cart, CartLinesInput } from 'types/checkout'
import { cartLinesAdd, readCart } from '@api/graphqlShopify'
import { basketEvents } from '@helpers/cart'
import { CartState } from '../reducers/cartReducer'
import { purgeNullVariantsFromCart } from '../cartHelpers'
import { updateCart, toggleCartNotification } from './cartActions'

type AddToCart = (
  itemsToAdd: CartLinesInput[],
  context: string
) => ThunkAction<Promise<Cart>, { cart: CartState }, unknown, Action>

const addToCart: AddToCart =
  (itemsToAdd, context) => async (dispatch, getState) => {
    const { cart } = getState()

    let newCart = await cartLinesAdd(itemsToAdd as CartLinesInput[])

    const purgedCheckout = await purgeNullVariantsFromCart(newCart?.lines)

    if (purgedCheckout) {
      dispatch(toggleCartNotification(true))
      newCart = await cartLinesAdd(itemsToAdd)
    }

    dispatch(updateCart(newCart))

    basketEvents({
      checkout: newCart,
      previousItems: cart.lines || [],
      context,
      product: itemsToAdd[0]
    })

    return newCart
  }

export default addToCart
