import AppContext from '@components/AppContext'
import { FLASH_BANNER_HEIGHT } from '@consts/index'
import useScrollPosition from '@helpers/hooks/useScrollPosition'
import { useContext, useEffect, useState } from 'react'

const COLORS = {
  dark: '#001e3c',
  light: '#ffffff',
  transparent: 'transparent'
}

function gethemeColor(
  enabled: boolean,
  isInScrollPositionRange: boolean,
  theme: string
) {
  if (theme === 'transparent-light' && isInScrollPositionRange) {
    return enabled ? COLORS.light : COLORS.dark
  }

  if (!isInScrollPositionRange) {
    return COLORS.dark
  }

  return COLORS.dark
}

function gethemeBackgroundColor(
  enabled: boolean,
  isInScrollPositionRange: boolean
) {
  return enabled && isInScrollPositionRange ? COLORS.transparent : COLORS.light
}

export default function useHeaderTheme() {
  const scrollPosition = useScrollPosition()
  const { headerTheme } = useContext(AppContext)
  const [transparentMode, setTransparentMode] = useState<boolean>(false)
  const headerTransparencyEnabled =
    transparentMode &&
    (headerTheme === 'transparent-light' || headerTheme === 'transparent-dark')

  const isInScrollPositionRange = scrollPosition < FLASH_BANNER_HEIGHT

  useEffect(() => {
    setTransparentMode(
      headerTheme === 'transparent-light' || headerTheme === 'transparent-dark'
    )
  }, [headerTheme])

  useEffect(() => {
    const themeColor = gethemeColor(
      headerTransparencyEnabled,
      isInScrollPositionRange,
      headerTheme
    )
    const themeBackgroundColor = gethemeBackgroundColor(
      headerTransparencyEnabled,
      isInScrollPositionRange
    )
    document.body.style.setProperty(
      '--header-background-theme',
      themeBackgroundColor
    )
    document.body.style.setProperty('--header-color-theme', themeColor)
    document.body.style.setProperty(
      '--header-transition',
      '0.3s cubic-bezier(0.4, 0, 0.6, 1)'
    )
  }, [headerTransparencyEnabled, headerTheme, isInScrollPositionRange])

  const setHeaderTransparent = (value: boolean) => setTransparentMode(value)

  return {
    setHeaderTransparent
  }
}
