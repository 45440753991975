import { COOKIE_EXPIRE_1_MONTH } from '@consts/index'
import { getCookieByName, setCookie } from '@helpers/session'
import { useEffect } from 'react'
import { ShopLocale } from 'types/locales'

type GeoRegionCode = ShopLocale | 'ROW'

type ChosenCountry = ShopLocale | undefined

const REGION_CODES = ['GB', 'EU', 'US'] as const

type GetShouldShowCountryModalArgs = {
  geoRegionCode: GeoRegionCode
  chosenCountry: ChosenCountry
  shopLocale: ShopLocale
}
export function getShouldShowCountryModal({
  geoRegionCode,
  chosenCountry,
  shopLocale
}: GetShouldShowCountryModalArgs): boolean {
  if (!REGION_CODES.includes(geoRegionCode) && !chosenCountry) {
    return true
  }

  if (
    shopLocale === geoRegionCode &&
    chosenCountry &&
    chosenCountry !== shopLocale
  ) {
    return true
  }

  if (shopLocale !== geoRegionCode && chosenCountry !== shopLocale) {
    return true
  }

  return false
}

export function useCountryModal(shopLocale: ShopLocale) {
  const geoRegionCode = (getCookieByName('geo_region_code') ||
    shopLocale) as GeoRegionCode
  const chosenCountry = getCookieByName('th_chosen') as ChosenCountry

  const shouldShowCountryModal = getShouldShowCountryModal({
    geoRegionCode,
    chosenCountry,
    shopLocale
  })

  useEffect(() => {
    if (!shouldShowCountryModal) {
      setCookie(
        'th_chosen',
        chosenCountry || shopLocale,
        COOKIE_EXPIRE_1_MONTH,
        true
      )
    }
  }, [shouldShowCountryModal, chosenCountry, shopLocale])

  return { shouldShowCountryModal }
}
