import Modal from '@components/ui/Modal'
import { NewsletterModalProps } from './shared/types'
import NewsletterModalContent from './shared/NewsletterModalContent'
import { ConfirmationContent } from './shared/NewsletterSteps/NewsletterConfirmation'
import styles from './shared/styles.module.scss'

const image = '/static/images/newsletter-modal-image1.jpg'

type FooterNewsletterModalProps = NewsletterModalProps & {
  email: string
}

function FooterNewsletterModal({
  onClose,
  openedModal,
  email
}: FooterNewsletterModalProps) {
  return (
    <Modal
      classes={styles.NewsletterModalWrapper}
      onClose={onClose}
      openedModal={openedModal}
      exclude
    >
      <NewsletterModalContent image={image}>
        <ConfirmationContent email={email} onClose={onClose} />
      </NewsletterModalContent>
    </Modal>
  )
}

export default FooterNewsletterModal
